import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import { Route, Redirect } from "react-router-dom";
import { history } from "../routes/AppRouters";

import { changeLocale } from "../actions/locales";
import { startSetFavorites } from "../actions/favorites";

export const PublicRoutes = ({
  locale,
  i18n,
  changeLocale,
  startSetFavorites,
  favorites,
  isAuthenticated,
  component: Component,
  ...rest
}) => {
  const localeCodes = ["en", "ar"];

  let [firstSegmentOfLocation] = document.location.pathname.replace(/^\//, "").split("/");

  if (localeCodes.includes(firstSegmentOfLocation)) {
    if (locale !== firstSegmentOfLocation) {
      changeLocale(firstSegmentOfLocation);
      i18n.changeLanguage(firstSegmentOfLocation);
    }
  } else {
    document.location.pathname = `/${locale}${document.location.pathname}`;
  }

  if (isAuthenticated && favorites === null) {
    startSetFavorites(1);
  }

  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated && props.location.pathname.includes("/user") ? (
          <Redirect to={props.location.state ? props.location.state.from : `/${locale}/`} />
        ) : (
          <Component {...props} />
        )
      }
    />
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isLoggedIn,
  locale: state.locales,
  favorites: state.favorites.data,
});

const mapDispatchToProps = (dispatch) => ({
  changeLocale: (language) => dispatch(changeLocale(language)),
  startSetFavorites: (pageNumber) => dispatch(startSetFavorites(pageNumber)),
});

export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
)(PublicRoutes);
