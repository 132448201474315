import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { Formik } from 'formik'
import NotFound from './NotFound'
import * as Yup from 'yup'

import { startFetchStoreProfile, startUpdateStoreProfile } from '../actions/profile'
import { startFetchPlaces } from '../actions/places'

import picture from '../images/picture.svg'
import Avatar from './genericComponents/Avatar'
import FormInput from './genericComponents/FormInput'
import FormSelectOption from './genericComponents/FormSelectOption'
import ShadowCard from './genericComponents/ShadowCard'
import Switches from './genericComponents/Switches'
import SupportInfo from './genericComponents/SupportInfo'
import Spinner from './Spinner'

export class AccountStorePage extends Component {

    state = {
        disabled: true,
        avatar: null,
        isFetching: false
    }

    async componentDidMount() {
      this.setState({ isFetching: true })
      try {
        await this.props.startFetchStoreProfile()
        await this.props.startFetchPlaces()
        this.setState({ isFetching: false })
      } catch (error) {
        // fetch log error
        this.setState({ isFetching: false })
      }
    }

    changeFormStateHandler = () => {
      this.setState({ disabled: !this.state.disabled })
    }

    submitHandler = async (values, bag) => {
      this.setState({ isFetching: true })
      try {
        this.state.avatar ?
          await this.props.startUpdateStoreProfile({ ...values, store_logo: this.state.avatar }, 'store') :
          await this.props.startUpdateStoreProfile({ ...values }, 'store')
        this.setState({ isFetching: false, disabled: true })
      } catch (error) {
        bag.setSubmitting(false)
        this.setState({ isFetching: false })
      }
    }

    toBase64 = (file) => {
      let reader = new FileReader()

      reader.onload = () => {
        this.setState({ avatar: reader.result })
      }

      reader.readAsDataURL(file)
    }

    onFileChangeHandler = (event) => {
      const files = Object.values(event.target.files).filter(image => image.type !== 'image');
      files.forEach(file => this.toBase64(file))
    }

    render() {
      if (this.props.profile && !this.props.profile.vendor_name) {
        return <NotFound />;
      }

        return (
          <React.Fragment>
            <SupportInfo />
              <ShadowCard>
                <Switches />
                <div className="my-account__actions">
                    <Link to={'/account/store/branches'}><i className="fas fa-arrow-left"></i></Link>
                    <button type="button" onClick={this.changeFormStateHandler}><i className="fas fa-pen"></i></button>
                </div>
                {this.state.isFetching ? <Spinner /> : (
                    <div className="my-account py-5 flex-column flex-lg-row">
                        <div className="avatar-container">
                            {!this.state.avatar ?
                                <Avatar
                                    src={this.props.profile ? this.props.profile.store_logo : picture}
                                    onFileChangeHandler={this.onFileChangeHandler}
                                    hidden={this.state.disabled}
                                />
                                :
                                <Avatar
                                    src={this.state.avatar}
                                    onFileChangeHandler={this.onFileChangeHandler}
                                    hidden={this.state.disabled}
                                />
                            }
                        </div>
                        <div className="flex-1 user-info-container">
                            <Formik
                                initialValues={{
                                    store_name_english: this.props.profile ? this.props.profile.store_name_english : '',
                                    store_name_arabic: this.props.profile ? this.props.profile.store_name_arabic : '',
                                    store_mobile: this.props.profile ? this.props.profile.store_mobile : '',
                                    store_land_line: this.props.profile ? this.props.profile.store_land_line : '',
                                    store_government: this.props.profile ? this.props.profile.store_government : '',
                                    store_neighbourhood: this.props.profile ? this.props.profile.store_neighbourhood : '',
                                    store_address: this.props.profile ? this.props.profile.store_address : '',
                                    about_store: this.props.profile ? this.props.profile.about_store : ''
                                }}
                                onSubmit={this.submitHandler}
                                validationSchema={
                                    Yup.object().shape({
                                        store_name_english: Yup.string().required('ادخل اسم الشركة'),
                                        store_name_arabic: Yup.string().required('ادخل اسم الشركة'),
                                        store_mobile: Yup.string().required('مينفعش رقم الموبايل يكون فاضى'),
                                        store_land_line: Yup.string(),
                                        store_government: Yup.string().required('اختر المحافظة'),
                                        store_neighbourhood: Yup.string().required('اختر المنطقة'),
                                        store_address: Yup.string().required('مينفعش العنوان يكون فاضى'),
                                        about_store: Yup.string()
                                    })
                                }
                            >
                                {({
                                    handleSubmit,
                                    handleChange,
                                    handleBlur,
                                    values,
                                    errors,
                                    touched,
                                    isValid,
                                    isSubmitting
                                }) => (
                                        <div className="auth__form mx-100">
                                            <form onSubmit={handleSubmit} className="m-0">
                                                <div className="d-flex justify-content-start text-right flex-column flex-lg-row text-right direction-rtl">
                                                    <div className="flex-1 dynamic-padding">
                                                        <FormInput
                                                            autoCapitalize="none"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            disabled={this.state.disabled}
                                                            value={values.store_name_arabic}
                                                            label="اسم الشركة"
                                                            required
                                                            placeholder="ادخل الاسم"
                                                            type="text"
                                                            name="store_name_arabic"
                                                            error={touched.store_name_arabic && errors.store_name_arabic}
                                                        />
                                                        {this.state.disabled && <p style={{ 'paddingTop': '5px', 'borderBottom': '1px solid #ccc' }}></p>}
                                                        <FormInput
                                                            autoCapitalize="none"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            disabled={this.state.disabled}
                                                            value={values.store_mobile}
                                                            label="موبايل"
                                                            placeholder="ادخل رقم الموبايل"
                                                            required
                                                            type="text"
                                                            name="store_mobile"
                                                            error={touched.store_mobile && errors.store_mobile}
                                                        />
                                                        {this.state.disabled && <p style={{ 'paddingTop': '5px', 'borderBottom': '1px solid #ccc' }}></p>}
                                                        <FormSelectOption
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            disabled={this.state.disabled}
                                                            value={values.store_government}
                                                            options={this.props.places && this.props.places}
                                                            label="المحافظة"
                                                            required
                                                            name="store_government"
                                                            error={touched.store_government && errors.store_government}
                                                        />
                                                        {this.state.disabled && <p style={{ 'paddingTop': '5px', 'borderBottom': '1px solid #ccc' }}></p>}
                                                        <FormInput
                                                            autoCapitalize="none"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            disabled={this.state.disabled}
                                                            value={values.store_address}
                                                            label="العنوان"
                                                            required
                                                            placeholder="ادخل العنوان"
                                                            type="text"
                                                            name="store_address"
                                                            error={touched.store_address && errors.store_address}
                                                        />
                                                    </div>
                                                    <div className="flex-1 px-3 dynamic-padding">
                                                        <FormInput
                                                            autoCapitalize="none"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            disabled={this.state.disabled}
                                                            value={values.store_name_english}
                                                            label="اسم الشركة بالانجليزية"
                                                            required
                                                            placeholder="ادخل الاسم"
                                                            type="text"
                                                            name="store_name_english"
                                                            error={touched.store_name_english && errors.store_name_english}
                                                        />
                                                        {this.state.disabled && <p style={{ 'paddingTop': '5px', 'borderBottom': '1px solid #ccc' }}></p>}
                                                        <FormInput
                                                            autoCapitalize="none"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            disabled={this.state.disabled}
                                                            value={values.store_land_line}
                                                            label="تليفون ارضى"
                                                            placeholder="ادخل رقم التليفون الارضى"
                                                            type="text"
                                                            name="store_land_line"
                                                            error={touched.store_land_line && errors.store_land_line}
                                                        />
                                                        {this.state.disabled && <p style={{ 'paddingTop': '5px', 'borderBottom': '1px solid #ccc' }}></p>}
                                                        <FormSelectOption
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.store_neighbourhood}
                                                            options={this.props.places && this.props.places.find(place => place.name === values.store_government)}
                                                            disabled={!values.store_government || this.state.disabled}
                                                            label="المنطقة"
                                                            required
                                                            name="store_neighbourhood"
                                                            error={touched.store_neighbourhood && errors.store_neighbourhood}
                                                        />
                                                        {this.state.disabled && <p style={{ 'paddingTop': '5px', 'borderBottom': '1px solid #ccc' }}></p>}
                                                        <FormInput
                                                            autoCapitalize="none"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            disabled={this.state.disabled}
                                                            value={values.about_store}
                                                            label="نبذه عن الشركة"
                                                            placeholder="نبذه عن الشركة"
                                                            type="text"
                                                            name="about_store"
                                                            error={touched.about_store && errors.about_store}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="text-right mt-3">
                                                    <button
                                                        type="submit"
                                                        className="btn btn-info px-5 pt-1 pb-1"
                                                        disabled={!isValid || isSubmitting}
                                                        hidden={this.state.disabled}
                                                    >
                                                        حفظ
                                                </button>
                                                </div>
                                            </form>
                                        </div>
                                    )}
                            </Formik>
                        </div>
                    </div>
                )
                }
            </ShadowCard>
        
          </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    profile: state.profile,
    places: state.places.places
})

const mapDispatchToProps = (dispatch) => ({
    startFetchStoreProfile: () => dispatch(startFetchStoreProfile()),
    startUpdateStoreProfile: (values, type) => dispatch(startUpdateStoreProfile(values, type)),
    startFetchPlaces: () => dispatch(startFetchPlaces())
})

export default connect(mapStateToProps, mapDispatchToProps)(AccountStorePage)