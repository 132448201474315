import React from "react";
import { connect } from "react-redux";
import Pagination from "./Pagination";
import Spinner from "./Spinner";
import EmptySetHandler from "./EmptySetHandler";
import { startSetFavorites } from "../actions/favorites";
import SubModelList from "./SubModelList";
import HelmetMetaData from "./HelmetMetaData";
import Logo from "../images/Group 692.png";

export class Favorites extends React.Component {
  componentDidMount() {
    this.props.startSetFavorites();
  }

  handlePageClick = (data) => {
    const page_number = data + 1;
    this.props.startSetFavorites(page_number);
  };

  render() {
    const { data, pages_count, count, isFetching } = this.props.favorites;
    const locale = this.props.locales;

    const title = locale === "ar" ? "متدورش - المفضلة" : "Matdawarsh - Favorites";
    const description =
      locale === "ar"
        ? "نسعى لتقديم افضل خدمة لتعريف الجمهور بأفضل اسعار المنتجات في السوق المصري والعربي ومن ذلك الموبايلات والاجهزة المنزلية والإليكترونية وغيرها"
        : "We strive to provide the best service to familiarize the public with the best prices for products in the Egyptian and Arab market (mobiles, home and electronic devices, etc";

    return (
      <div className="ptb-30">
        <HelmetMetaData title={title} description={description}></HelmetMetaData>
        {isFetching ? (
          <div className="floating-spinner">
            <img src={Logo} alt="Matdawarsh" />
          </div>
        ) : data && data.length === 0 ? (
          <EmptySetHandler />
        ) : (
          <React.Fragment>
            {data &&
              data.map(({ pk, sub_model }) => (
                <SubModelList key={pk} favorite_id={pk} size={"combo"} {...sub_model} />
              ))}
            {pages_count > 1 && (
              <Pagination
                pages_count={pages_count}
                count={count}
                handlePageClick={this.handlePageClick}
              />
            )}
          </React.Fragment>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  favorites: state.favorites,
  locale: state.locales,
});

const mapDispatchToProps = (dispatch) => ({
  startSetFavorites: (pageNumber) => dispatch(startSetFavorites(pageNumber)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Favorites);
