import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
const fetchUrl = new URL(window.location.href);

const SubModelItems = ({ t, locale, website, store_branch, price, link, itemLink }) => {
  return (
    <div className="subModels__lowestPricesItems__item">
      <div className={`item__store ${locale === "ar" ? "direction-rtl" : "direction-ltr"}`}>
        {store_branch ? (
          // <Link to={`/store/${store_branch.slug}`}>
          <Link to={itemLink}>
            <div
              className={`store d-flex justify-content-between ${locale === "ar" &&
                "flex-row-reverse"}`}
            >
              <span>
                {store_branch
                  ? store_branch[`name_${locale === "ar" ? "arabic" : "english"}`]
                  : website.name}
              </span>
              <div className="item__store__img">
                {store_branch ? (
                  <img
                    src={store_branch.store_logo}
                    alt={store_branch[`name_${locale === "ar" ? "arabic" : "english"}`]}
                  />
                ) : (
                  <img src={website.image} alt={website.slug} />
                )}
              </div>
            </div>
            <p className="subModel__details__price--flyingPrice">
              <span className="value">{price}</span>
              <span className="currency">{
                fetchUrl.host.includes("sa.") ? t("sub_models.currency_sa")
                :
                fetchUrl.host.includes("kw.") ? t("sub_models.currency_kw")
                :
                fetchUrl.host.includes("ae.") ? t("sub_models.currency_ae")
                :
                t("sub_models.currency_eg")
              }</span>
            </p>
          </Link>
        ) : (
          //<a href={link} target="_blank">
          <a href={itemLink} target="_blank">
            <div
              className={`store d-flex justify-content-between ${locale === "ar" &&
                "flex-row-reverse"}`}
            >
              <span>
                {store_branch
                  ? store_branch[`name_${locale === "ar" ? "arabic" : "english"}`]
                  : website.name}
              </span>
              <div className="item__store__img">
                {store_branch ? (
                  <img
                    src={store_branch.store_logo}
                    alt={store_branch[`name_${locale === "ar" ? "arabic" : "english"}`]}
                  />
                ) : (
                  <img src={website.image} alt={website.slug} />
                )}
              </div>
            </div>
            <p className="subModel__details__price--flyingPrice">
              <span className="value">{price}</span>
              <span className="currency">{
                fetchUrl.host.includes("sa.") ? t("sub_models.currency_sa")
                :
                fetchUrl.host.includes("kw.") ? t("sub_models.currency_kw")
                :
                fetchUrl.host.includes("ae.") ? t("sub_models.currency_ae")
                :
                t("sub_models.currency_eg")
              }</span>
            </p>
          </a>
        )}
      </div>
      <div className="item__link">
        {store_branch ? (
          <Link to={`/${locale}/store/${store_branch.slug}`}>
            <i className="fas fa-caret-left"></i>
          </Link>
        ) : (
          <a href={link} target="_blank">
            <i className="fas fa-caret-left"></i>
          </a>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  locale: state.locales,
});

export default compose(withTranslation(), connect(mapStateToProps))(SubModelItems);
