import React, { useState } from "react";
import picture from "../../images/picture.svg";

import API from "../../api";

function Image(props) {
  const [image, setImage] = useState(props.image);
  const [image_id, setImage_id] = useState(props.image_id);
  const [isUploading, setIsUploading] = useState(0);

  const upload = async (uploadedImage) => {
    try {
      const response = await API.post(
        `store/store-branch-images-create-destroy/?store_branch_pk=${props.branch_id}`,
        {
          store_branch_image: uploadedImage,
        },
        {
          onUploadProgress: (ProgressEvent) =>
            setIsUploading(Math.round((ProgressEvent.loaded * 100) / ProgressEvent.total)),
        }
      );
      setImage(response.data.store_branch_image);
      setImage_id(response.data.pk);
      setIsUploading(0);
    } catch (err) {
      setImage("");
      setIsUploading(0);
    }
  };

  const remove = async () => {
    try {
      await API.delete(
        `store/store-branch-images-create-destroy/?store_branch_pk=${props.branch_id}&store_branch_image_pk=${image_id}`
      );
      setImage("");
    } catch (err) {
      setImage("");
      setIsUploading(0);
    }
  };

  const toBase64 = (file) => {
    let reader = new FileReader();

    reader.onload = () => {
      setImage(reader.result);
      upload(reader.result);
    };

    reader.readAsDataURL(file);
  };

  const onFileChangeHandler = (event) => {
    const files = Object.values(event.target.files).filter((image) => image.type !== "image");
    files.forEach((file) => toBase64(file));
  };

  return (
    <div className={`image image--${props.size}`}>
      {props.isUpdateMode && (
              <div className="image__options">
                <div className="image__options--overlay">
                  <label className="file-select" hidden={image}>
                      <span>
                          <i className="fas fa-camera"></i>
                      </span>
                      <input type="file" className="fileUpload" accept=".png,.jpg,.gif,.jpeg" onChange={(event) => onFileChangeHandler(event)} />
                  </label>
                  <span className="file-select" onClick={() => remove()} hidden={!image}>
                      <span>
                          <i className="fas fa-trash"></i>
                      </span>
                  </span>
                </div>
              </div>
      )}
      <div className="upload-progress" hidden={!isUploading}>
        <span>{isUploading} %</span>
      </div>
      <img src={image || picture} alt="Uploaded Image" />
    </div>
  );
}

export default Image;
