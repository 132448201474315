import React from "react";
import { connect } from "react-redux";

import Spinner from "./Spinner";
import ModelMainDetails from "./ModelMainDetails";
import Ad from "./Ad";
import ModelImages from "./ModelImages";
import SubModelGrid from "./SubModelGrid";
import HelmetMetaData from "./HelmetMetaData";

import Logo from "../images/Group 692.png";
import defaultImage from "../images/default.jpg";

import API from "../api";

export class ModelPage extends React.Component {
  state = {
    lookingFor: null,
    model: null,
    isFetching: false,
  };

  getActiveModel() {
    const model_slug = this.props.match.params.slug;
    this.setState({ isFetching: true, lookingFor: model_slug });
    API.get(`/collector/models/${model_slug}/`).then(
      (response) => {
        this.setState({ model: response.data, isFetching: false });
      },
      (error) => {
        this.setState({ isFetching: false });
        if (error.response.status === 404) {
          this.props.history.push("/404");
        }
      }
    );
  }

  componentDidMount() {
    this.getActiveModel();
  }

  componentDidUpdate() {
    // once user change model, fecth related categories
    if (this.props.match.params.slug !== this.state.lookingFor) {
      this.getActiveModel();
    }
  }

  render() {
    const { model, isFetching } = this.state;

    if (isFetching) {
      return (
        <div className="floating-spinner">
          <img src={Logo} alt="Matdawarsh" />
        </div>
      );
    }

    return (
      <React.Fragment>
        <div className="model-parent">
          {model && (
            <HelmetMetaData
              title={model.sub_model.meta_keywords[this.props.locale]}
              description={model.sub_model.meta_description[this.props.locale]}
              image={model.sub_model.images[0] ? model.sub_model.images[0].image : defaultImage}
            ></HelmetMetaData>
          )}
          <div>
            <Ad position={"model_top_left_one"} width={200} height={200} />
            <div className="my-4"></div>
            <Ad position={"model_top_left_two"} width={200} height={200} />
          </div>
          {model && <ModelMainDetails {...model} />}
          {model && <ModelImages {...model} />}
        </div>
        {model && model.related_sub_models.length ? (
          <div className="featuresGroup py-3">
            <div
              className={`featuresGroup__heading flex-row-reverse ${
                this.props.locale === "en" ? "justify-content-end" : ""
              }`}
            >
              <h3>{this.props.locale === "ar" ? "منتجات مشابهة" : "Similar Products"}</h3>
            </div>
            {window.innerWidth > 800 ? (
              <div
                className={`featuresGroup__slider d-flex flex-wrap ${
                  this.props.locale === "ar" ? "justify-content-end" : ""
                }`}
              >
                {model.related_sub_models.map((featured_model, index) => (
                  <SubModelGrid key={index} size={"combo"} {...featured_model} />
                ))}
              </div>
            ) : (
              <div className="overflow-wrapper">
                <div className="featuresGroup__slider featuresGroup__slider--mobile d-inline-flex justify-content-center">
                  {model.related_sub_models.map((featured_model, index) => (
                    <SubModelGrid key={index} size={"combo"} {...featured_model} />
                  ))}
                </div>
              </div>
            )}
          </div>
        ) : (
          ""
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  locale: state.locales,
});

export default connect(mapStateToProps)(ModelPage);
