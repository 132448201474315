import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import { startSetSearchResults, startSetSearchFilters, setCurrentPage } from "../actions/category";

import NoResults from "./NoResults";
import MainCategoryResults from "./MainCategoryResults";
import AsideFilter from "./AsideFilter";

function SearchPage({
  match,
  parameter,
  sections: { sections },
  locale,
  isFetching,
  models,
  startSetSearchResults,
  startSetSearchFilters,
  setCurrentPage,
}) {
  const [featuredModels, setFeaturedModels] = useState([]);
  const searchTerm = match.params.keyword;
  //const page = match.params.page || 1;
  let filter = location.search.replace("?", "");
  //get page number
  const splitQuery = filter.split("&");
  const pageIndex = splitQuery.findIndex(data => data.includes("page"));
  let page = 1;
  if(pageIndex != -1){
    const pageNoIndex = splitQuery[pageIndex].split("=");
    page = Number(pageNoIndex[1]);
  }
  //now lets create our filter
  if(!filter.toLowerCase().includes("page=")) filter = `page=${page}${filter ? `&${filter}` : ""}`;
  //save filter in local storage to get it in MainCategoryResults => hanleChange
  localStorage.setItem("f_q", JSON.stringify(filter));

  useEffect(() => {
    startSetSearchResults(searchTerm, page, filter, parameter);
    getFeaturedModelsList();

    return () => {
      setCurrentPage(1);
    };
  }, [filter]);

  useEffect(() => {
    startSetSearchFilters(searchTerm, parameter);
    getFeaturedModelsList();

    return () => {
      setCurrentPage(1);
    };
  }, [searchTerm]);

  const getFeaturedModelsList = () => {
    const featured = sections.forEach((section) => {
      let count = 0;
      const firstFive = [];
      section.featured_models.forEach((m) => {
        if (m.images.length > 0 && count < 4) {
          count += 1;
          firstFive.push(m);
        }
      });

      setFeaturedModels(featuredModels.concat(firstFive));
    });
  };

  if (!isFetching && models && models.length === 0) {
    return <NoResults keyword={searchTerm} featuredModels={featuredModels} />;
  }

  return (
    <section
      className={`category-results d-flex justify-content-between ${
        locale === "ar" ? "flex-row-reverse" : ""
      } ${window.innerWidth > 800 ? "mt-5" : ""}`}
    >
      <AsideFilter slug={searchTerm} />
      <MainCategoryResults slug={searchTerm} page={page} />
    </section>
  );
}

const mapStateToProps = (state) => ({
  parameter: state.search.parameter,
  models: state.category.results.data,
  isFetching: state.category.results.isFetching,
  locale: state.locales,
  sections: state.sections,
});

const mapDispatchToProps = (dispatch) => ({
  startSetSearchResults: (search, pageNumber, filter, parameter) =>
    dispatch(startSetSearchResults(search, pageNumber, filter, parameter)),
  startSetSearchFilters: (keyword, parameter) =>
    dispatch(startSetSearchFilters(keyword, parameter)),
  setCurrentPage: (page) => dispatch(setCurrentPage(page)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchPage);
