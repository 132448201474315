import { 
    SET_PLACES,
    FETCH_PLACES_REQUEST,
    FETCH_PLACES_SUCCESS,
    FETCH_PLACES_FAILURE 
} from '../constants/action-types'

const defaultPlaces = {
    places: null,
    isFetching: false,
    message: null
};

export default (state = defaultPlaces, action) => {
    switch(action.type) {
        case SET_PLACES:
            return {
                ...state,
                places: action.places
            };
        case FETCH_PLACES_REQUEST:
            return {
                ...state,
                isFetching: true
            }
        case FETCH_PLACES_SUCCESS:
        case FETCH_PLACES_FAILURE: 
            return {
                ...state,
                isFetching: false,
                message: action.message || null
            }
        default:
            return state;
    }
}