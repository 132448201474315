import API from '../api'
import { SET_STORES } from '../constants/action-types'

export const setStores = (stores) => ({
    type: SET_STORES,
    stores
})

export const startFetchStores = (params = '') => async dispatch => {
    try {
        const response = await API.get(`store/store-branches-list/${params}`)
        dispatch(setStores(response.data))
    } catch (err) {
        // Ignore Error Message
    }
}

export const startFetchStoresInsideNeighbourhoood = (page = 1, neighbourhood) => async dispatch => {
    try {
        const response = await API.get(`store/neighbourhood-store-branches-list/?neighbourhood=${neighbourhood}&page=${page}`)
        return response
    } catch (err) {
        // Ignore Error Message
    }
}