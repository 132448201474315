import API from '../api'

export const createVisitBranchReport = ({storeId,productId,isMobile,isComingFromSocial}) => dispatch => {
    return API.post(`visit-tracker/visits/`, {
        store: storeId,
        source_item: productId,
        source_site: isComingFromSocial,
        is_mobile: isMobile,
    })
}

export const createVisitSocialReport = ({storeId,isWhatsapp,isMessenger,isPhone}) => dispatch => {
    return API.post(`visit-tracker/social-click/`,{
        store: storeId,
        whatsapp: isWhatsapp,
        messenger: isMessenger,
        phone: isPhone,
    })
}