import React from "react";
import { connect } from "react-redux";

function ContactUS({ locale }) {
  return locale === "ar" ? (
    <div style={{ padding: "2em" }}>
      <div style={{ textAlign: "center" }}>
        <img
          border={0}
          data-original-height={1983}
          data-original-width={1847}
          height={200}
          width={200}
          src="https://1.bp.blogspot.com/-hIA-u5-ZID8/X-zYFwhnBCI/AAAAAAAAANo/0HF8dQvcZpc3_vyrgotPvT70o6RV--ljwCLcBGAsYHQ/w186-h200/Artboard%2B1%2Bcopy.jpg"
        />
      </div>
      <div style={{ textAlign: "left" }}>
        <br />
      </div>
      <div style={{ textAlign: "center", padding: "2rem" }}>
        <span
          style={{
            color: "#ffa400",
            fontfamily: "Open Sans, Helvetica Neue, Helvetica, Arial, sans-serif",
            fontSize: "x-large",
            textAlign: "left",
          }}
        >
          <b>&nbsp; أتصل بنا</b>
        </span>
      </div>
      <div style={{ textAlign: "center" }}>
        <b>
          <span style={{ color: "#ffa400", fontSize: "large" }}>
            <br />
          </span>
        </b>
      </div>
      <div style={{ textAlign: "left" }}>
        <span style={{ color: "#2b00fe" }}>
          <b>EMAIL:</b>
        </span>{" "}
        info@matdawarsh.com
      </div>
      <div style={{ textAlign: "left" }}>
        <br />
      </div>
      <div style={{ textAlign: "left" }}>
        <b style={{ color: "#2b00fe" }}>EMAIL:&nbsp;</b>sales@matdawarsh.com
      </div>
      <div style={{ textAlign: "left" }}>
        <br />
      </div>
      <div style={{ textAlign: "left" }}>
        <b>
          <span style={{ color: "#2b00fe" }}>MOBILE</span>
        </b>{" "}
        : 012-0048-5573
      </div>
      <div style={{ textAlign: "left" }}>
        <br />
      </div>
      <div style={{ textAlign: "left" }}>
        <span style={{ color: "#2b00fe" }}>
          <b>facebook.com</b>
        </span>
        /matdawarsh
      </div>
      <div style={{ textAlign: "left" }}>
        <span style={{ color: "#2b00fe" }}>
          <b>twitter.com</b>
        </span>
        /matdawarsh
      </div>
      <div style={{ textAlign: "left" }}>
        <span style={{ color: "#2b00fe" }}>
          <b>instagram.com</b>
        </span>
        /matdawarsh
      </div>
      <div>
        <br />
      </div>
      <div>
        <br />
      </div>
      <div>
        <br />
      </div>
    </div>
  ) : (
    <div style={{ padding: "2em" }}>
      <div style={{ textAlign: "center" }}>
        <img
          border={0}
          data-original-height={1983}
          data-original-width={1847}
          height={200}
          width={200}
          src="https://1.bp.blogspot.com/-hIA-u5-ZID8/X-zYFwhnBCI/AAAAAAAAANo/0HF8dQvcZpc3_vyrgotPvT70o6RV--ljwCLcBGAsYHQ/w186-h200/Artboard%2B1%2Bcopy.jpg"
        />
      </div>
      <div style={{ textAlign: "left" }}>
        <br />
      </div>
      <div style={{ textAlign: "center", padding: "2rem" }}>
        <span
          style={{
            color: "#ffa400",
            fontfamily: "Open Sans, Helvetica Neue, Helvetica, Arial, sans-serif",
            fontSize: "x-large",
            textAlign: "left",
          }}
        >
          <b>&nbsp;Contact us</b>
        </span>
      </div>
      <div style={{ textAlign: "left" }}>
        <span style={{ color: "#2b00fe" }}>
          <b>EMAIL:</b>
        </span>{" "}
        info@matdawarsh.com
      </div>
      <div style={{ textAlign: "left" }}>
        <br />
      </div>
      <div style={{ textAlign: "left" }}>
        <b style={{ color: "#2b00fe" }}>EMAIL:&nbsp;</b>sales@matdawarsh.com
      </div>
      <div style={{ textAlign: "left" }}>
        <br />
      </div>
      <div style={{ textAlign: "left" }}>
        <b>
          <span style={{ color: "#2b00fe" }}>MOBILE</span>
        </b>{" "}
        : 012-0048-5573
      </div>
      <div style={{ textAlign: "left" }}>
        <br />
      </div>
      <div style={{ textAlign: "left" }}>
        <span style={{ color: "#2b00fe" }}>
          <b>facebook.com</b>
        </span>
        /matdawarsh
      </div>
      <div style={{ textAlign: "left" }}>
        <span style={{ color: "#2b00fe" }}>
          <b>twitter.com</b>
        </span>
        /matdawarsh
      </div>
      <div style={{ textAlign: "left" }}>
        <span style={{ color: "#2b00fe" }}>
          <b>instagram.com</b>
        </span>
        /matdawarsh
      </div>
      <div>
        <br />
      </div>
      <div>
        <br />
      </div>
      <div>
        <br />
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  locale: state.locales,
});

export default connect(mapStateToProps)(ContactUS);
