import React, { useState } from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";
import { startFetchUserNotifications, isNotificationsOpened } from "../actions/notifications";
import NotificationContainer from "./NotificationContainer";
import SearchInput from "./SearchInput";
import MobileMenu from "./mobile-menu";
import Ad from "./Ad";
import { resetFilters } from "../actions/search";
import burgerIcon from "../images/menu-button.svg";
import searchIcon from "../images/Search.svg";
//import websiteLogo from "../images/Group 692.png";
//load dynamic logo
let defaultLogo = "";
const fetchUrl = new URL(window.location.href);
if(fetchUrl.host.includes("eg.")){
  defaultLogo = require("../images/Egypt-logo.png");
}else if(fetchUrl.host.includes("sa.")){
  defaultLogo = require("../images/ksa-logo.png");
}else if(fetchUrl.host.includes("ae.")){
  defaultLogo = require("../images/UAE-logo.png");
}else if(fetchUrl.host.includes("kw.")){
  defaultLogo = require("../images/kuwait-logo.png");
}else{
  defaultLogo = require("../images/Group 692.png");
}

const MobileHeader = ({
  locale,
  keyword,
  resetFilters,
  isLoggedIn,
  isNotificationsClicked,
  startFetchUserNotifications,
  isNotificationsOpened,
}) => {
  if (location.pathname.includes("/user/")) {
    document.body.style.overflow = "auto";
    return null;
  }

  // Declare a new state variable, which we'll call "appendSearch"
  const [showSearch, appendSearch] = useState(false);
  const [showMenu, switchMenuState] = useState(false);

  return (
    <React.Fragment>
      <header className="mobileHeader">
        <div className="mobileHeader__inner">
          <div id="wrapper">
            <div className={`mobileHeader__main ${locale === "en" ? "direction-rtl" : undefined}`}>
              <div className="mobileHeader__user">
                <div
                  className="notifications"
                  hidden={!isLoggedIn || showMenu}
                  onClick={() => {
                    isNotificationsOpened();
                    startFetchUserNotifications();
                    isNotificationsClicked
                      ? (document.body.style.overflow = "auto")
                      : (document.body.style.overflow = "hidden");
                  }}
                >
                  <span
                    className={`far fa-bell ${isNotificationsClicked && "coloredBellBlue"}`}
                  ></span>
                  {isNotificationsClicked && <NotificationContainer />}
                </div>
                <div className="user" hidden={showMenu} onClick={() => appendSearch(!showSearch)}>
                  <img src={searchIcon} alt="search" />
                </div>
              </div>
              <div className="mobileHeader__menu">
                <div
                  className="logo"
                  onClick={() => {
                    switchMenuState(false);
                    document.body.style.overflow = "auto";
                  }}
                >
                  <Link to={`/${locale}/`}>
                    <img src={defaultLogo} alt={"متدورش"} />
                  </Link>
                </div>
                <div
                  className="burgerIcon"
                  onClick={() => {
                    switchMenuState(!showMenu);
                    showMenu
                      ? (document.body.style.overflow = "auto")
                      : (document.body.style.overflow = "hidden");
                  }}
                >
                  <img src={burgerIcon} alt="menu" />
                </div>
              </div>
            </div>
          </div>
        </div>
        {<MobileMenu showMenu={showMenu} switchMenuState={switchMenuState} />}
        {showSearch && (
          <div
            className={`mobileSearchFilter ${locale === "ar" ? "direction-rtl" : "direction-rtl"}`}
          >
            <div
              hidden={!keyword}
              className={`mobileSearchFilter__flag ${keyword === null ? "p-0" : undefined} ${
                locale === "ar" ? "flex-row" : "flex-row-reverse"
              }`}
            >
              {keyword && <i className="far fa-times-circle" onClick={() => resetFilters()}></i>}
              <span>{keyword}</span>
            </div>
            <SearchInput />
          </div>
        )}
      </header>
      <div 
        style={{"margin-top": (window.innerWidth < 800 ? "4em" : "")}} 
        hidden={location.pathname.includes("/model/") || location.pathname.includes("/stores") || location.pathname.includes("/store/")}
      >
        <Ad position={"mobile_header"} width={"100%"} height={"auto"} mobile={true} />
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  isLoggedIn: state.auth.isLoggedIn,
  locale: state.locales,
  keyword: state.search.keyword,
  isNotificationsClicked: state.notifications.isOpened,
});

const mapDispatchToProps = (dispatch) => ({
  startFetchUserNotifications: () => dispatch(startFetchUserNotifications()),
  isNotificationsOpened: () => dispatch(isNotificationsOpened()),
  resetFilters: () => dispatch(resetFilters()),
});

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(MobileHeader);
