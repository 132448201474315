import React, { useState } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import SubModelItems from "./SubModelItems";
import { startRemoveFavorite, startAddFavorite } from "../actions/favorites";
import redHeart from "../images/favorite-red.svg";
import heart from "../images/favorite.svg";
import defaultImage from "../images/default.jpg";

const fetchUrl = new URL(window.location.href);

const SubModelGrid = ({
  t,
  locale,
  isLoggedIn,
  bookmarked,
  favorites,
  images,
  least_price_items,
  name,
  slug,
  pk,
  stores_count,
  startRemoveFavorite,
  startAddFavorite,
  size,
  width,
}) => {
  const [isBookmarked, setBookmarked] = useState(
    favorites && !!favorites.find(({ sub_model }) => sub_model.pk === pk)
  );
  const [srcImage, setSrcError] = useState(images && images[0] ? images[0].image : defaultImage);
  let history = useHistory();

  const onBookmarkHandler = () => {
    if (!isLoggedIn) {
      return history.push(`/${locale}/user/login`);
    }

    if (isBookmarked) {
      const favoriteItem = favorites.find(({ sub_model }) => sub_model.pk === pk);
      setBookmarked(false);
      startRemoveFavorite(favoriteItem.pk);
    } else {
      setBookmarked(true);
      startAddFavorite(pk);
    }
  };

  return (
    <div
      className={`subModel ${width ? "w-0" : ""} ${locale === "ar" && "text-right"} ${size ||
        undefined}`}
    >
      <Link to={`/${locale}/model/${slug}`}>
        <div className="subModel__avatar">
          <img src={srcImage} alt={name[locale]} onError={() => setSrcError(defaultImage)} />
        </div>
        <div className="subModel__details d-block h-auto text-right">
          <h2
            className={`subModel__details__title ${
              locale === "ar" ? "direction-rtl" : "direction-ltr"
            }`}
            title={name[locale]}
          >
            <a href={`/${locale}/model/${slug}`}>{name[locale]}</a>
          </h2>
          <span className="subModel__span">
            {locale === "ar"
              ? `متوفر فى ${stores_count} ${stores_count > 2 ? "متاجر" : "متجر"}`
              : `available in ${stores_count} ${stores_count > 2 ? "stores" : "store"} `}
          </span>
          <p
            className={`subModel__details__price d-block text-right ${
              locale === "ar" ? "direction-rtl" : "direction-ltr"
            }`}
          >
            <span className="value">
              {(least_price_items && least_price_items.length && least_price_items[0].price) || 0}
            </span>
            <span className="currency">{
              fetchUrl.host.includes("sa.") ? t("sub_models.currency_sa")
              :
              fetchUrl.host.includes("kw.") ? t("sub_models.currency_kw")
              :
              fetchUrl.host.includes("ae.") ? t("sub_models.currency_ae")
              :
              t("sub_models.currency_eg")
            }</span>
          </p>
        </div>
      </Link>
            <button
                arialabel="Add to favorites"
                className={`addToFavorites ${locale === 'ar' ? 'left5' : 'right5'}`}
                onClick={() => {
                    if (!isLoggedIn) return history.push('/user/login')
                    setBookmarked(!isBookmarked)
                    isBookmarked ?
                        favorites.find(({ pk: id, sub_model }) => {
                            if (sub_model.pk === pk) startRemoveFavorite(id)
                        })
                        :
                        startAddFavorite(pk)
                }}>
                {isBookmarked ? <img src={redHeart} alt={"Add to favorite"}/> : <img src={heart} alt={"Remove from favorite"}/>}
            </button>
    </div>
  );
};

const mapStateToprops = (state) => ({
  locale: state.locales,
  isLoggedIn: state.auth.isLoggedIn,
  favorites: state.favorites.data,
});

const mapDispatchToProps = (dispatch) => ({
  startAddFavorite: (id) => dispatch(startAddFavorite(id)),
  startRemoveFavorite: (id) => dispatch(startRemoveFavorite(id)),
});

export default compose(
  withTranslation(),
  connect(mapStateToprops, mapDispatchToProps)
)(SubModelGrid);
