import React from 'react';
import Switch from './Switch';
import { withRouter } from 'react-router-dom';
import { compose } from "redux";
import { connect } from "react-redux";

const Switches = props => (
  <div className="Switches-container">
    <div className="Switches">
      <Switch name="الحساب" 
        route="/account/user-information" 
        active={props.location.pathname === "/account/user-information"} 
      />
      <Switch 
        name="بيانات الشركة" 
        route="/account/store" 
        active={props.location.pathname === "/account/store"} 
        disabled={props.profile && !props.profile.vendor_name}
      />
      <Switch 
        name="الفروع" 
        route="/account/store/branches" 
        active={props.location.pathname === "/account/store/branches"} 
        disabled={props.profile && !props.profile.vendor_name}
      />
      <Switch 
        name="الاشتراكات و الدفع" 
        route="/account/store/subscription-and-payments" 
        active={props.location.pathname === "/account/store/subscription-and-payments"} 
        disabled={props.profile && !props.profile.vendor_name}
      />
      <Switch 
        name="تقرير الزيارات" 
        route="/account/store/reports" 
        active={props.location.pathname === "/account/store/reports"} 
        disabled={props.profile && !props.profile.vendor_name}
      />
    </div>
  </div>
);

const mapStateToProps = (state) => ({
  profile: state.profile
});

export default compose(
  withRouter,
  connect(mapStateToProps, null)
)(Switches);