import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

const ImageNameCard = ({ locale, flag, name, image, slug }) => (
  <div className="card">
    <Link to={`/${locale}/${flag}/${slug}`}>
      <div className="card__image">
        <img src={image} alt={slug} />
      </div>
      <div className="card__slug">
        <h3>{name[locale]}</h3>
      </div>
    </Link>
  </div>
);

const mapStateToProps = (state) => ({
  locale: state.locales,
});

export default connect(mapStateToProps)(ImageNameCard);
