import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import { Formik } from "formik";
import * as Yup from "yup";

import FormInput from "../genericComponents/FormInput";
import pageImage from "../../images/Group 260.png";

import API from "../../api";

const UserUpdatePasswordForm = ({ t, locale, match, history }) => {
  const submitHandler = async (values, bag) => {
    const uid = match.params.uid;
    const token = match.params.token;
    try {
      await API.post(`users/reset-password/confirm/${uid}/${token}/`, {
        ...values,
        uid,
        token,
      });
      return history.push(`/${locale}/user/login`);
    } catch (error) {
      bag.setErrors(error.response.data);
      bag.setSubmitting(false);
    }
  };

  return (
    <div className="authSystem">
      <div className={`auth ${locale === "ar" ? "flex-row-reverse" : ""} `}>
        <div className="auth__form">
          <div className={`auth__header ${locale === "ar" ? "flex-row-reverse" : "flex-row"}`}>
            <span className="vertical"></span>
            <h2>{t("update_password.title")}</h2>
            <span className="horizontial"></span>
          </div>
          <Formik
            initialValues={{
              new_password1: "",
              new_password2: "",
            }}
            onSubmit={submitHandler}
            validationSchema={Yup.object().shape({
              new_password1: Yup.string()
                .min(8, "Password must be at least 8 characters.")
                .required("Password is required."),
              new_password2: Yup.string()
                .oneOf([Yup.ref("new_password1"), null], "Passwords must match.")
                .required("Confirm password is required."),
            })}
          >
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              values,
              errors,
              touched,
              isSubmitting,
            }) => (
              <form
                onSubmit={handleSubmit}
                className={locale === "ar" ? "text-right" : "text-left"}
              >
                <div className="account__form__inner">
                  <div className="single-input">
                    <FormInput
                      autoCapitalize="none"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.new_password1}
                      label={t("update_password.new_password")}
                      placeholder={t("update_password.new_password_placeholder")}
                      type="password"
                      name="new_password1"
                      id="new_password1"
                      className={locale === "ar" ? "text-right" : "text-left"}
                      error={touched.new_password1 && errors.new_password1}
                    />
                  </div>
                  <div className="single-input">
                    <FormInput
                      autoCapitalize="none"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.new_password2}
                      label={t("update_password.confirm_password")}
                      placeholder={t("update_password.confirm_password_placeholder")}
                      type="password"
                      name="new_password2"
                      id="new_password2"
                      className={locale === "ar" ? "text-right" : "text-left"}
                      error={touched.new_password2 && errors.new_password2}
                    />
                  </div>
                  <div
                    className={`single-input submitForm d-flex mt-5 align-items-center justify-flex-start ${
                      locale === "ar" ? "flex-row-reverse" : "flex-row"
                    }`}
                  >
                    <button
                      type="submit"
                      className="m-0"
                      title={t("reset_password.recover")}
                      name="send"
                      id="send"
                      disabled={isSubmitting}
                    >
                      {t("update_password.send")}
                    </button>
                  </div>
                </div>
              </form>
            )}
          </Formik>
        </div>
        <div className="auth__image updatePassword">
          <img src={pageImage} alt="auth Image" />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  locale: state.locales,
});

export default compose(withTranslation(), connect(mapStateToProps))(UserUpdatePasswordForm);
