import {
    SET_STORE_PROFILE,
} from '../constants/action-types'

const profile = null

export default (state = profile, action) => {
    switch (action.type) {
        case SET_STORE_PROFILE:
            return action.values
        default:
            return state
    }
}