import React from 'react'
import Modal from 'react-modal'

Modal.setAppElement('#root')

const FloatingCard = props => (
  <Modal
    isOpen={props.isAddClicked}
    contentLabel=""
    closeTimeoutMS={200}
  >
    {props.children}
  </Modal>
);

export default FloatingCard