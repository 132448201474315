import {
  createStore, combineReducers, applyMiddleware, compose,
} from 'redux';
import thunk from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import auth from '../reducers/auth';
import favorites from '../reducers/favorites';
import layout_view from '../reducers/layout_view';
import sort from '../reducers/sort';
import search from '../reducers/search';
import places from '../reducers/places';
import category from '../reducers/category';
import categories from '../reducers/categories';
import isPlacesVisited from '../reducers/isPlacesVisited';
import profile from '../reducers/profile';
import notifications from '../reducers/notifications';
import locales from '../reducers/locales';
import sections from '../reducers/sections';
import category_actions from '../reducers/category_actions';
import stores from '../reducers/stores';
import branches from '../reducers/branches';
import packages from '../reducers/packages';
import branch_items from '../reducers/branch_items';
import brands from '../reducers/brands';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const rootReducer = combineReducers({
  sections,
  notifications,
  locales,
  auth,
  layoutView: layout_view,
  search,
  isPlacesVisited,
  places,
  favorites,
  categories,
  category,
  profile,
  sortBy: sort,
  category_actions,
  stores,
  branches,
  branch_items,
  packages,
  brands,
});

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['sections'], // only sections will be persisted(cached)
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export default () => {
  const store = createStore(
    persistedReducer,
    composeEnhancers(applyMiddleware(thunk)),
  );

  const persistor = persistStore(store);

  return { store, persistor };
};

export type RootState = ReturnType<typeof rootReducer>
