import {
    SET_BRANCH_ITEMS,
    ADD_BRANCH_ITEM,
    UPDATE_BRANCH_ITEM,
    DELETE_BRANCH_ITEM
} from '../constants/action-types'
// SAVED MODELS 

const defaultItems = {
    pages_count: 0,
    count: 0,
    items: null,
};

export default (state = defaultItems, action) => {
    switch (action.type) {
        case SET_BRANCH_ITEMS:
            return {
                ...state,
                items: action.items.results,
                pages_count: action.items.pages_count,
                count: action.items.count
            }
        case ADD_BRANCH_ITEM:
            return {
                ...state,
                items: [...state.items, action.item]
            }
        case UPDATE_BRANCH_ITEM:
            return {
                ...state,
                items: state.items.map(item => {
                    if (item.pk === action.id) {
                        return {
                            ...item,
                            ...action.updates
                        }
                    } else {
                        return { ...item }
                    }
                })
            }
        case DELETE_BRANCH_ITEM:
            return {
                ...state,
                items: state.items.filter(({ pk }) => pk !== action.item_id)
            }
        default:
            return state;
    }
};
