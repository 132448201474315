// filter models based on filters array

export default (models, sortBy) => {
  if (models === null) return null;

  return models.sort((a, b) => {
    if (sortBy === "price low to high") {
      return Number(a.least_price_items[0].price) < Number(b.least_price_items[0].price) ? -1 : 1;
    } else if (sortBy === "price high to low") {
      return Number(a.least_price_items[0].price) > Number(b.least_price_items[0].price) ? -1 : 1;
    } else {
      return 0;
    }
  });
};
