import {
    AUTH_LOGIN,
    AUTH_LOGOUT,
    USER_LOGIN_REQUEST,
    USER_LOGIN_RESPONSE
} from '../constants/action-types'
import axios from 'axios'
import API from '../api'

// USER_LOGIN
export const userLogin = user => ({
    type: AUTH_LOGIN,
    user
})

export const startUserLogin = values => async dispatch => {
    try {
        dispatch({ type: USER_LOGIN_REQUEST })
        const response = await API.post('users/login/', values)
        dispatch(userLogin(response.data))
        localStorage.setItem('user', JSON.stringify(response.data))
        dispatch({ type: USER_LOGIN_RESPONSE })
    } catch (error) {
        const message = error.response.data.non_field_errors[0]
        throw new Error(message)
    }
}

// USER_LOGOUT
export const startUserLogout = () => {
    localStorage.removeItem('user')
    return {
        type: AUTH_LOGOUT
    }
}

export const checkUserStatus = token => async () => {
    try {
        // const response = axios.post('http://52.14.150.126:8000/en/token-verify/', { token })
        const response = axios.post('https://backend.matdawarsh.com/en/token-verify/', { token })
        return response
    } catch (err) {
        throw new Error(err.message)
    }
}



