import React from "react";

function WidgetListItem({ title, value: { name, checked }, onUpdateCategoryFilters }) {
  return (
    <li className="widget__item" title={name.en}>
      <input
        type="checkbox"
        name={name.en}
        title={title}
        id={`widget-${title}-${name.en}`}
        value={checked}
        checked={checked}
        onChange={(e) => onUpdateCategoryFilters(e.target)}
      />
      <label htmlFor={`widget-${title}-${name.en}`}>{name.en}</label>
    </li>
  );
}

export default WidgetListItem;
