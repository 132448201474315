import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import useQuery from "../selectors/useQuery";
import {
  startSetCategoryResults,
  startSetCategoryFilters,
  setCurrentPage,
  updateCategoryFilters,
  getSpecificCategory,
} from "../actions/category";

import MainCategoryResults from "./MainCategoryResults";
import AsideFilter from "./AsideFilter";

import HelmetMetaData from "./HelmetMetaData";

function CategoryPage({
  match,
  location,
  locale,
  startSetCategoryResults,
  startSetCategoryFilters,
  updateCategoryFilters,
  setCurrentPage,
  getSpecificCategory,
}) {
  let query = useQuery();
  //set states
  const [categoryData, setCategoryData] = useState(null);
  const isMounted = useRef(false);

  const categoryName = match.params.slug;

  //lets fetch category and filter from parameters
  //const page = match.params.page || 1;
  let filter = location.search.replace("?", "");
  //get page number
  const splitQuery = filter.split("&");
  const pageIndex = splitQuery.findIndex(data => data.includes("page"));
  let page = 1;
  if(pageIndex != -1){
    const pageNoIndex = splitQuery[pageIndex].split("=");
    page = Number(pageNoIndex[1]);
  }
  //now lets create our filter
  if(!filter.toLowerCase().includes("page=")) filter = `page=${page}${filter ? `&${filter}` : ""}`;
  //save filter in local storage to get it in MainCategoryResults => hanleChange
  localStorage.setItem("f_q", JSON.stringify(filter));

  useEffect(() => {
    startSetCategoryResults(categoryName, page, filter);

    return () => {
      setCurrentPage(1);
    };
  }, [filter]);//listen on filter change

  useEffect(() => {

    startSetCategoryResults(categoryName, page, filter);
    
    startSetCategoryFilters(categoryName).then(() => {
      if (filter) {
        for (let entry of query.entries()) {
          entry[1].split("|").forEach((tag) => {
            updateCategoryFilters(entry[0], tag, true);
          });
        }
      }
    });

    return () => {
      setCurrentPage(1);
    };
  }, [categoryName]);//listen on category change

  useEffect(() => {
    isMounted.current = true;
    //fetch category info to set SEO
    getSpecificCategory(categoryName).then((categoryInfo) => {
      if (isMounted.current) setCategoryData(categoryInfo.results[0]);
    });

    return () => isMounted.current = false;
  }, [categoryName]);//listen on category change

  return (
    <React.Fragment>
      <HelmetMetaData 
        title={categoryData ? (locale == "ar" ? categoryData.name.ar:categoryData.name.en) : ""} 
        description={categoryData ? (locale == "ar" ? categoryData.meta_description.ar:categoryData.meta_description.en) : ""}>
      </HelmetMetaData>
      <section
        className={`category-results d-flex justify-content-between ${
          locale === "ar" ? "flex-row-reverse" : ""
        } ${window.innerWidth > 800 ? "mt-5" : ""}`}
      >
        <AsideFilter slug={categoryName} />
        <MainCategoryResults slug={categoryName} page={page} />
      </section>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  locale: state.locales,
});

const mapDispatchToProps = (dispatch) => ({
  startSetCategoryResults: (keyword, pageNumber, filter) =>
    dispatch(startSetCategoryResults(keyword, pageNumber, filter)),
  startSetCategoryFilters: (keyword) => dispatch(startSetCategoryFilters(keyword)),
  setCurrentPage: (page) => dispatch(setCurrentPage(page)),
  updateCategoryFilters: (title, tag, checked) =>
    dispatch(updateCategoryFilters(title, tag, checked)),
  getSpecificCategory: (slug) => dispatch(getSpecificCategory(slug)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CategoryPage);
