import React from 'react'
import { withTranslation } from 'react-i18next'
import NotificationItem from './NotificationItem'


const Notifications = ({ t, data: notifications }) => (
    notifications.length === 0 ? (
        <div className="AllCaughtUp">
            <i className="far fa-bell"></i>
            <h3>{t('notifications.empty')}</h3>
        </div>
    ) : (
        notifications.map(notification => {
            return <NotificationItem key={notification.id} {...notification} />
        })
    )
)

export default withTranslation()(Notifications)