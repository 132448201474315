import { SET_LAYOUT_VIEW } from '../constants/action-types';
// LAYOUT VIEW 

const defaultView = 'grid';

export default (state = defaultView, action) => {
    switch(action.type) {
        case SET_LAYOUT_VIEW:
            return action.view;
        default:
            return state;
    }
}; 
