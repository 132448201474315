import API from '../api';
import { 
    IS_NOTIFICATIONS_OPENED,
    FETCH_USER_NOTIFICATIONS_REQUEST,
    FETCH_USER_NOTIFICATIONS_SUCCESS,
    FETCH_USER_NOTIFICATIONS_FAILURE,
    SET_USER_NOTIFICATIONS,
    CLEAR_USER_NOTIFICATIONS,
    DELETE_USER_NOTIFICATION
} from '../constants/action-types';


export const isNotificationsOpened = () => ({
    type: IS_NOTIFICATIONS_OPENED
});

export const setUserNotifications = (notifications) => ({
    type: SET_USER_NOTIFICATIONS,
    notifications
})

export const startFetchUserNotifications = () => dispatch => {
    dispatch({
        type: FETCH_USER_NOTIFICATIONS_REQUEST
    });

    return API.get('collector/notifications/').then(
        response => {
            const notifications = response.data;
            dispatch(setUserNotifications(notifications));
            dispatch({
                type: FETCH_USER_NOTIFICATIONS_SUCCESS
            });
        },
        error => {
            dispatch({
                type: FETCH_USER_NOTIFICATIONS_FAILURE,
                message: 'Something went wrong.'
            });
        }
    )
}

export const clearUserNotifications = () => ({
    type: CLEAR_USER_NOTIFICATIONS
})


export const deleteUserNotification = (id) => ({
    type: DELETE_USER_NOTIFICATION,
    id
})

export const startDeleteUserNotification = (id) => dispatch => {
    return API.delete(`collector/notifications/${id}/`).then(
        response => {
            dispatch(deleteUserNotification(id));
        }
    )
}