import React from "react";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

const fetchUrl = new URL(window.location.href);

const Store = ({
  locale,
  t,
  isFromModel = false,
  width = "auto",
  condition,
  store_branch,
  isDirectTrader = false,
  isFlipped = false,
  productId = null,
  name,
  website,
  price,
  link,
}) => (
  <div
    className={`online-store ${width} ${
      locale === "ar" ? "ar mr-auto direction-rtl" : "en ml-auto"
    } ${isFromModel && "p-0 h-60"} ${isFlipped ? "hidden" : undefined}`}
  >
    <div className="online-store__information">
      <div className={`online-store__information__avatar ${width === "w-75" && "hidden"}`}>
        {isDirectTrader ? (
          <img src={store_branch.store_logo} alt={store_branch.name_en} />
        ) : (
          <img src={website.image} alt={website.slug} />
        )}
      </div>
      <div className="online-store__information__price">
        <span className="value">{price}</span>
        <span className="currency">{
          fetchUrl.host.includes("sa.") ? t("sub_models.currency_sa")
          :
          fetchUrl.host.includes("kw.") ? t("sub_models.currency_kw")
          :
          fetchUrl.host.includes("ae.") ? t("sub_models.currency_ae")
          :
          t("sub_models.currency_eg")
        }</span>
      </div>
      <div className={`online-store__information__price ${condition}`}>
        <span className="value">{t(`sub_models.${condition}`)}</span>
      </div>
    </div>
    <div
      className={`online-store__redirect ${isDirectTrader && "orange"} d-flex align-items-center`}
    >
      {isDirectTrader ? (
        <Link to={{ pathname: `/${locale}/store/${store_branch.slug}`, state: {comesFromSection: "product_page", productId} }}>
          {locale === "ar" ? "الى التاجر" : "Store"}
          {locale === "ar" ? (
            <i className="fas fa-caret-left px-2"></i>
          ) : (
            <i className="fas fa-caret-right px-2"></i>
          )}
        </Link>
      ) : (
        <a href={link} target="_blank">
          {t("sub_models.to_store")}
          {locale === "ar" ? (
            <i className="fas fa-caret-left px-2"></i>
          ) : (
            <i className="fas fa-caret-right px-2"></i>
          )}
        </a>
      )}
    </div>
  </div>
);

const mapStateToProps = (state) => ({
  locale: state.locales,
});

export default compose(withTranslation(), connect(mapStateToProps))(Store);
