import React from "react";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";

import API from "../api";
import ImageNameCard from "./ImageNameCard";
import HelmetMetaData from "./HelmetMetaData";
import Logo from "../images/Group 692.png";
import Ad from "./Ad";

class CategoriesPage extends React.Component {
  // default categories
  state = { categories: null, isFetching: false };

  // fetch sections
  componentDidMount() {
    this.setState({ isFetching: true });
    API.get("/basic-data/categories/").then(({ data: categories }) =>
      this.setState(() => ({ categories, isFetching: false }))
    );
  }

  render() {
    const { t, locale } = this.props;

    if (this.state.isFetching) {
      return (
        <div className="floating-spinner">
          <img src={Logo} alt="Matdawarsh" />
        </div>
      );
    }

    const title = locale === "ar" ? "متدورش - جميع الفئات" : "Matdawarsh - All Categories";
    const description =
      locale === "ar"
        ? "نسعى لتقديم افضل خدمة لتعريف الجمهور بأفضل اسعار المنتجات في السوق المصري والعربي ومن ذلك الموبايلات والاجهزة المنزلية والإليكترونية وغيرها"
        : "We strive to provide the best service to familiarize the public with the best prices for products in the Egyptian and Arab market (mobiles, home and electronic devices, etc";

    return (
      <React.Fragment>
        <HelmetMetaData title={title} description={description}></HelmetMetaData>
        <div className={`cards__header ${locale === "ar" ? "text-right" : "text-left"}`}>
          <h2>{t("navigation.all_categories")}</h2>
        </div>
        <div className={`cards ${locale === "ar" ? "flex-row-reverse" : undefined}`}>
          {this.state.categories &&
            this.state.categories.map((category, index) => (
              <React.Fragment key={index}>
                {((index != 0) && (index % 6 == 0)) ?
                <div style={{"margin":"20px 0", "width": "100%"}} hidden={window.innerWidth > 800}>
                  <Ad position={"mobile_categories_splitter"} width={"100%"} height={"auto"} mobile={true} />
                </div>
                : undefined}
                <ImageNameCard key={index} flag={"category"} {...category} />
              </React.Fragment>
            ))}
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  locale: state.locales,
});

export default compose(withTranslation(), connect(mapStateToProps))(CategoriesPage);
