
// Filter 
export default (filters) => {
    let filter = '';

    for(let filterName in filters) {
        if(filter.length !== 0) {
            filter += '&';
        }

        filters[filterName].map(({ name, checked, price_lte, price_gte }) => {
            if(filterName === 'Prices' && value.price_lte !== undefined) {
                filter += `price=${price_lte}to${price_gte}`;
            } else if(checked && !filter.includes(filterName)) {
                filter += `${filterName}=${name.en}`;
            } else if (checked && filter.includes(filterName)) {
                filter += `|${name.en}`;
            }
        })

        if(filter[filter.length - 1] === '&') {
            filter = filter.substr(0, filter.length - 1);
        }
    }

    return filter;
};