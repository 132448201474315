import React from 'react'

const SupportInfo = () => {
  return (
    <div class="alert alert-info text-center fw-bolder w-50 mx-auto mt-3" role="alert" style={{ fontSize: 16 }}>
      <a href="http://m.me/matdawarsh.Retailers" target="_blank">
        <i className="fab fa-facebook-messenger"></i>
      </a>
      <a href="https://wa.me/+201558477571" target="_blank" className="mx-2">
        <i className="fab fa-whatsapp"></i>
      </a>
      اذا واجهتك اي مشكلة او لديك اي استفسار فلا تتردد في التواصل معنا
    </div>
  )
};

export default SupportInfo;
