import React, { Fragment } from "react";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import searchResults from "../images/search-result.png";
import SubModelGrid from "./SubModelGrid";

const NoResults = ({ t, keyword = "", message = "", featuredModels = [] }) => (
  <div className="handler">
    <div className="handler__flag">
      <img src={searchResults} alt="no data" />
    </div>
    <div className="handler__message handler__message--search-text">
      {keyword ? (
        <p>
          <strong>{keyword}</strong>
          لم نتمكن من العثور على اى شيء لـ
        </p>
      ) : (
        <p>{message}</p>
      )}
    </div>
    {!!featuredModels.length && (
      <Fragment>
        <h3 style={{ marginTop: 100 }}>{t("search.featuredProducts")}</h3>
        <div className="featuresGroup py-2">
          <div className="featuresGroup__slider d-flex flex-wrap justify-content-end">
            {featuredModels &&
              featuredModels.map((item, index) => (
                <SubModelGrid key={index} size="combo" {...item} />
              ))}
          </div>
        </div>
      </Fragment>
    )}
  </div>
);

export default compose(withTranslation())(NoResults);
