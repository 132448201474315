import React from 'react'
import FetchError from '../FetchError'

const FormInput = props => {

    const { label, error, ...rest } = props
    return (
        <div>
            <div className="single-input">
                <label>
                    {label}
                    {props.required && <strong className="red-text mr-2">*</strong>}
                </label>
                <input
                    onChange={props.onChange}
                    onBlur={props.onBlur}
                    {...rest}
                />
            </div>
            {error && <FetchError message={error} />}
        </div>
    )
}

export default FormInput