import {
  SET_CATEGORY_RESULTS,
  REQUEST_CATEGORY_RESULTS,
  RECIEVE_CATEGORY_RESULTS,
  SET_CATEGORY_FILTERS,
  REQUEST_CATEGORY_FILTERS,
  RECIEVE_CATEGORY_FILTERS,
  UPDATE_RANGE_SLIDER,
  UPDATE_CATEGORY_FILTERS,
  SET_CURRENT_PAGE,
  SET_SPECIFIC_CATEGORY,
} from "../constants/action-types";

// CATEGORY MODELS

const defaultCategory = {
  results: {
    pages_count: 0,
    count: 0,
    data: null,
    isFetching: false,
    currentPage: 1,
  },
  filters: {
    data: {},
    isFetching: false,
  },
};

export default (state = defaultCategory, action) => {
  switch (action.type) {
    case SET_CATEGORY_RESULTS:
      return {
        ...state,
        results: {
          ...state.results,
          data: action.category.results,
          pages_count: action.category.pages_count,
          count: action.category.count,
        },
      };
    case SET_SPECIFIC_CATEGORY:
      return {
        ...state,
        results: {
          ...state.results,
          data: action.category.results,
        },
      };
    case SET_CURRENT_PAGE:
      return {
        ...state,
        results: {
          ...state.results,
          currentPage: action.page,
        },
      };
    case REQUEST_CATEGORY_RESULTS:
      return {
        ...state,
        results: {
          ...state.results,
          isFetching: true,
        },
      };
    case RECIEVE_CATEGORY_RESULTS:
      return {
        ...state,
        results: {
          ...state.results,
          isFetching: false,
        },
      };
    case SET_CATEGORY_FILTERS:
      return {
        ...state,
        filters: {
          ...state.filters,
          data: action.filters,
        },
      };
    case REQUEST_CATEGORY_FILTERS:
      return {
        ...state,
        filters: {
          ...state.filters,
          isFetching: true,
        },
      };
    case RECIEVE_CATEGORY_FILTERS:
      return {
        ...state,
        filters: {
          ...state.filters,
          isFetching: false,
        },
      };
    case UPDATE_RANGE_SLIDER:
      return {
        ...state,
        filters: {
          ...state.filters,
          data: {
            ...state.filters.data,
            prices: state.filters.data["prices"].map((value) => {
              return {
                ...value,
                price_lte: action.range[0],
                price_gte: action.range[1],
                checked: true,
              };
            }),
          },
        },
      };
    case UPDATE_CATEGORY_FILTERS:
      return {
        ...state,
        filters: {
          ...state.filters,
          data: {
            ...state.filters.data,
            [action.title]: state.filters.data[action.title].map((value) => {
              if (value.name.en === action.tag) return { ...value, checked: action.checked };
              return { ...value };
            }),
          },
        },
      };
    default:
      return state;
  }
};
