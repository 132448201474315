import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import Widget from "./Widget";
import Spinner from "./Spinner";
import { switchFilters } from "../actions/category_actions";
import {
  startSetCategoryResults,
  startSetSearchResults,
  startSetSearchFilters,
  updateCategoryFilters,
  setCurrentPage,
} from "../actions/category";
import calculateTextFilter from "../selectors/calculateTextFilter";

function AsideFilter({
  slug,
  filters: { data: filters, isFetching },
  parameter,
  startSetSearchFilters,
  startSetCategoryResults,
  startSetSearchResults,
  updateCategoryFilters,
  switchFilters,
  setCurrentPage,
  isFiltersOpened,
}) {
  const [dispatchUpdate, setDispatchUpdate] = useState(false);
  const mountedRef = useRef(false);
  let location = useLocation();
  let history = useHistory();

  useEffect(() => {
    // check for mount
    if (mountedRef.current) {
      const searchFilter = calculateTextFilter(filters);
      localStorage.setItem("f_q", JSON.stringify(searchFilter));
      history.push({ search: searchFilter });
      setCurrentPage(1);

      if (location.pathname.includes("search")) {
        startSetSearchResults(slug, 1, searchFilter, parameter);
        searchFilter.split("|").length === 1 &&
          searchFilter.split("=").length === 2 &&
          startSetSearchFilters(slug, searchFilter, parameter);
      } else {
        startSetCategoryResults(slug, 1, searchFilter);
      }
    } else mountedRef.current = true;

    return () => {
      localStorage.removeItem("f_q");
    };
  }, [dispatchUpdate]);

  const onUpdateCategoryFilters = ({ title, name: tag, checked }) => {
    updateCategoryFilters(title, tag, checked);
    setDispatchUpdate(!dispatchUpdate);
  };

  return (
    <div className="sidebar-filter" hidden={!isFiltersOpened && window.innerWidth < 800}>
      <div className="sidebar__mobile__row">
        <div className="sidebar__mobile__row__filters">
          <p>filters</p>
          <button name="filter" onClick={() => switchFilters(false)}>
            search
          </button>
        </div>
        <div className="sidebar__mobile__row__close">
          <button name="close" onClick={() => switchFilters(false)}>
            <i className="fas fa-times"></i>
          </button>
        </div>
      </div>
      <div className="sidebar__wrapper">
        {isFetching ? (
          <Spinner />
        ) : (
          filters &&
          Object.keys(filters).map((filterName) => (
            <Widget
              key={filterName}
              name={filterName}
              values={filters[filterName]}
              onUpdateCategoryFilters={onUpdateCategoryFilters}
            />
          ))
        )}
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  parameter: state.search.parameter,
  filters: state.category.filters,
  isFiltersOpened: state.category_actions.isFiltersOpened,
});

const mapDispatchToProps = (dispatch) => ({
  startSetSearchFilters: (keyword, parameter) =>
    dispatch(startSetSearchFilters(keyword, parameter)),
  startSetSearchResults: (keyword, pageNumber, filter, parameter) =>
    dispatch(startSetSearchResults(keyword, pageNumber, filter, parameter)),
  startSetCategoryResults: (keyword, pageNumber, filter) =>
    dispatch(startSetCategoryResults(keyword, pageNumber, filter)),
  switchFilters: (value) => dispatch(switchFilters(value)),
  updateCategoryFilters: (title, tag, checked) =>
    dispatch(updateCategoryFilters(title, tag, checked)),
  setCurrentPage: (pageNumber) => dispatch(setCurrentPage(pageNumber)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AsideFilter);
