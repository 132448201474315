import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { Field, Formik } from 'formik'
import * as Yup from 'yup'

import Spinner from '../components/Spinner'
import Switches from '../components/genericComponents/Switches'
import ShadowCard from '../components/genericComponents/ShadowCard'
import FloatingCard from '../components/genericComponents/FloatingCard'
import BranchListItem from '../components/genericComponents/BranchListItem'
import FormInput from '../components/genericComponents/FormInput'
import FormSelectOption from '../components/genericComponents/FormSelectOption'
import SupportInfo from './genericComponents/SupportInfo'
import NoResults from '../components/NoResults'
import NotFound from './NotFound'

import {
    startFetchStoreBranches, startRemoveStoreBranch, startCreateStoreBranch,
    startFetchWorkDomains
} from '../actions/branches'
import { startFetchPlaces } from '../actions/places'

export class AccountStoreBranchesPage extends React.Component {
    state = { 
      isFetching: false, 
      error: null, 
      isAddClicked: false, 
      work_domains: null,
      branchPk: null 
    }

    getStoreSlug = () => {
      return JSON.parse(localStorage.getItem('user')).user.store_slug;
    };

    async componentDidMount() {
      this.setState({ isFetching: true })
      try {
        await this.props.startFetchStoreBranches(this.getStoreSlug())
        await this.props.startFetchPlaces()
        const response = await this.props.startFetchWorkDomains()
        this.setState({ isFetching: false, work_domains: response.data })
      } catch (err) {
        this.setState({ isFetching: false, error: err })
      }
    }

    changeFormStateHandler = () => {
      this.setState({ isAddClicked: false })
    }

    submitHandler = async (values, bag) => {
      delete values['government']
      try {
        await this.props.startCreateStoreBranch(values)
        this.setState({ isAddClicked: false })
      } catch (error) {
        bag.setSubmitting(false)
        bag.setErrors({ name_arabic: error.message, name_english: error.message })
      }
    }

    render() {
      if (this.props.profile && !this.props.profile.vendor_name) {
        return <NotFound />;
      }
      
        return (
          <React.Fragment>
            <SupportInfo />
            <ShadowCard>
                <Switches />
                <div className="branches-header d-flex justify-content-between align-items-center p-3">
                    <button type="button" onClick={() => this.setState({ isAddClicked: true })}>اضافة فرع</button>
                    <h3 className="">الفروع</h3>
                </div>
                {this.state.isFetching ? <Spinner /> :
                    this.props.branches.length ? (
                      <div className="table-container">
                        <div className="p-3 table-contents">
                            {this.props.branches.map(branch =>
                                <BranchListItem key={branch.pk}>
                                    <div className="branch-list-item__options">
                                        <button className="red" type="button" onClick={() => this.setState({ branchPk: branch.pk })}>
                                            <i className="fas fa-trash"></i>
                                        </button>
                                        <button type="button" className="blue">
                                            <Link to={`/account/store/${this.getStoreSlug()}/branch/${branch.pk}`}>
                                                <i className="fas fa-eye"></i>
                                            </Link>
                                        </button>
                                    </div>
                                    <p>{branch.name_arabic}</p>
                                    <p>{branch.government_name}</p>
                                    <p>{branch.neighbourhood.name}</p>
                                </BranchListItem>
                            )}
                        </div>
                      </div>
                    ) : (
                            <NoResults message="لا توجد فروع لهذا المتجر" />
                        )
                }

                {this.state.branchPk && (
                  <div className="modal d-block" role="dialog" style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
                    <div className="modal-dialog modal-dialog-centered" role="document">
                      <div className="modal-content">
                        <div className="modal-header">
                          <h5 className="modal-title">حذف فرع</h5>
                        </div>
                        <div className="modal-body text-center">
                          <p>هل انت متاكد من انك تريد حذف هذا الفرع ؟</p>
                        </div>
                        <div className="modal-footer">
                          <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={() => this.setState({ branchPk: null })}>رجوع</button>
                          <button type="button" className="btn btn-danger" onClick={() => {
                            this.props.startRemoveStoreBranch(this.state.branchPk)
                            this.setState({ branchPk: null });
                          }}>حذف</button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              
                {this.state.isAddClicked && (
                    <FloatingCard isAddClicked={this.state.isAddClicked}>
                        <Formik
                            initialValues={{
                                name_english: '',
                                name_arabic: '',
                                mobile_number: '',
                                land_line_number: '',
                                government: '',
                                neighbourhood_id: '',
                                address: '',
                                work_domain_id: '',
                                installment: false,
                                city_delivery: false,
                                delivery: false
                            }}
                            onSubmit={this.submitHandler}
                            validationSchema={
                                Yup.object().shape({
                                    name_english: Yup.string().required('ادخل اسم الفرع بالانجليزى'),
                                    name_arabic: Yup.string().required('ادخل اسم الفرع'),
                                    mobile_number: Yup.string().required('ادخل رقم الموبايل').max(11, 'رقم الموبايل  لا يمكن ان يكون اكثر من 11 رقم'),
                                    land_line_number: Yup.string().required('ادخل رقم الخط الارضى').max(11, 'رقم الخط الارضى لا يمكن ان يكون اكثر من 11 رقم'),
                                    neighbourhood_id: Yup.string().required('اختر المنطقة'),
                                    address: Yup.string().required('ادخل العنوان'),
                                    work_domain_id: Yup.string().required('اختر النشاط'),
                                    store_facebook_page: Yup.string(),
                                    store_twitter_page: Yup.string(),
                                    store_youtube_page: Yup.string(),
                                    store_instagram_page: Yup.string(),
                                    messenger_link: Yup.string(),
                                    whatsapp: Yup.string(),
                                    installment: Yup.boolean(),
                                    city_delivery: Yup.boolean(),
                                    delivery: Yup.boolean()
                                })
                            }
                        >
                            {({
                                handleSubmit,
                                handleChange,
                                handleBlur,
                                values,
                                errors,
                                touched,
                                isValid,
                                isSubmitting
                            }) => (
                              <div className="auth__form mx-100">
                                <form onSubmit={handleSubmit} className="m-0 position-relative">
                                  <div className="text-right px-1">
                                    <h2 style={{ fontSize: '18px', fontWeight: 'bold' }}>اضافة فرع</h2>
                                  </div>
                                  <div className="form-fields row text-right direction-rtl" style={{paddingBottom: "16px"}}>
                                    <div className="col-12 col-lg-6">
                                                    <FormInput
                                                        autoCapitalize="none"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.name_arabic}
                                                        required
                                                        label="اسم الفرع"
                                                        placeholder="ادخل الاسم"
                                                        type="text"
                                                        name="name_arabic"
                                                        error={touched.name_arabic && errors.name_arabic}
                                                    />
                                                    <FormInput
                                                      autoCapitalize="none"
                                                      onChange={handleChange}
                                                      onBlur={handleBlur}
                                                      value={values.name_english}
                                                      required
                                                      label="اسم الفرع بالانجليزى"
                                                      placeholder="ادخل اسم الفرع بالانجليزى"
                                                      type="text"
                                                      name="name_english"
                                                      error={touched.name_english && errors.name_english}
                                                    />
                                                    <FormSelectOption
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.work_domain_id}
                                                        domains={this.state.work_domains && this.state.work_domains}
                                                        label="النشاط"
                                                        required
                                                        name="work_domain_id"
                                                        error={touched.work_domain_id && errors.work_domain_id}
                                                    />
                                                </div>
                                    <div className="col-12 col-lg-6">
                                                                                                        <FormSelectOption
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.government}
                                                        options={this.props.places && this.props.places}
                                                        label="المحافظة"
                                                        required
                                                        name="government"
                                                        error={touched.government && errors.government}
                                                    />
                                                    <FormSelectOption
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.neighbourhood_id}
                                                        options={this.props.places && this.props.places.find(place => place.name === values.government)}
                                                        disabled={!values.government}
                                                        label="المنطقة"
                                                        required
                                                        name="neighbourhood_id"
                                                        error={touched.neighbourhood_id && errors.neighbourhood_id}
                                                    />
                                                    <FormInput
                                                        autoCapitalize="none"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.address}
                                                        label="العنوان"
                                                        required
                                                        placeholder="ادخل العنوان"
                                                        type="text"
                                                        name="address"
                                                        error={touched.address && errors.address}
                                                    />
                                                </div>
                                                      <div className="col-12">
                                      <div className="text-right px-1 mt-5">
                                        <h2 style={{ fontSize: '14px', fontWeight: 'bold' }}>اضف بيانات الاتصال للشراء من متجرك</h2>
                                      </div>
                                      <div className="row">
                                        <div className="col-12 col-lg-6">
                                                    <FormInput
                                                        autoCapitalize="none"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.land_line_number}
                                                        label="تليفون ارضى"
                                                        placeholder="ادخل رقم التليفون الارضى"
                                                        type="text"
                                                        required
                                                        name="land_line_number"
                                                        error={touched.land_line_number && errors.land_line_number}
                                                    />
                                                    <FormInput
                                                        autoCapitalize="none"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.mobile_number}
                                                        label="موبايل"
                                                        required
                                                        placeholder="ادخل رقم الموبايل"
                                                        type="text"
                                                        name="mobile_number"
                                                        error={touched.mobile_number && errors.mobile_number}
                                                    />
                                        </div>
                                        <div className="col-12 col-lg-6">
                                                                                                        <FormInput
                                                      onChange={handleChange}
                                                      onBlur={handleBlur}
                                                      value={values.messenger_link}
                                                      label="ماسنجر"
                                                      placeholder=""
                                                      type="text"
                                                      name="messenger_link"
                                                      error={touched.messenger_link && errors.messenger_link}
                                                    />
                                                    <FormInput
                                                      onChange={handleChange}
                                                      onBlur={handleBlur}
                                                      value={values.whatsapp}
                                                      label="واتس اب"
                                                      placeholder=""
                                                      type="text"
                                                      name="whatsapp"
                                                      error={touched.whatsapp && errors.whatsapp}
                                                    />
                                        </div>
                                      </div>
                                  </div>
                                    <div className="col-12">
                                      <div className="text-right px-1 mt-5">
                                        <h2 style={{ fontSize: '14px', fontWeight: 'bold' }}>اضف السوشيال ميديا لمتجرك "اختياري"</h2>
                                      </div>
                                      <div className="row">
                                        <div className="col-12 col-lg-6">
                                          <FormInput
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.store_facebook_page}
                                            label=" صفحة فيس بوك"
                                            placeholder=""
                                            type="text"
                                            name="store_facebook_page"
                                            error={touched.store_facebook_page && errors.store_facebook_page}
                                          />
                                          <FormInput
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.store_twitter_page}
                                            label="صفحة تويتر"
                                            placeholder=""
                                            type="text"
                                            name="store_twitter_page"
                                            error={touched.store_twitter_page && errors.store_twitter_page}
                                          />
                                        </div>
                                        <div className="col-12 col-lg-6">
                                          <FormInput
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.store_instagram_page}
                                            label="صفحة انستجرام"
                                            placeholder=""
                                            type="text"
                                            name="store_instagram_page"
                                            error={touched.store_instagram_page && errors.store_instagram_page}
                                          />
                                          <FormInput
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.store_youtube_page}
                                            label=" صفحة يوتيوب"
                                            placeholder=""
                                            type="text"
                                            name="store_youtube_page"
                                            error={touched.store_youtube_page && errors.store_youtube_page}
                                          />
                                        </div>
                                      </div>
                                      {<div style={{marginTop:"16px"}}>
                                      <div className="d-flex flex-column">
                                        <label>
                                          <Field type="checkbox" name="installment"/>
                                          هل تقدم خدمة البيع بالتقسيط
                                        </label>
                                        <label>
                                          <Field type="checkbox" name="city_delivery"/>
                                          هل تقدم خدمة شحن البضاعة لمحافظات اخري
                                        </label>
                                        <label>
                                          <Field type="checkbox" name="delivery"/>
                                          هل تقدم خدمة توصيل البضاعة للعميل
                                        </label>
                                      </div>
                                    </div>}
                                  </div>
                                  </div>
                                  <div className="text-right mt-5 position-absolute bottom-0 right-0 button-container">
                                    <button
                                      type="button"
                                      className="btn btn-info px-5 pt-1 pb-1"
                                      onClick={() => this.setState({ isAddClicked: false })}
                                    >
                                      رجوع
                                    </button>
                                    <button
                                      type="submit"
                                      className="btn btn-info px-5 pt-1 pb-1 ml-3"
                                      disabled={!isValid || isSubmitting}
                                    >
                                      حفظ
                                    </button>
                                  </div>
                                </form>
                              </div>
                            )}
                        </Formik>
                    </FloatingCard>
                )}
            </ShadowCard>
          </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    branches: state.branches,
    places: state.places.places,
    profile: state.profile
})

const mapDispatchToProps = (dispatch) => ({
    startFetchStoreBranches: (slug) => dispatch(startFetchStoreBranches(slug)),
    startRemoveStoreBranch: (id) => dispatch(startRemoveStoreBranch(id)),
    startCreateStoreBranch: (values) => dispatch(startCreateStoreBranch(values)),
    startFetchPlaces: () => dispatch(startFetchPlaces()),
    startFetchWorkDomains: () => dispatch(startFetchWorkDomains())
})

export default connect(mapStateToProps, mapDispatchToProps)(AccountStoreBranchesPage)