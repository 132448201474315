import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import Logo from '../images/Group 692.png'

const BranchCard = ({ slug, mobile_number, store_logo, neighbourhood, name_arabic, name_english, address, locale, comesFromSection }) => {
    
    const [image, setImage] = useState(store_logo || Logo)

    return (
        <div className="branch">
            <Link to={{ pathname : `/${locale}/store/${slug}`, state: { comesFromSection } }}>
                <div className="branch__logo">
                    <img src={image} alt={locale == "ar" ? name_arabic : name_english} onError={() => setImage(Logo)} />
                </div>
            </Link>
            <div className="branch__details">
                <div className="heading">
                    <Link to={{ pathname : `/${locale}/store/${slug}`, state: { comesFromSection } }}>
                        <h4 className="line-clamp">{locale == "ar" ? name_arabic : name_english} - {locale == "ar" ? neighbourhood.name : neighbourhood.name_en}</h4>
                    </Link>
                </div>
                <div className="branch__row contact">
                    <i className="fas fa-phone"></i>
                    <span>{mobile_number}</span>
                </div>
                <div className="branch__row">
                    <i className="fas fa-map-marker"></i>
                    <span className="line-clamp">{address}</span>
                </div>
            </div>
        </div>
    )
}

export default BranchCard