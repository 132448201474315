import axios from 'axios';

const isProduction = true;
let baseURL = "https://backend.matdawarsh.com/en/api/v1/";
const fetchUrl = new URL(window.location.href);

//if we are in production env just replace url with production one
if(isProduction){
  //if store in egypt just avoid
  if(!fetchUrl.host.includes("eg.")){
    const version = "/v1/";
    //set backend url
    baseURL = `${fetchUrl.protocol}//backend.${fetchUrl.host}/en/api${version}`;
  }
}

const request = axios.create({
  //baseURL: 'http://52.14.150.126:8000/en/api/v1/',
  baseURL,
  headers: {
    'Content-Type': 'application/json',
  },
});

request.interceptors.request.use((config) => {
  const token = JSON.parse(localStorage.getItem('user')) && JSON.parse(localStorage.getItem('user')).token;
  if (token) {
    config.headers.Authorization = `JWT ${token}`;
  }

  return config;
});

export default request;
