import React from "react";
import ReactPaginate from "react-paginate";

export class Pagination extends React.Component {
  render() {
    return (
      <ReactPaginate
        previousLabel={"previous"} // prev
        nextLabel={"next"} // next
        breakLabel={"..."}
        breakClassName={"break-me"}
        pageCount={this.props.pages_count} // total number of pages
        marginPagesDisplayed={2} // The number of pages to display for margins.
        pageRangeDisplayed={1} // The range of pages displayed.
        onPageChange={(e) => this.props.handlePageClick(e.selected)}
        containerClassName={"pagination"}
        subContainerClassName={"pages pagination"}
        activeClassName={"active"}
        initialPage={this.props.pageNumber}
        disableInitialCallback={true}
      />
    );
  }
}

export default Pagination;
