import API from "../api";
import {
  ADD_FAVORITE,
  REMOVE_FAVORITE,
  SET_FAVORITES,
  CLEAR_FAVORITES,
  FAVORITES_REQUEST,
  FAVORITES_SUCCESS,
} from "../constants/action-types";

// FAVORITES

// ADD_FAVORITE
export const addFavorite = (sub_model) => ({
  type: ADD_FAVORITE,
  sub_model,
});

export const startAddFavorite = (sub_model = 0) => (dispatch) => {
  return API.post("collector/bookmarked/", {
    sub_model_id: sub_model,
  })
    .then((response) => {
      dispatch(addFavorite(response.data));
    })
    .catch((error) => {
      // Ignore Error Message
    });
};

// REMOVE_FAVORITES
export const removeFavorite = (id) => ({
  type: REMOVE_FAVORITE,
  id,
});

export const startRemoveFavorite = (id) => (dispatch) => {
  return API.delete(`collector/bookmarked/${id}/`)
    .then((response) => {
      dispatch(removeFavorite(id));
    })
    .catch((err) => {
      // Ignore Error Message
    });
};

export const setFavorites = (favorites) => ({
  type: SET_FAVORITES,
  favorites,
});

export const startSetFavorites = (pageNumber = 1) => (dispatch) => {
  dispatch({
    type: FAVORITES_REQUEST,
  });

  return API.get(`collector/bookmarked/?page=${pageNumber}`).then(
    (response) => {
      dispatch(setFavorites(response.data));
      dispatch({
        type: FAVORITES_SUCCESS,
      });
    },
    (err) => {
      if (err.response.status === 401) {
        localStorage.removeItem("user");
        location.reload();
      }
    }
  );
};

export const clearFavorites = () => ({
  type: CLEAR_FAVORITES,
});
