import React from 'react'
import { connect } from 'react-redux'

const fetchUrl = new URL(window.location.href);

const StoreParent = ({ t, locale, website, length, isFlipped, setFlip, lowestPerStore }) => (
    <div className={`online-store ${locale === 'ar' ? 'direction-rtl' : undefined} w-100`}>
        <div className="online-store__information">
     
            <div className='online-store__information__avatar biggerAvatar'>
                <img src={website.image} alt={website.slug} />
            </div>
            <div className="online-store__information__price big-size">
                <span className="value">{lowestPerStore}</span>
                <span className="currency">{
                    fetchUrl.host.includes("sa.") ? t("sub_models.currency_sa")
                    :
                    fetchUrl.host.includes("kw.") ? t("sub_models.currency_kw")
                    :
                    fetchUrl.host.includes("ae.") ? t("sub_models.currency_ae")
                    :
                    t("sub_models.currency_eg")
                }</span>
            </div>
        </div>
        <div className={`online-store__redirect itemsCount ${isFlipped ? 'dark-bk' : undefined}`} onClick={() => setFlip(!isFlipped)}>
            +{length} {locale === 'en' ? 'items' : 'منتج'} {isFlipped ? <i className="fas fa-caret-down"></i> : <i className="fas fa-caret-up"></i>}
        </div>
    </div>
)

const mapStateToProps = (state) => ({
    locale: state.locales
})

export default connect(mapStateToProps)(StoreParent)