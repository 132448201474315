import React, { useRef, useEffect } from 'react'
import { connect } from 'react-redux'
import Notifications from './Notifications'
import Spinner from './Spinner'
import { isNotificationsOpened } from '../actions/notifications'

const NotificationContainer = ({ isFetching, locale, notifications, isNotificationsOpened }) => {
    function useOutsideAlerter(ref) {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event) {
          if (ref.current && !ref.current.contains(event.target)) {
            isNotificationsOpened();
          }
        }
      
        useEffect(() => {
          // Bind the event listener
          document.addEventListener("mousedown", handleClickOutside);
          return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
          };
        });
    }

    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef);

    return (
        <div id="notificationContainer" ref={wrapperRef} className={locale === 'ar' ? 'left-0' : 'right-0'}>
            <div className='notificationsContainer'>
                {isFetching ? <Spinner /> : <Notifications {...notifications} />}
            </div>
        </div>
    )
}
const mapStateToProps = (state) => ({
    locale: state.locales,
    notifications: state.notifications,
    isFetching: state.notifications.isFetching
})

const mapDispatchToProps = (dispatch) => ({
    isNotificationsOpened: () => dispatch(isNotificationsOpened())
})

export default connect(mapStateToProps, mapDispatchToProps)(NotificationContainer)