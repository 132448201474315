import { 
    SET_SECTIONS,
    FETCH_SECTIONS_REQUEST,
    FETCH_SECTIONS_SUCCESS,
    FETCH_SECTIONS_FAILURE
} from '../constants/action-types';

const defaultState = {
    sections: [],
    isFetching: false,
    error: null
}

export default (state = defaultState, action) => {
    switch(action.type) {
        case SET_SECTIONS:
            return {
                ...state,
                sections: action.sections
            }
        case FETCH_SECTIONS_REQUEST: 
            return {
                ...state,
                isFetching: true
            }
        case FETCH_SECTIONS_SUCCESS:
        case FETCH_SECTIONS_FAILURE:
            return {
                ...state,
                isFetching: false,
                error: action.message ? action.message : null
            }
        default:
            return state;
    }
}