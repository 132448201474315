import { 
    AUTH_LOGIN, 
    AUTH_LOGOUT, 
    USER_LOGIN_REQUEST,
    USER_LOGIN_RESPONSE
} from '../constants/action-types'
// USER AUTH

const userState = {
    user: {},
    isLoggedIn: false,
    isFetching: false
}

export default (state = userState, action) => {
    switch(action.type) {
        case AUTH_LOGIN:
            const token = action.user.token;
            const user = action.user.user;
            return {
                ...state,
                token,
                user: {
                    ...user
                },
                isLoggedIn: true
            };
        case USER_LOGIN_REQUEST:
            return {
                ...state,
                isFetching: true
            }
        case USER_LOGIN_RESPONSE:
            return {
                ...state,
                isFetching: false
            }
        case AUTH_LOGOUT:
            return userState;
        default: 
            return state;
    }
};
