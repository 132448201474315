import React from 'react'
import { Link } from 'react-router-dom'

const Switch = ({ route, name = '', active = false, disabled = false }) => {
  const getRoute = () => {
    if (disabled) return;

    return route;
  };

  return (
    <Link to={getRoute()} className={`Switch ${active ? 'active' : undefined}`}>
        <span>{name}</span>
    </Link>
  )
}

export default Switch;