import API from '../api'
import { 
    GET_CATEGORIES,
    REQUEST_CATEGORIES,
    REQUEST_CATEGORIES_SUCCESS,
    REQUEST_CATEGORIES_FAILURE
} from '../constants/action-types'


export const getCategories = (categories) => ({
    type: GET_CATEGORIES,
    categories
})

export const startGetCategories = () => async dispatch => {
    dispatch({ type: REQUEST_CATEGORIES })
    try {
        const response = await API.get('basic-data/categories/')
        dispatch(getCategories(response.data))
        dispatch({ type: REQUEST_CATEGORIES_SUCCESS })
    } catch (err) {
        dispatch({ type: REQUEST_CATEGORIES_FAILURE, message: error.response.data || 'Something went wrong.' })
    }
}