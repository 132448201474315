import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import SubModelGrid from "./SubModelGrid";
import { getSectionFeaturedModels } from "../actions/sections";
import Spinner from "./Spinner";

class SliderContent extends React.Component {
  state = {
    isFetching: true,
    featured_models: [],
  }

  async componentDidMount(){
    try{
      const featuredModels = await this.props.getSectionFeaturedModels(this.props.id);
      this.setState({ featured_models: featuredModels.data.results });
      this.setState({ isFetching: false });
    }catch(error){
      //
    }
  }

  render() {
    const { id, locale, name_arabic, name_english, slug, featured_models } = this.props;

    const settings = {
      dots: true,
      arrows: false,
      infinite: false,
      autoplay: false,
      draggable: true,
      speed: 500,
      pauseOnHover: true,
      slidesToShow: 6, // condition logic
      slidesToScroll: 6,
      initialSlide: 0,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 5,
            slidesToScroll: 5,
            infinite: true,
            dots: false,
          },
        },
        {
          breakpoint: 900,
          settings: {
            dots: true,
            slidesToShow: 4,
            slidesToScroll: 4,
          },
        },
        {
          breakpoint: 800,
          settings: {
            dots: true,
            slidesToShow: 3,
            slidesToScroll: 3,
          },
        },
        {
          breakpoint: 600,
          settings: {
            dots: false,
            slidesToShow: 2,
            slidesToScroll: 2,
          },
        },
        {
          breakpoint: 500,
          settings: {
            dots: false,
            slidesToShow: 2,
            slidesToScroll: 2,
          },
        },
        {
          breakpoint: 320,
          settings: {
            dots: false,
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };

    return <div className="featuresGroup">
      <div
        className={`featuresGroup__heading ${
          window.innerWidth > 800
            ? `${locale === "en" ? "flex-row-reverse" : {}}`
            : `${locale === "ar" ? "flex-row-reverse" : ""}`
        }`}
      >
        <span></span>
        <Link to={`/${locale}/section/${slug}`}>
          <h3>{locale == "ar" ? name_arabic : name_english}</h3>
        </Link>
      </div>
      {window.innerWidth > 800 ? (
        <div className="featuresGroup__slider">
          {this.state.isFetching ? <Spinner /> : <Slider {...settings}>
            {this.state.featured_models.map((featured_model, index) => (
              <SubModelGrid key={index} {...featured_model} />
            ))}
          </Slider>}
        </div>
      ) : (
        <div className="overflow-wrapper">
          <div className="featuresGroup__slider featuresGroup__slider--mobile d-inline-flex justify-content-center">
            {this.state.featured_models.map((featured_model, index) => (
              <SubModelGrid key={index} size="combo" {...featured_model} />
            ))}
          </div>
        </div>
      )}
    </div>
  }
}

const mapStateToProps = (state) => ({
  locale: state.locales,
});

const mapDispatchToProps = (dispatch) => ({
  getSectionFeaturedModels: (sectionId) => dispatch(getSectionFeaturedModels(sectionId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SliderContent);
