import React from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'

const NotificationItem = ({ title, body, link, locale }) => (
    <div className="notificationActions">
        <Link className={`notificationLink ${link}`} to={`/model/${link}`} onClick={(e) => { !link && e.preventDefault() }}>
            <div className={`notificationItem ${locale === 'ar' ? 'flex-row-reverse' : ''}`}>
                <div className={`notificationIcon ${locale === 'ar' ? 'ml-3' : 'mr-3'}`}>
                    <i className="far fa-bell"></i>
                </div>
                <div className={`notificationBody ${locale === 'ar' ? 'text-right' : 'text-left'}`}>
                    <div className='notificationHeader'>
                        <p className="name ellipsis font-weight-bold">{title}</p>
                    </div>
                    <div className='notificationFooter'>
                        <p className="name">{body}</p> 
                    </div>
                </div>
            </div>
        </Link>
    </div>
)

const mapStateToProps = (state) => ({
    locale: state.locales
})

const mapDispatchToProps = (dispatch) => ({
    startDeleteUserNotification: (id) => dispatch(startDeleteUserNotification(id))
})

export default connect(mapStateToProps, mapDispatchToProps)(NotificationItem)