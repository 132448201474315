import React from "react";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import { connect } from "react-redux";
import { startAddFavorite, startRemoveFavorite } from "../actions/favorites";
import { FacebookShareButton, TwitterShareButton, FacebookIcon, TwitterIcon } from "react-share";
import Ad from "./Ad";
import SubModelGrid from "./SubModelGrid";
import defaultImage from "../images/default.jpg";

export class ModelImages extends React.Component {
  state = {
    images: this.props.sub_model.images || [],
    selectedImage: this.props.sub_model.images[0]
      ? this.props.sub_model.images[0].image
      : defaultImage,
    bookmarked: this.props.sub_model.bookmarked,
  };

  selectImageHandler(imagePosition) {
    this.setState({
      selectedImage: [...this.state.images].find((image, index) => imagePosition === index).image,
    });
  }

  favoritesHandler(pk) {
    if (!this.props.isLoggedIn) return this.props.history.push(`/${this.props.locale}/user/login`);

    this.setState({ bookmarked: !this.state.bookmarked });

    this.state.bookmarked
      ? this.props.favorites.find(({ pk: id, sub_model }) => {
          if (sub_model.pk === pk) this.props.startRemoveFavorite(id);
        })
      : this.props.startAddFavorite(pk);
  }

  render() {
    const { locale, sub_model, featured_sub_models_data } = this.props;
    const { images, selectedImage, bookmarked } = this.state;
    const fetchUrl = new URL(window.location.href);
    const shareUrl = `${fetchUrl.protocol}//${fetchUrl.host}${this.props.location.pathname}`;

    return (
      <div className="model-images">
        <div className="model-images__slider-wrapper">
          <div className="model-images__slider-wrapper__selected">
            <div className="model-images__slider-wrapper__selected__image">
              <img src={selectedImage} alt="Focused Image" />
            </div>
            <div className="model-images__slider-wrapper__selected__actions">
              <div className="model-images__slider-wrapper__selected__actions__share">
                <span>{locale === "ar" ? "شارك" : "Share"}</span>
                <FacebookShareButton
                  url={shareUrl}
                  quote={sub_model.name[locale]}
                  hashtag={locale === "ar" ? "متدورش" : "Matdawarsh"}
                >
                  <FacebookIcon size={25} round={true} />
                </FacebookShareButton>
                <TwitterShareButton
                  url={shareUrl}
                  title={sub_model.name[locale]}
                  hashtags={locale === "ar" ? ["متدورش"] : ["Matdawarsh"]}
                >
                  <TwitterIcon size={25} round={true} />
                </TwitterShareButton>
              </div>
              {
                <div
                  className="model-images__slider-wrapper__selected__actions__push_to_favorites"
                  onClick={() => this.favoritesHandler(sub_model.pk)}
                >
                  <i className={`${bookmarked ? "fas fa-heart" : "far fa-heart"}`}></i>
                  {locale === "ar" ? "اضف الي المفضلة" : "Add to favorites"}
                </div>
              }
            </div>
          </div>
          <div className="model-images__slider-wrapper__thumbs">
            {images.map((image, index) => (
              <div
                key={index}
                onClick={() => this.selectImageHandler(index)}
                className={`model-images__slider-wrapper__thumbs__thumb ${
                  image.image === selectedImage ? "selected" : ""
                }`}
              >
                <img src={image.image} alt={index} />
              </div>
            ))}
          </div>
        </div>
        <Ad position={"model_top_vertical_rectangle"} width={"100%"} height={100} />
        {featured_sub_models_data.length ? (
          <div className={`featuresGroup py-3 ${window.innerWidth > 800 ? "" : "d-none"}`}>
            <div className="featuresGroup__heading flex-row-reverse justify-content-start">
              <h3>{this.props.locale === "ar" ? "شاهد المزيد" : "See also"}</h3>
            </div>
            <div className="featuresGroup__slider d-flex flex-wrap justify-content-end">
              {featured_sub_models_data
                .filter((model, index) => index < 6)
                .map((featured_model, index) => (
                  <SubModelGrid key={index} size={"combo"} width={"w-0"} {...featured_model} />
                ))}
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isLoggedIn: state.auth.isLoggedIn,
  locale: state.locales,
  favorites: state.favorites.data,
});

const mapDispatchToProps = (dispatch) => ({
  startAddFavorite: (pk) => dispatch(startAddFavorite(pk)),
  startRemoveFavorite: (pk) => dispatch(startRemoveFavorite(pk)),
});

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(ModelImages);
