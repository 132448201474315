import React from 'react'
import { connect } from 'react-redux'
import { setSearchParameter } from '../actions/search'
import { isPlacesVisited } from '../actions/isPlacesVisited'

const Neighborhoods = ({ pk, name, parameter, setSearchParameter, governmentName, isPlacesVisited }) => 
    <li onClick={() => {
                setSearchParameter(parameter, pk, governmentName, name);
                isPlacesVisited(false);
                document.body.style.overflow = 'auto';
    }}>{name}</li>

const mapDispatchToProps = (dispatch) => ({
    setSearchParameter: (parameter, pk, governmentName, neighborhoodName) => dispatch(setSearchParameter(parameter, pk, governmentName, neighborhoodName)),
    isPlacesVisited: () => dispatch(isPlacesVisited(false))
})

export default connect(undefined,  mapDispatchToProps)(Neighborhoods)