import React from 'react'
import { connect } from 'react-redux'
import Governments from './Governments'
import Neighborhoods from './Neighborhoods'
import { startFetchPlaces } from '../actions/places'

class SearchFilters extends React.Component {

    state = {
        SelectedPlaceNeighborhoods: null,
        governmentName: null
    }

    componentDidMount() {
        this.props.startFetchPlaces(); // start fetch places only when try open         
    }

    findSelectedPlaceNeighborhoods = (neigborhoods, governmentName) => {
        this.setState(() => ({ SelectedPlaceNeighborhoods: neigborhoods, governmentName }))
    }

    render() {
        const { SelectedPlaceNeighborhoods, governmentName } = this.state;
        const { locale, places: { places } } = this.props;
        return (
            <div className={`filters ${locale === 'ar' ? 'right-0' : 'left-0'}`}>
                <div className="filters__wrapper">
                    <ul className={`filters__list filters__list--width ${SelectedPlaceNeighborhoods ? '' : 'hidden'}`}>
                        <div className="filters__choose" hidden={window.innerWidth > 800}>
                            <p>اختر المنطقة</p>
                            <i className="fas fa-long-arrow-alt-left" onClick={() => this.setState(() => ({ SelectedPlaceNeighborhoods: null }))}>
                                <span>رجوع</span>
                            </i>
                        </div>
                        {SelectedPlaceNeighborhoods && SelectedPlaceNeighborhoods.map((neighborhood, index) => <Neighborhoods key={index} {...neighborhood} governmentName={governmentName} />)}
                    </ul>
                    <ul className='filters__list' hidden={SelectedPlaceNeighborhoods && window.innerWidth < 800}>
                        <div className="filters__choose" hidden={window.innerWidth > 800}>
                            <p>اختر المحافظة</p>
                        </div>
                        {places && places.map((place, index) => <Governments findSelectedPlaceNeighborhoods={this.findSelectedPlaceNeighborhoods} key={index} {...place} />)}
                    </ul>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    locale: state.locales,
    places: state.places
})

const mapDispatchToProps = (dispatch) => ({
    startFetchPlaces: () => dispatch(startFetchPlaces())
})

export default connect(mapStateToProps, mapDispatchToProps)(SearchFilters)
