import React from 'react'
import FetchError from '../FetchError'

const FormSelecOption = props => {

    const { label, options = null, domains = null, data = null, conds = null, error, useEn = false, ...rest } = props
    return (
        <React.Fragment>
            <div className="single-input">
                <label>
                    {label}
                    {props.required && <strong className="red-text mr-3">*</strong>}
                </label>
                <select
                    onChange={props.onChange}
                    onBlur={props.onBlur}
                    {...rest}
                >
                    <option value="" label="" />
                    {conds && conds.map(({ name }, index) => <option key={index} value={name['en']} label={name['ar']}>{name['ar']}</option>)}
                    {data && data.map(({ name, pk }) => <option key={pk} value={pk} label={useEn ? name['en'] : name['ar'] || name}>{useEn ? name['en'] : name['ar'] || name}</option>)}
                    {options && options.neighbourhoods ?
                        options.neighbourhoods.filter(({ parameter }) => parameter !== 'government')
                            .map(({ name, pk }, index) => <option key={index} value={pk} label={name}>{name}</option>) :
                        options && options.map(({ name }, index) => <option key={index} value={name} label={name}>{name}</option>)
                    }
                    {domains && domains.map(domain => <option key={domain.pk} value={domain.pk} label={domain.name}>{domain.name}</option>)}
                </select>
            </div>
            {error && <FetchError message={error} />}
        </React.Fragment>
    )
}

export default FormSelecOption