import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";

const fetchUrl = new URL(window.location.href);

const BrandsList = ({ categorySlug, brands, locale, categoryName }) => (
  <div className={`brands-list ${locale === "ar" ? "rtl" : ""}`}>
    {brands.map((brand) => (
      <span key={brand.slug} className="badge badge-pill badge-info">
        <Link to={`/${locale}/search/${brand.slug}?categories=${categoryName["en"]}`}>{brand.name}</Link>
      </span>
    ))}
  </div>
);

const SectionStaticPrices = ({ t, locale, name, slug, image, brands }) => (
  <div className="section__wrapper">
    <BrandsList brands={brands} locale={locale} categorySlug={slug} categoryName={name} />
    <div className={`section__staticPrices ${locale === "en" && "direction-rtl"}`}>
      <div
        className={`section__staticPrices__image ${locale === "ar" ? "text-left" : "text-right"}`}
      >
        <img src={image} alt={name} />
        <Link className="d-none d-md-block" to={`/${locale}/category/${slug}`}>
          {t("section.see_more")}
        </Link>
      </div>

      <div className="section__staticPrices__prices">
        <div
          className={`section__staticPrices__header d-flex align-items-center ${
            locale === "ar"
              ? "justify-content-start direction-rtl text-right"
              : "direction-ltr flex-row-reverse text-left"
          }`}
        >
          <Link to={`/${locale}/category/${slug}`}>
            <h2>{name[locale]}</h2>
          </Link>
          <span className="price-categories">
            {locale === "ar" ? "الفئات السعرية" : "Price categories"}
          </span>
        </div>
        <ul className={locale === "ar" ? "mr-auto text-right" : "ml-auto text-left"}>
          <li>
            <Link to={`/${locale}/category/${slug}?price=0to2500`}>
              {t("section.less_than")} 2,500
            </Link>
            <span>{
              fetchUrl.host.includes("sa.") ? t("sub_models.currency_sa")
              :
              fetchUrl.host.includes("kw.") ? t("sub_models.currency_kw")
              :
              fetchUrl.host.includes("ae.") ? t("sub_models.currency_ae")
              :
              t("sub_models.currency_eg")
            }</span>
          </li>
          <li>
            <Link to={`/${locale}/category/${slug}?price=2500to3500`}>3,500 - 2,500</Link>
            <span>{
              fetchUrl.host.includes("sa.") ? t("sub_models.currency_sa")
              :
              fetchUrl.host.includes("kw.") ? t("sub_models.currency_kw")
              :
              fetchUrl.host.includes("ae.") ? t("sub_models.currency_ae")
              :
              t("sub_models.currency_eg")
            }</span>
          </li>
          <li>
            <Link to={`/${locale}/category/${slug}?price=3500to5000`}>5,000 - 3,500</Link>
            <span>{
              fetchUrl.host.includes("sa.") ? t("sub_models.currency_sa")
              :
              fetchUrl.host.includes("kw.") ? t("sub_models.currency_kw")
              :
              fetchUrl.host.includes("ae.") ? t("sub_models.currency_ae")
              :
              t("sub_models.currency_eg")
            }</span>
          </li>
          <li>
            <Link to={`/${locale}/category/${slug}?price=5000to7000`}>7,000 - 5,000</Link>
            <span>{
              fetchUrl.host.includes("sa.") ? t("sub_models.currency_sa")
              :
              fetchUrl.host.includes("kw.") ? t("sub_models.currency_kw")
              :
              fetchUrl.host.includes("ae.") ? t("sub_models.currency_ae")
              :
              t("sub_models.currency_eg")
            }</span>
          </li>
          <li>
            <Link to={`/${locale}/category/${slug}?price=7000to10000`}>10,000 - 7,000</Link>
            <span>{
              fetchUrl.host.includes("sa.") ? t("sub_models.currency_sa")
              :
              fetchUrl.host.includes("kw.") ? t("sub_models.currency_kw")
              :
              fetchUrl.host.includes("ae.") ? t("sub_models.currency_ae")
              :
              t("sub_models.currency_eg")
            }</span>
          </li>
          <li>
            <Link to={`/${locale}/category/${slug}?price=10000to1000000`}>
              {t("section.more_than")} 10,000
            </Link>
            <span>{
              fetchUrl.host.includes("sa.") ? t("sub_models.currency_sa")
              :
              fetchUrl.host.includes("kw.") ? t("sub_models.currency_kw")
              :
              fetchUrl.host.includes("ae.") ? t("sub_models.currency_ae")
              :
              t("sub_models.currency_eg")
            }</span>
          </li>
        </ul>
      </div>
      <Link className="see-more__mobile d-block d-md-none" to={`/${locale}/category/${slug}`}>
        {t("section.see_more")}
      </Link>
    </div>
  </div>
);

const mapStateToProps = (state) => ({
  locale: state.locales,
});

export default compose(withTranslation(), connect(mapStateToProps))(SectionStaticPrices);
