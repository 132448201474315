import React from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { compose } from "redux";
import { Link, withRouter } from "react-router-dom";
import Ad from "./Ad";
//import websiteLogo from "../images/Group 692.png";
import playstoreLogo from "../images/download-google-play@1x.png";

//load dynamic logo
let defaultLogo = "";
const fetchUrl = new URL(window.location.href);
if(fetchUrl.host.includes("eg.")){
  defaultLogo = require("../images/Egypt-logo.png");
}else if(fetchUrl.host.includes("sa.")){
  defaultLogo = require("../images/ksa-logo.png");
}else if(fetchUrl.host.includes("ae.")){
  defaultLogo = require("../images/UAE-logo.png");
}else if(fetchUrl.host.includes("kw.")){
  defaultLogo = require("../images/kuwait-logo.png");
}else{
  defaultLogo = require("../images/Group 692.png");
}

const Footer = ({ t, locale, location }) => {
  // IS LOCATION AT AUTH PAGES ?
  if (location.pathname.includes("/user/")) return null;

  return (
    <footer className="footer">
      <Ad position={"mobile_footer"} width={"100%"} height={"auto"} mobile={true} />
      <div id="wrapper">
        <div className={`footer__main ${locale === "ar" && "direction-rtl"}`}>
          <div className={`footer__information`}>
            <div className={`header__main__logo ${locale === "ar" && "text-right"}`}>
              <Link to={`/${locale}/`}>
                <img src={defaultLogo} alt={"متدورش"} />
              </Link>
            </div>
            <div className="description">
              <p>{
                fetchUrl.host.includes("sa.") ? t("footer.brief_sa")
                :
                fetchUrl.host.includes("kw.") ? t("footer.brief_kw")
                :
                fetchUrl.host.includes("ae.") ? t("footer.brief_ae")
                :
                t("footer.brief_eg")
              }</p>
            </div>
            <div className={`footer__socialmedia ${locale === "ar" && "text-right"}`}>
              <a href="https://www.instagram.com/matdawarsh/" target="_blank">
                <i className="fab fa-instagram"></i>
              </a>
              <a href="https://twitter.com/matdawarsh" target="_blank">
                <i className="fab fa-twitter"></i>
              </a>
              <a href="https://www.facebook.com/matdawarsh" target="_blank">
                <i className="fab fa-facebook"></i>
              </a>
              <a href="https://www.youtube.com/channel/UCIjWXYwCG840qxgAM6ymVzA" target="_blank">
                <i className="fab fa-youtube"></i>
              </a>
            </div>
          </div>
          <div className="footer__static__links">
            <Link to={`/${locale}/`}>{t("footer.privacy_policy")}</Link>
            <Link to={`/${locale}/`}>{t("footer.user_agreement")}</Link>
            <Link to={`/${locale}/`}>{t("footer.sitemap")}</Link>
          </div>
          <div className="footer__static__links">
            <Link to={`/${locale}/about-us`}>{t("footer.about")}</Link>
            <Link to={`/${locale}/contact-us`}>{t("footer.call")}</Link>
            <Link to={`/${locale}/`}>{t("footer.help")}</Link>
          </div>
          <div className="footer__apps">
            <a href="https://play.google.com/store/apps/details?id=com.eg.matdawarsh" target="_blank"><img src={playstoreLogo} alt="Matdawarsh Playstore App"/></a>
          </div>
        </div>
      </div>
      <div className="d-flex" style={{backgroundColor: "#ededed",borderTop: "1px solid #ddd"}}>
        <p style={{textAlign: "right", padding: "16px", margin: 0}}>
          {t("footer.note")}
        </p>
      </div>
      <div className="created-by d-flex justify-content-center align-items-center">
        <p className="m-0">
          Created by{" "}
          <a href="https://progaze.net/" target="_blank">
            Progaze
          </a>{" "}
          2020
        </p>
      </div>
    </footer>
  );
};

const mapStateToProps = (state) => ({
  locale: state.locales,
});

export default compose(withRouter, withTranslation(), connect(mapStateToProps))(Footer);
