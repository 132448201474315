import React from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";

function HelmetMetaData(props) {
  const fetchUrl = new URL(window.location.href);
  const splitHost = fetchUrl.host.split(".");
  const subDomain = splitHost[0];
  const locale = props.locale;
  let location = useLocation();
  let currentUrl = window.location.href;
  let quote = props.quote !== undefined ? props.quote : "";
  let title = props.title !== undefined ? props.title : "";
  let image =
    props.image !== undefined
      ? props.image
      : "https://scontent-hbe1-1.xx.fbcdn.net/v/t1.0-9/131899151_114048813895769_3558714867290479089_o.jpg?_nc_cat=108&ccb=2&_nc_sid=8bfeb9&_nc_eui2=AeHijh7wz7kNfNDoK6tJOdObm2pvVbklAB6bam9VuSUAHrOK5Le9_IMO0dTBHvKeopLxTkqNuZJWLzCt-vd2lBuW&_nc_ohc=PGyGVMcxUNcAX8xGEKb&_nc_ht=scontent-hbe1-1.xx&oh=0218cd84368892272af4e5aadcd7fe9c&oe=601BA599";
  let description = props.description !== undefined ? props.description : "";
  let hashtag = props.hashtag !== undefined ? props.hashtag : "#matdawarsh";
  return (
    <Helmet>
      <title>{title}</title>
      <link rel="alternate" href={`${fetchUrl.protocol}//${fetchUrl.host}/en`} hreflang={`en-${subDomain}`} />
	    <link rel="alternate" href={`${fetchUrl.protocol}//${fetchUrl.host}/ar`} hreflang={`ar-${subDomain}`} />
      <meta charset="utf-8" />
      <meta http-equiv="X-UA-Compatible" content="IE=edge" />
      <meta name="csrf_token" content="" />
      <meta property="type" content="website" />
      <meta property="url" content={currentUrl} />
      <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
      <meta name="msapplication-TileColor" content="#ffffff" />
      <meta name="msapplication-TileImage" content="/ms-icon-144x144.png" />
      <meta name="theme-color" content="#ffffff" />
      <meta name="_token" content="" />
      <meta name="robots" content="noodp" />
      <meta property="title" content={title} />
      <meta property="quote" content={quote} />
      <meta name="description" content={description} />
      <meta property="image" content={image} />

      <meta property="og:type" content="website" />
      <meta property="og:title" content={title} />
      <meta property="og:quote" content={quote} />
      <meta property="og:hashtag" content={hashtag} />
      <meta property="og:image" content={image} />
      <meta content="image/*" property="og:image:type" />
      <meta property="og:url" content={currentUrl} />
      <meta property="og:site_name" content="Matdawarsh" />
      <meta property="og:description" content={description} />

      <meta name="twitter:title" content={title} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta property="twitter:domain" content={fetchUrl.host} />
      <meta property="twitter:url" content={currentUrl} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={image} />
      <meta name="twitter:site" content="@matdawarsh" />
    </Helmet>
  );
}

const mapStateToProps = (state) => ({
  locale: state.locales,
});

export default connect(mapStateToProps)(HelmetMetaData);
