import React from 'react'
import ContentLoader from 'react-content-loader'

const FeaturedSectionsBoxesContentLoader = () => (
    <ContentLoader
        height={250}
        speed={2}
        style={{ width: '30%', marginTop: '10px' }}
        primaryColor="#efefef"
        secondaryColor="#dad9d9"
    >
        <rect x="80" y="20" rx="4" ry="4" width="100" height="10" />
        <rect x="0" y="0" rx="0" ry="0" width="60" height="60" />
        <rect x="280" y="20" rx="4" ry="4" width="100" height="10" />
        <rect x="200" y="0" rx="0" ry="0" width="60" height="60" />


        <rect x="80" y="100" rx="4" ry="4" width="100" height="10" />
        <rect x="0" y="80" rx="0" ry="0" width="60" height="60" />
        <rect x="280" y="100" rx="4" ry="4" width="100" height="10" />
        <rect x="200" y="80" rx="0" ry="0" width="60" height="60" />

        <rect x="80" y="180" rx="4" ry="4" width="100" height="10" />
        <rect x="0" y="160" rx="0" ry="0" width="60" height="60" />
        <rect x="280" y="180" rx="4" ry="4" width="100" height="10" />
        <rect x="200" y="160" rx="0" ry="0" width="60" height="60" /> 

    </ContentLoader>
)

export default FeaturedSectionsBoxesContentLoader