import React, { Fragment } from "react";
import { connect } from "react-redux";

function AboutUs({ locale }) {
  return locale === "ar" ? (
    <div style={{ padding: "2em" }}>
      <div class="separator" style={{ clear: "both", textAlign: "center" }}>
        <img
          border={0}
          data-original-height={1983}
          data-original-width={1847}
          height={200}
          width={200}
          src="https://1.bp.blogspot.com/-hIA-u5-ZID8/X-zYFwhnBCI/AAAAAAAAANo/0HF8dQvcZpc3_vyrgotPvT70o6RV--ljwCLcBGAsYHQ/s320/Artboard%2B1%2Bcopy.jpg"
        />
      </div>
      <br />
      <div>
        <br />
      </div>
      <div style={{ textAlign: "right", direction: "rtl" }}>
        <b>
          <span style={{ color: "#ffa400", fontSize: "large" }}>من نحن :</span>
        </b>
      </div>
      <div>
        <b>
          <span style={{ color: "#ffa400", fontSize: "large" }}>
            <br />
          </span>
        </b>
      </div>
      <div style={{ textAlign: "right", direction: "rtl" }}>
        <span style={{ fontFamily: "Arial, sans-serif" }}>
          نسعى لتقديم&nbsp; افضل خدمة لتعريف الجمهور بأفضل اسعار المنتجات في السوق المصري والعربي
          (&nbsp;
        </span>
        <span style={{ fontFamily: "Arial, sans-serif" }}>
          ومن ذلك الموبايلات والاجهزة المنزلية والإليكترونية
        </span>
        <span style={{ fontFamily: "Arial, sans-serif" }}>&nbsp;وغيرها&nbsp;</span>
        <span style={{ fontFamily: "Arial, sans-serif" }}>&nbsp;)</span>
      </div>
      <div style={{ textAlign: "right", direction: "rtl" }}>
        <span
          dir="RTL"
          lang="AR-EG"
          style={{ fontFamily: "Arial, sans-serif", fontSize: "11.0pt", lineHeight: "115%" }}
        >
          وذلك من خلال عمل مقارنة دورية ويومية وعلى مدار الساعة للأسعار الموجودة على المواقع
          الاليكترونية التي تعتمد البيع اون لاين والمتاجر الأخرى&nbsp;
        </span>
      </div>
    </div>
  ) : (
    <div style={{ padding: "2em" }}>
      <div class="separator" style={{ clear: "both", textAlign: "center" }}>
        <img
          border={0}
          data-original-height={1983}
          data-original-width={1847}
          height={200}
          width={200}
          src="https://1.bp.blogspot.com/-hIA-u5-ZID8/X-zYFwhnBCI/AAAAAAAAANo/0HF8dQvcZpc3_vyrgotPvT70o6RV--ljwCLcBGAsYHQ/s320/Artboard%2B1%2Bcopy.jpg"
        />
      </div>
      <br />
      <div>
        <br />
      </div>
      <div style={{ textAlign: "left", direction: "ltr" }}>
        <b>
          <span style={{ color: "#ffa400", fontSize: "large" }}>About us :</span>
        </b>
      </div>
      <div>
        <b>
          <span style={{ color: "#ffa400", fontSize: "large" }}>
            <br />
          </span>
        </b>
      </div>
      <div style={{ textAlign: "left", direction: "ltr" }}>
        <div style={{ textAlign: "left" }}>
          We strive to provide the best service, to familiarize the public with the best prices for
          products in the Egyptian and Arab market ( mobiles, home and electronic devices, etc. )
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  locale: state.locales,
});

export default connect(mapStateToProps)(AboutUs);
