import React, { Fragment, useEffect } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { compose } from "redux";
import { withTranslation } from "react-i18next";

import { startFetchSections } from "../actions/sections";

function Navigation({ location, t, locale, sections: { sections, isFetching }, startFetchSections }) {
  // IS LOCATION AT AUTH PAGES ?
  if (location.pathname.includes("/user/")) {
    return null;
  }

  useEffect(() => {
    startFetchSections().then((data) => {
      //
    });
  }, []);

  return (
    <div className="navigation">
      <div id="wrapper">
        <nav className={locale === "ar" ? "flex-row-reverse" : ""}>
          <Link to={`/${locale}/`}>
            <i className="fa fa-home"></i>
          </Link>
          <Link to={`/${locale}/categories`}>{t("navigation.all_categories")}</Link>
          {!isFetching && (
            <Fragment>
              {sections.map(
                ({ slug, name_arabic, name_english, categories }, index) =>
                  index < 6 && (
                    <div className="flayers" key={index}>
                      <Link to={`/${locale}/section/${slug}`} className="sections">
                        {locale == "ar" ? name_arabic : name_english}
                      </Link>
                      {categories.length ? (
                        <div className="navigation__section__categories">
                          <div
                            className={`navigation__section__categories__content ${
                              locale === "en" ? "text-left flex-row-reverse" : "text-right"
                            }`}
                          >
                            <div className="d-flex justify-content-around align-items-center flex-2 h-100">
                              {categories.length && (
                                <div className="flayers-image">
                                  <img src={categories[0].image} alt="" />
                                </div>
                              )}
                              {categories.length > 1 && (
                                <div className="flayers-image">
                                  <img src={categories[1].image} alt="" />
                                </div>
                              )}
                            </div>
                            <div className="flex-1">
                              <p>{locale === "ar" ? "الفئات" : "categories"}</p>
                              <ul>
                                {categories.map(({ pk, name_arabic, name_english, slug }) => (
                                  <Link
                                    to={`/${locale}/category/${slug}`}
                                    key={pk}
                                    style={{
                                      padding: 0,
                                      margin: 0,
                                      justifyContent: locale === "ar" ? "flex-end" : "flex-start",
                                    }}
                                  >
                                    <li>{locale == "ar" ? name_arabic : name_english}</li>
                                  </Link>
                                ))}
                              </ul>
                            </div>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  )
              )}

              <div className="flayers">
                <span className="sections">
                  <p
                    style={{
                      fontSize: "30px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    ...
                  </p>
                </span>
                <div className="navigation__section__categories">
                  <div
                    className={`navigation__section__categories__content ${
                      locale === "en" ? "text-left flex-row-reverse" : "text-right"
                    }`}
                  >
                    <div className="flex-1">
                      <p>{locale === "ar" ? "الاقسام" : "sections"}</p>
                      <ul>
                        {sections.map(
                          ({ pk, name_arabic, name_english, slug }, index) =>
                            index >= 6 && (
                              <Link
                                to={`/${locale}/section/${slug}`}
                                key={pk}
                                style={{
                                  padding: 0,
                                  margin: 0,
                                  justifyContent: locale === "ar" ? "flex-end" : "flex-start",
                                }}
                              >
                                <li>{locale == "ar" ? name_arabic : name_english}</li>
                              </Link>
                            )
                        )}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              {/* {sections.length > 6 && (
                <div className="flayers">
                  <a className="sections">
                    <span
                      style={{
                        fontSize: "30px",
                        marginTop: "-15px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      ...
                    </span>
                  </a>
                  <div className="navigation__section__categories">
                    <div
                      className={`navigation__section__categories__content ${
                        locale === "en" ? "text-left flex-row-reverse" : "text-right"
                      }`}
                    >
                      <div className="flex-1">
                        <p>{locale === "ar" ? "الاقسام" : "sections"}</p>
                        <ul>
                          {sections.splice(6).map(({ pk, name_arabic, name_english, slug }) => (
                            <Link
                              to={`/${locale}/section/${slug}`}
                              key={pk}
                              style={{
                                padding: 0,
                                margin: 0,
                                justifyContent: locale === "ar" ? "flex-end" : "flex-start",
                              }}
                            >
                              <li>{locale == "ar" ? name_arabic : name_english}</li>
                            </Link>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              )} */}
            </Fragment>
          )}
          <div className="flayers navigation__storeLink">
              <Link to={`/${locale}/stores`} className={`navigation__store ${locale === 'en' ? 'ml-auto' : ''}`}>
                  {t('navigation.stores')}
                  <i className="fas fa-store-alt"></i>
              </Link>
          </div>
        </nav>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  locale: state.locales,
  sections: state.sections,
});

const mapDispatchToProps = (dispatch) => ({
  startFetchSections: () => dispatch(startFetchSections()),
});

export default compose(withRouter, withTranslation(), connect(mapStateToProps, mapDispatchToProps))(Navigation);
