import React, { useState } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import SearchFilters from "./SearchFilters";
import { resetFilters } from "../actions/search";
import { isPlacesVisited } from "../actions/isPlacesVisited";
import searchIcon from "../images/Search.svg";

function SearchInput({ t, locale, keyword, isVisited, isPlacesVisited, resetFilters }) {
  const [searchTerm, setSearchTerm] = useState("");
  let history = useHistory();

  const submitSearchKeywordHandler = (e) => {
    e.preventDefault();
    if (searchTerm) {
      history.push(`/${locale}/search/${searchTerm}`);
    }
  };

  const reversePlacesState = () => {
    isPlacesVisited(!isVisited);
    // remove body slider, on mobile ( mobile view Mode )
    if (window.innerWidth < "801") {
      isVisited
        ? (document.body.style.overflow = "auto")
        : (document.body.style.overflow = "hidden");
    }
  };

  return (
    <div className="header__main__search">
      <div className={`searchTooltip ${locale === "ar" ? "flex-row-reverse" : undefined}`}>
        <div
          className={`areas ${locale === "ar" ? "flex-row-reverse" : undefined}`}
          onClick={reversePlacesState}
        >
          {keyword && <i className="far fa-times-circle" onClick={() => resetFilters()}></i>}
          {keyword || t("header.all_areas")}
          <i className="fa fa-caret-down"></i>
        </div>
        <div
          className={`mobileFilter ${locale === "ar" ? "flex-row-reverse" : undefined}`}
          onClick={reversePlacesState}
        >
          <i className="fas fa-filter"></i>
        </div>
        <form
          onSubmit={submitSearchKeywordHandler}
          className={`search-form ${locale === "ar" ? "flex-row-reverse" : undefined}`}
        >
          <input
            type="text"
            id="search"
            name="search"
            autoComplete="off"
            autoCorrect="off"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            placeholder={t("header.search_placeholder")}
            className={`${
              locale === "ar" ? "text-right border-left-0" : "text-left border-right-0"
            }`}
          />
          <label name="search" htmlFor="search" onClick={submitSearchKeywordHandler}>
            <img src={searchIcon} alt="search" />
          </label>
        </form>
      </div>
      {isVisited && <SearchFilters />}
    </div>
  );
}

const mapStateToProps = (state) => ({
  locale: state.locales,
  parameter: state.search.parameter,
  keyword: state.search.keyword,
  isVisited: state.isPlacesVisited,
});

const mapDispatchToProps = (dispatch) => ({
  isPlacesVisited: (isVisited) => dispatch(isPlacesVisited(isVisited)),
  resetFilters: () => dispatch(resetFilters()),
});

export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
)(SearchInput);
