import {
    SET_STORE_BRANCHES,
    UPDATE_STORE_BRANCH,
    DELETE_STORE_BRANCH,
    CREATE_STORE_BRANCH,
    UPDATE_BRANCH_PACKAGE
} from '../constants/action-types'

const initialState = []

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_STORE_BRANCHES:
            return action.data
        case CREATE_STORE_BRANCH:
            return [...state, action.data]
        case UPDATE_STORE_BRANCH:
            return state.map(branch => {
                if (branch.pk === action.data.id) {
                    return {
                        ...branch,
                        ...action.data
                    }
                } else {
                    return {
                        ...branch
                    }
                }
            })
        case UPDATE_BRANCH_PACKAGE:
            return state.map(branch => {
                if (branch.pk === action.id) {
                    return {
                        ...branch,
                        package: action.data
                    }
                } else {
                    return {
                        ...branch
                    }
                }
            })
        case DELETE_STORE_BRANCH:
            return state.filter(branch => branch.pk !== action.id)
        default:
            return state
    }
}