import React from "react";
import { Link } from "react-router-dom";
import notFoundImage from "../images/ss@2x.png";

const NotFound = () => (
  <div className="handler">
    <div className="handler__flag">
      <img src={notFoundImage} alt="404" />
    </div>
    <div className="handler__message">
      <p>SORRY, THE PAGE IS NOT FOUND</p>
    </div>
    <div className="handler__backHome">
      <Link to="/">BACK TO HOME</Link>
    </div>
  </div>
);

export default NotFound;
