import API from '../api'
import {
    RESET_FILTERS,
    SET_SEARCH_PARAMETER,
} from '../constants/action-types'

export const setSearchParameter = (parameter, pk, governmentName, neighborhoodName) => ({
    type: SET_SEARCH_PARAMETER,
    parameter, pk, governmentName, neighborhoodName
})

export const resetFilters = () => ({ type: RESET_FILTERS })
