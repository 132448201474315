import {
    RESET_FILTERS,
    SET_SEARCH_PARAMETER,
} from '../constants/action-types'

const defaultMatch = {
    parameter: null,
    keyword: null
}

export default (state = defaultMatch, action) => {
    switch(action.type) {
        case SET_SEARCH_PARAMETER:
            return {
                ...state,
                parameter: `${action.parameter}=${action.pk}`,
                keyword: `${action.governmentName}/${action.neighborhoodName}`
            }
        case RESET_FILTERS:
            return {
                ...state,
                parameter: null,
                keyword: null
            }
        default:
            return state;
    }
}