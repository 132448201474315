import React, { useState } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";

import FormInput from "../genericComponents/FormInput";
import pageImage from "../../images/Group 274@2x.png";

import API from "../../api";

const UserResetPasswordForm = ({ t, locale }) => {
  const [recovered, setRecovered] = useState(false);

  const submitHandler = async (values, bag) => {
    try {
      await API.post(`users/reset-password/`, values);
      return setRecovered(true);
    } catch (error) {
      bag.setErrors(error.response.data);
      bag.setSubmitting(false);
    }
  };

  return (
    <div className="authSystem">
      <div className={`auth ${locale === "ar" ? "flex-row-reverse" : ""} `}>
        <div className="auth__form">
          <div className={`auth__header ${locale === "ar" ? "flex-row-reverse" : "flex-row"}`}>
            <span className="vertical"></span>
            <h2>{t("reset_password.title")}</h2>
            <span className="horizontial"></span>
          </div>
          <div className={`auth__message ${locale === "ar" ? "flex-row-reverse" : "flex-row"}`}>
            <p className={locale === "ar" ? "text-right ml-auto" : "text-left mr-auto"}>
              {recovered ? t("reset_password.invoice") : t("reset_password.message")}
            </p>
          </div>
          <Formik
            initialValues={{
              email: "",
            }}
            onSubmit={submitHandler}
            validationSchema={Yup.object().shape({
              email: Yup.string()
                .email("Email is not valid.")
                .required("Email is required."),
            })}
          >
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              values,
              errors,
              touched,
              isSubmitting,
            }) => (
              <form
                onSubmit={handleSubmit}
                className={locale === "ar" ? "text-right" : "text-left"}
                hidden={recovered}
              >
                <div className="account__form__inner">
                  <div className="single-input">
                    <FormInput
                      autoCapitalize="none"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                      label={t("reset_password.email")}
                      placeholder={t("reset_password.e_placeholder")}
                      type="email"
                      name="email"
                      id="email"
                      className={locale === "ar" ? "text-right" : "text-left"}
                      error={touched.email && errors.email}
                    />
                  </div>
                  <div
                    className={`single-input submitForm d-flex mt-5 align-items-center justify-flex-start ${
                      locale === "ar" ? "flex-row-reverse" : "flex-row"
                    }`}
                  >
                    <button
                      type="submit"
                      className="m-0"
                      title={t("reset_password.recover")}
                      name="reset"
                      id="reset"
                      disabled={isSubmitting}
                    >
                      {t("reset_password.recover")}
                    </button>
                    <p className="ml-3 mr-3 mb-0">
                      {t("reset_password.back_home")}
                      <span className="ml-2 mr-2">
                        <Link to={`/${locale}/user/login`}>{t("reset_password.enter")}</Link>
                      </span>
                    </p>
                  </div>
                </div>
              </form>
            )}
          </Formik>
        </div>
        <div className="auth__image">
          <img src={pageImage} alt="auth Image" />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  locale: state.locales,
});

export default compose(withTranslation(), connect(mapStateToProps))(UserResetPasswordForm);
