import { 
    SET_LOCALE
} from '../constants/action-types';

// LOCALIZATION
// const lang = navigator.language === 'ar'  ? 'ar' : 'en'; // broswer language mode as default
const lang = 'ar'

export default (state = lang, action) => {
    switch(action.type) {
        case SET_LOCALE:
            return action.language;
        default:
            return state;
    }
};