import React, { useState } from 'react'
import ModelFilterFeatures from './ModelFilterFeature'

const ModelFeatures = ({ features }) => {
    const [isFeaturesCollapsed, setFeaturesCollapse] = useState(false)

    return (
        <div className="model__features">
            {Object.keys(features).map((featureName, index) =>
                <div className={`model__features__feature-row__feature-values ${index >= 6 && !isFeaturesCollapsed ? 'hidden' : undefined}`} key={index}>
                    <ModelFilterFeatures key={index} featureName={featureName} value={features[featureName]} />
                </div>
            )}
            <div className="model__features__show-more" hidden={Object.keys(features).length <= 7}>
                <p onClick={() => setFeaturesCollapse(!isFeaturesCollapsed)}>
                    {isFeaturesCollapsed ? 'less features' : 'more features'}
                    {isFeaturesCollapsed ? <i className="fas fa-caret-up"></i> : <i className="fas fa-caret-down"></i>}
                </p>
            </div>
        </div>
    )
}

export default ModelFeatures