import React, { useState } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import Store from "./Store";
import SubModelItems from "./SubModelItems";
import { startRemoveFavorite, startAddFavorite } from "../actions/favorites";
import redHeart from "../images/favorite-red.svg";
import heart from "../images/favorite.svg";
import defaultImage from "../images/default.jpg";

const SubModelList = ({
  t,
  locale,
  isLoggedIn,
  bookmarked,
  favorites,
  images,
  least_price_items,
  name,
  slug,
  pk,
  favorite_id,
  stores_count,
  startRemoveFavorite,
  startAddFavorite,
  size,
}) => {
  const [isBookmarked, setBookmarked] = useState(
    favorites && !!favorites.find(({ sub_model }) => sub_model.pk === pk)
  );
  const [srcImage, setSrcError] = useState(images[0] ? images[0].image : defaultImage);
  let history = useHistory();

  const onBookmarkHandler = () => {
    if (!isLoggedIn) {
      return history.push(`/${locale}/user/login`);
    }

    if (isBookmarked) {
      const favoriteItem = favorites.find(({ sub_model }) => sub_model.pk === pk);
      setBookmarked(false);
      startRemoveFavorite(favoriteItem.pk);
    } else {
      setBookmarked(true);
      startAddFavorite(pk);
    }
  };

  return (
    <div
      className={`subModel subModel--list ${locale === "ar" ? "text-right direction-rtl" : ""} ${
        size ? size : undefined
      }`}
    >
      <div className="subModel__avatar subModel__avatar--border">
        <Link to={`/${locale}/model/${slug}`}>
          <img src={srcImage} alt={name[locale]} onError={() => setSrcError(defaultImage)} />
        </Link>
      </div>
      <div className="subModel__details subModel__details--biggerFont">
        <Link to={`/${locale}/model/${slug}`}>
          <h5
            className={`subModel__details__title ${
              locale === "ar" ? "direction-rtl" : "direction-ltr"
            }`}
            title={name[locale]}
          >
            {name[locale]}
          </h5>
        </Link>
        <span className="subModel__span py-1">
          {locale === "ar"
            ? `متوفر فى ${stores_count} ${stores_count > 2 ? "متاجر" : "متجر"}`
            : `available in ${stores_count} ${stores_count > 2 ? "stores" : "store"} `}
        </span>
        {least_price_items.length >= 1 &&
          window.innerWidth > 800 &&
          least_price_items.map(
            (item, index) =>
              index < 3 && <Store key={index} isDirectTrader={!!item.store_branch} {...item} />
          )}
        {least_price_items.length >= 1 && window.innerWidth < 800 && (
          <div className="subModels__lowestPricesItems mobile">
            <SubModelItems {...least_price_items[0]} />
          </div>
        )}
      </div>
      {history.location.pathname.includes("favorites") ? (
        <button
          className={`delete__button ${locale === "ar" ? "left-5" : "right-5"}`}
          onClick={() => startRemoveFavorite(favorite_id)}
        >
          <i className="far fa-trash-alt"></i> Remove
        </button>
      ) : (
        <button
          aria-label="Add to favorites"
          className={`addToFavorites ${locale === "ar" ? "left-5" : "right-5"}`}
          onClick={onBookmarkHandler}
        >
          {isBookmarked ? (
            <img src={redHeart} alt={"Bookmarked"} />
          ) : (
            <img src={heart} alt={"Bookmark"} />
          )}
        </button>
      )}
    </div>
  );
};

const mapStateToprops = (state) => ({
  locale: state.locales,
  isLoggedIn: state.auth.isLoggedIn,
  favorites: state.favorites.data,
});

const mapDispatchToProps = (dispatch) => ({
  startAddFavorite: (id) => dispatch(startAddFavorite(id)),
  startRemoveFavorite: (id) => dispatch(startRemoveFavorite(id)),
});

export default compose(
  withTranslation(),
  connect(mapStateToprops, mapDispatchToProps)
)(SubModelList);
