import { SWITCH_FILTERS, SWITCH_SORT_BY } from '../constants/action-types'

const defaultState = {
    isFiltersOpened: false,
    isSortByOpened: false
}

export default (state = defaultState, action) => {
    switch(action.type) {
        case SWITCH_FILTERS:
            return { ...state, isFiltersOpened: action.value }
        case SWITCH_SORT_BY:
            return { ...state, isSortByOpened: action.value }
        default: 
            return state
    }
}