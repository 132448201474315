import React from "react";
import { Link } from "react-router-dom";
import { compose } from "redux";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import FeaturedSecionsBoxesContentLoader from "./FeaturedSecionsBoxesContentLoader";

import more from "../images/more.png";

const FeaturedSectionsBoxes = ({ t, locale, sections: { sections } }) => (
  <div
    className={`sectionsBoxes ${
      window.innerWidth < 800 && locale === "ar" ? "justify-content-end" : "justify-content-start"
    }`}
  >
    {sections
      .filter((section) => section.show_in_menu)
      .map(
        (section, index) =>
          index < 6 && (
            <Link
              to={`/${locale}/section/${section.slug}`}
              key={index}
              className={locale === "ar" ? "direction-rtl" : undefined}
            >
              <div className="sectionBox">
                <div className="sectionBox__avatar">
                  <img src={section.image} alt={locale == "ar" ? section.name_arabic : section.name_english} />
                </div>
                <div className="sectionBox__name">
                  <h3>{locale == "ar" ? section.name_arabic : section.name_english}</h3>
                </div>
              </div>
            </Link>
          )
      )}
    {sections.length > 6 && (
      <Link to={`/${locale}/categories/`} className={locale === "ar" ? "direction-rtl" : undefined}>
        <div className="sectionBox">
          <div className="sectionBox__avatar">
            <img src={more} alt={t("navigation.all_sections")} />
          </div>
          <div className="sectionBox__name">
            <h3>{t("navigation.all_sections")}</h3>
          </div>
        </div>
      </Link>
    )}
  </div>
);

const mapStateToProps = (state) => ({
  locale: state.locales,
  sections: state.sections,
});

export default compose(withTranslation(), connect(mapStateToProps))(FeaturedSectionsBoxes);
