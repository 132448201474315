import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";

import FormInput from "./genericComponents/FormInput";
import FormSelectOption from "./genericComponents/FormSelectOption";
import ShadowCard from "./genericComponents/ShadowCard";
import Spinner from "./Spinner";

import { startFetchPlaces } from "../actions/places";
import API from "../api";

function UserProfile({ t }) {
  const [user, setUser] = useState(null);
  const [isloading, setIsLoading] = useState(false);
  const [isEditing, setEditing] = useState(false);
  const logggedUser = useSelector((state) => state.user);
  const places = useSelector((state) => state.places.places);
  const locale = useSelector((state) => state.locales);
  const dispatch = useDispatch();

  const localesBagErrors = {
    full_name: { en: "Enter your name.", ar: "الاسم مطلوب" },
    birth_date: { en: "Enter your birth date.", ar: "تاريخ الميلاد مطلوب" },
    gender: { en: "choose gender.", ar: "اختر الجنس" },
    government: { en: "choose government.", ar: "اختر المحافظة" },
    neighbourhood: { en: "choose district/neighbourhood.", ar: "اختر المنطقة" },
    mobile: { en: "Mobile number is not valid", ar: "رقم الهاتف غير صحيح" }
  };

  useEffect(() => {
    async function fetchUser() {
      try {
        setIsLoading(true);
        const response = await API.get("users/user-profile/");
        setUser(response.data);
        setIsLoading(false);
      } catch (e) {
        // Ignore Error Message
      }
    }
    // fetch current user profile info.
    fetchUser();
    dispatch(startFetchPlaces());
  }, []);

  const submitHandler = async (values, bag) => {
    try {
      await API.put("users/user-profile/", values);
      setEditing(false);
    } catch (error) {
      bag.setErrors(error.response.data);
      bag.setSubmitting(false);
    }
  };

  return (
    <ShadowCard>
      {isloading ? (
        <Spinner />
      ) : (
        <div className={locale === "ar" ? "text-right" : "text-left"}>
          <div className={locale === "ar" ? "row p-2 direction-rtl" : "row p-2 direction-ltr"}>
            <h4>{t("userProfile.title")}</h4>
            {!isEditing && (
              <button type="button" className="btn btn-sm btn-primary mx-2" onClick={() => setEditing(true)}>
                <i className="fas fa-pen fs-10"></i>
              </button>
            )}
          </div>
          <Formik
            initialValues={{
              full_name: user ? user.full_name : "",
              birth_date: user ? user.birth_date : "",
              gender: user ? user.gender : "",
              government: user ? user.government : "",
              neighbourhood: user ? user.neighbourhood : "",
              mobile: user ? user.mobile : ""
            }}
            onSubmit={submitHandler}
            validationSchema={Yup.object().shape({
              full_name: Yup.string().required(localesBagErrors.full_name[locale]),
              birth_date: Yup.string().required(localesBagErrors.birth_date[locale]),
              gender: Yup.string().required(localesBagErrors.gender[locale]),
              government: Yup.string().required(localesBagErrors.government[locale]),
              neighbourhood: Yup.string().required(localesBagErrors.neighbourhood[locale]),
              mobile: Yup.string().matches(new RegExp('[0-9]{10}'), localesBagErrors.mobile[locale])
            })}
          >
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              values,
              errors,
              touched,
              isSubmitting,
            }) => (
              <div
                className="auth__form"
                style={{
                  width: "70%",
                  marginLeft: locale === "ar" ? "auto" : "",
                  marginRight: locale === "en" ? "auto" : "",
                }}
              >
                <form
                  onSubmit={handleSubmit}
                  className={locale === "ar" ? "text-right" : "text-left"}
                >
                  <div className="single-input">
                    <FormInput
                      autoCapitalize="none"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.full_name}
                      label={t("userProfile.full_name")}
                      placeholder={t("userProfile.full_name")}
                      type="text"
                      name="full_name"
                      disabled={!isEditing}
                      id="full_name"
                      className={locale === "ar" ? "text-right" : "text-left"}
                      error={touched.full_name && errors.full_name}
                    />
                  </div>
                  <div className="single-input">
                    <label>{t("userProfile.gender")}</label>
                    <select
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.gender}
                      name="gender"
                      id="gender"
                      disabled={!isEditing}
                      className={locale === "ar" ? "direction-rtl" : "direction-ltr"}
                    >
                      <option value="" label="" />
                      {[{ name: "male", value: 'ذكر' }, { name: "female", value: 'انثي' }].map(({ name, value }, index) => <option key={index} value={name}>{value}</option>)}
                    </select>
                    {touched.gender && errors.gender && (
                      <p className="red-text"> 
                        <i className="fas fa-exclamation-triangle"></i>
                        {errors.gender[0]} 
                      </p>
                    )}
                  </div>
                  <div className="single-input">
                    <FormInput
                      autoCapitalize="none"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.birth_date}
                      label={t("userProfile.birth_date")}
                      type="date"
                      name="birth_date"
                      disabled={!isEditing}
                      id="birth_date"
                      className={locale === "ar" ? "text-right" : "text-left"}
                      error={touched.birth_date && errors.birth_date}
                    />
                  </div>
                  <div className="single-input">
                    <FormInput
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.mobile}
                      label={t("userProfile.mobile")}
                      type="string"
                      name="mobile"
                      disabled={!isEditing}
                      id="mobile"
                      className={locale === "ar" ? "text-right" : "text-left"}
                      error={touched.mobile && errors.mobile}
                    />
                  </div>
                  <div className="single-input">
                    <FormSelectOption
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.government}
                      options={places && places}
                      label={t("userProfile.government")}
                      name="government"
                      disabled={!isEditing}
                      className={locale === "ar" ? "direction-rtl" : "direction-ltr"}
                      error={touched.government && errors.government}
                    />
                  </div>
                  <div className="single-input">
                    <FormSelectOption
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.neighbourhood}
                      options={places && places.find((place) => place.name === values.government)}
                      disabled={!values.government}
                      label={t("userProfile.area")}
                      name="neighbourhood"
                      disabled={!isEditing}
                      className={locale === "ar" ? "direction-rtl" : "direction-ltr"}
                      error={touched.neighbourhood && errors.neighbourhood}
                    />
                  </div>
                  <div
                    className={`single-input d-flex mt-5 align-items-center justify-flex-start ${
                      locale === "ar" ? "flex-row-reverse" : "flex-row"
                    }`}
                  >
                    {isEditing && (
                      <button
                        type="submit"
                        className="btn btn-primary m-0"
                        name="submit"
                        id="submit"
                        disabled={isSubmitting}
                      >
                        {t("userProfile.save")}
                      </button>                      
                    )}
                  </div>
                </form>
              </div>
            )}
          </Formik>
        </div>
      )}
    </ShadowCard>
  );
}

export default withTranslation()(UserProfile);
