import {
    GET_BRANDS
} from '../constants/action-types'

const defaultBrands = []

export default (state = defaultBrands, action) => {
    switch (action.type) {
        case GET_BRANDS:
            return action.brands
        default:
            return state
    }
}