import React from "react";
import { connect } from "react-redux";
import { unionBy } from "lodash";
import HotDealsCarousel from "./HotDealsCarousel";
import Ad from "./Ad";
import HelmetMetaData from "./HelmetMetaData";
import SectionStaticPrices from "./SectionStaticPrices";
import SubModelGrid from "./SubModelGrid";
import Logo from "../images/Group 692.png";

import API from "../api";

class SectionPage extends React.Component {
  state = {
    lookingFor: null,
    activeSection: {},
    brands: [],
    isFetching: false,
  };

  componentDidMount() {
    this.getActiveSection();
  }

  async getActiveSection() {
    try {
      // update state with currectn fetching section
      this.setState(() => ({ isFetching: true, lookingFor: this.props.match.params.slug }));
      const response = await API.get(`/collector/sections/${this.props.match.params.slug}/`);
      this.setState(
        () => ({ activeSection: response.data, isFetching: false }),
        () => {
          this.getBrandsInSection();
        }
      );
    } catch (error) {
      return this.props.history.push("/404");
    }
  }

  async getBrandsInSection() {
    const { locale } = this.props;

    const featuredModels = this.state.activeSection.featured_models;
    let brands = [];
    featuredModels.forEach((item) => {
      const modItem = { slug: item.brand.slug, name: item.brand.name[locale] };
      brands = unionBy(brands, [modItem], "slug");
    });

    this.setState({ brands: brands });
  }

  componentDidUpdate(prevProps) {
    // once user change section, fecth related categories
    if (this.props.match.params.slug !== this.state.lookingFor) {
      this.getActiveSection();
    }
    if (prevProps.locale !== this.props.locale) {
      this.getBrandsInSection();
    }
  }

  render() {
    const { brands } = this.state;
    const { section, featured_models } = this.state.activeSection;

    if (this.state.isFetching) {
      return (
        <div className="floating-spinner">
          <img src={Logo} alt="Matdawarsh" />
        </div>
      );
    }

    return (
      <div className="section">
        <div className="section__ads section__ads--mobileVersion">
          {section && (
            <HelmetMetaData
              title={section.meta_keywords[this.props.locale]}
              description={section.meta_description[this.props.locale]}
            ></HelmetMetaData>
          )}
          <Ad
            position={"section_top_left"}
            slug={this.props.match.params.slug}
            width={300}
            height={200}
          />
          <HotDealsCarousel position={"section_top_slider"} slug={this.props.match.params.slug} />
        </div>
        {section &&
          section.categories.map((category, index) => (
            <React.Fragment key={index}>
              {((index != 0) && (index % 3 == 0)) ?
              <div style={{"margin":"20px 0"}} hidden={window.innerWidth > 800}>
                <Ad position={"mobile_sections_splitter"} width={"100%"} height={"auto"} mobile={true} />
              </div>
              : undefined}
              <SectionStaticPrices key={index} {...category} brands={brands} />
            </React.Fragment>
          ))}

        <div
          className={`d-flex justify-content-between align-items-center ${
            window.innerWidth > 800 ? "py-5" : ""
          }`}
        >
          <Ad
            position={"section_middle_left"}
            slug={this.props.match.params.slug}
            width={"49%"}
            height={100}
          />
          <Ad
            position={"section_middle_right"}
            slug={this.props.match.params.slug}
            width={"49%"}
            height={100}
          />
        </div>

        {featured_models && featured_models.length ? (
          <div className="featuresGroup py-2">
            <div
              className={`featuresGroup__heading flex-row-reverse ${
                this.props.locale === "en" ? "justify-content-end" : ""
              }`}
            >
              <h3>{this.props.locale === "ar" ? " افضل العروض" : "Best Offers"}</h3>
            </div>
            {window.innerWidth > 800 ? (
              <div
                className={`featuresGroup__slider d-flex flex-wrap ${
                  this.props.locale === "ar" ? "justify-content-end" : ""
                }`}
              >
                {featured_models.map((featured_model, index) => (
                  <SubModelGrid key={index} size={"combo"} {...featured_model} />
                ))}
              </div>
            ) : (
              <div className="overflow-wrapper">
                <div className="featuresGroup__slider featuresGroup__slider--mobile d-inline-flex justify-content-center">
                  {featured_models.map((featured_model, index) => (
                    <SubModelGrid key={index} size={"combo"} {...featured_model} />
                  ))}
                </div>
              </div>
            )}
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  locale: state.locales,
});

export default connect(mapStateToProps)(SectionPage);
