import {
  ADD_FAVORITE,
  REMOVE_FAVORITE,
  SET_FAVORITES,
  CLEAR_FAVORITES,
  FAVORITES_REQUEST,
  FAVORITES_SUCCESS,
} from "../constants/action-types";
// SAVED MODELS

const favsDefault = {
  pages_count: 0,
  count: 0,
  data: null,
  isFetching: false,
};

export default (state = favsDefault, action) => {
  switch (action.type) {
    case ADD_FAVORITE:
      return {
        ...state,
        data: [...state.data, action.sub_model],
      };
    case REMOVE_FAVORITE:
      return {
        ...state,
        data: state.data.filter(({ pk }) => pk !== action.id),
      };
    case SET_FAVORITES:
      return {
        ...state,
        data: action.favorites.results,
        pages_count: action.favorites.pages_count,
        count: action.favorites.count,
      };
    case CLEAR_FAVORITES:
      return favsDefault;
    case FAVORITES_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case FAVORITES_SUCCESS:
      return {
        ...state,
        isFetching: false,
      };
    default:
      return state;
  }
};
