import React, { useEffect } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import { Route, Redirect, useLocation } from "react-router-dom";

import { changeLocale } from "../actions/locales";
import { startFetchStoreProfile } from '../actions/profile';

const branchProfileExcludedRoutes = [
  "/account/user-information",
  "/account/store",
  "/account/store/branches",
  "/account/store/subscription-and-payments",
];

export const PrivateRoutes = ({
  locale,
  i18n,
  changeLocale,
  startFetchStoreProfile,
  isAuthenticated,
  userAccountType,
  component: Component,
  ...rest
}) => {
  const localeCodes = ["en", "ar"];
  let [firstSegmentOfLocation] = document.location.pathname.replace(/^\//, "").split("/");
  let location = useLocation();

  useEffect(() => {
    startFetchStoreProfile();
  }, [])

  if (!location.pathname.includes("/account")) {
    if (localeCodes.includes(firstSegmentOfLocation)) {
      if (locale !== firstSegmentOfLocation) {
        changeLocale(firstSegmentOfLocation);
        i18n.changeLanguage(firstSegmentOfLocation);
      }
    } else {
      document.location.pathname = `/${locale}${document.location.pathname}`;
    }
  }

  return (
    <Route
      {...rest}
      render={(props) => {
        if (!isAuthenticated) {
          return (
            <Redirect to={{ pathname: `/${locale}/user/login`, state: { from: props.location } }} />
          );
        }

        if (
          userAccountType === "normal" &&
          !props.location.pathname.includes("/favorites") &&
          !props.location.pathname.includes("/profile")
        ) {
          return <Redirect to={{ pathname: "/404" }} />;
        }

        if (
          userAccountType === "branch" &&
          branchProfileExcludedRoutes.includes(props.location.pathname)
        ) {
          return <Redirect to={{ pathname: "/404" }} />;
        }

        // authorized, return component
        return <Component {...props} />;
      }}
    />
  );
};

const mapStateToProps = (state) => ({
  userAccountType: state.auth.user.account_type,
  isAuthenticated: state.auth.isLoggedIn,
  locale: state.locales,
});

const mapDispatchToProps = (dispatch) => ({
  changeLocale: (language) => dispatch(changeLocale(language)),
  startFetchStoreProfile: () => dispatch(startFetchStoreProfile()),
});

export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
)(PrivateRoutes);
