// AUTH
export const AUTH_LOGIN = "AUTH_LOGIN";
export const AUTH_LOGOUT = "AUTH_LOGOUT";
export const USER_LOGIN_REQUEST = "USER_LOGIN_REQUEST";
export const USER_LOGIN_RESPONSE = "USER_LOGIN_RESPONSE";

// LAYOUT [ KANBAN - LIST ]
export const SET_LAYOUT_VIEW = "SET_LAYOUT_VIEW";

// FAVORITES
export const ADD_FAVORITE = "ADD_FAVORITE";
export const REMOVE_FAVORITE = "REMOVE_FAVORITE";
export const SET_FAVORITES = "SET_FAVORITES";
export const CLEAR_FAVORITES = "CLEAR_FAVORITES";
export const FAVORITES_REQUEST = "FAVORITES_REQUEST";
export const FAVORITES_SUCCESS = "FAVORITES_SUCCESS";

// SORT_BY
export const SORT_BY = "SORT_BY";

// CATEGORY
export const SET_CATEGORY_RESULTS = "SET_CATEGORY_RESULTS";
export const FILTER_CATEGORY_RESULTS = "FILTER_CATEGORY_RESULTS";
export const REQUEST_CATEGORY_RESULTS = "REQUEST_CATEGORY_RESULTS";
export const RECIEVE_CATEGORY_RESULTS = "RECIEVE_CATEGORY_RESULTS";
export const SET_CATEGORY_FILTERS = "SET_CATEGORY_FILTERS";
export const REQUEST_CATEGORY_FILTERS = "REQUEST_CATEGORY_FILTERS";
export const RECIEVE_CATEGORY_FILTERS = "RECIEVE_CATEGORY_FILTERS";
export const UPDATE_CATEGORY_FILTERS = "UPDATE_CATEGORY_FILTERS";
export const SET_CURRENT_PAGE = "SET_CURRENT_PAGE";
export const REQUEST_SPECIFIC_CATEGORY = "REQUEST_SPECIFIC_CATEGORY";
export const RECIEVE_SPECIFIC_CATEGORY = "RECIEVE_SPECIFIC_CATEGORY";
export const SET_SPECIFIC_CATEGORY = "SET_SPECIFIC_CATEGORY";

// FILTERS
export const SET_FILTER = "SET_FILTER";
export const RESET_ALL_FILTERS = "RESET_ALL_FILTERS";

// SEARCH
export const RESET_FILTERS = "RESET_FILTERS";
export const SET_SEARCH_PARAMETER = "SET_SEARCH_PARAMETER";

// CUSTOMS
export const IS_PLACES_VISITED = "IS_PLACES_VISITED";
export const UPDATE_RANGE_SLIDER = "UPDATE_RANGE_SLIDER";

// MODEL
export const GET_MODEL = "GET_MODEL";
export const REQUEST_MODEL = "REQUEST_MODEL";
export const RECIEVE_MODEL = "RECIEVE_MODEL";

// SECTIONS
export const SET_SECTIONS = "SET_SECTIONS";
export const FETCH_SECTIONS_REQUEST = "FETCH_SECTIONS_REQUEST";
export const FETCH_SECTIONS_FAILURE = "FETCH_SECTIONS_FAILURE";
export const FETCH_SECTIONS_SUCCESS = "FETCH_SECTIONS_SUCCESS";

// PLACES
export const SET_PLACES = "SET_PLACES";
export const FETCH_PLACES_REQUEST = "FETCH_PLACES_REQUEST";
export const FETCH_PLACES_SUCCESS = "FETCH_PLACES_SUCCESS";
export const FETCH_PLACES_FAILURE = "FETCH_PLACES_FAILURE";

// BRANDS
export const SET_BRAND_CATEGORIES = "SET_BRAND_CATEGORIES";
export const REQUEST_BRANDS = "REQUEST_BRANDS";
export const RECIEVE_BRANDS = "RECIEVE_BRANDS";

// CATEGORIES
export const GET_CATEGORIES = "GET_CATEGORIES";
export const REQUEST_CATEGORIES = "REQUEST_CATEGORIES";
export const REQUEST_CATEGORIES_SUCCESS = "REQUEST_CATEGORIES_SUCCESS";
export const REQUEST_CATEGORIES_FAILURE = "REQUEST_CATEGORIES_FAILURE";

// NOTIFICATIONS
export const IS_NOTIFICATIONS_OPENED = "IS_NOTIFICATIONS_OPENED";
export const FETCH_USER_NOTIFICATIONS_REQUEST = "FETCH_USER_NOTIFICATIONS_REQUEST";
export const FETCH_USER_NOTIFICATIONS_SUCCESS = "FETCH_USER_NOTIFICATIONS_SUCCESS";
export const FETCH_USER_NOTIFICATIONS_FAILURE = "FETCH_USER_NOTIFICATIONS_FAILURE";
export const SET_USER_NOTIFICATIONS = "SET_USER_NOTIFICATIONS";
export const CLEAR_USER_NOTIFICATIONS = "CLEAR_USER_NOTIFICATIONS";
export const DELETE_USER_NOTIFICATION = "DELETE_USER_NOTIFICATION";

// LOCALIZATION
export const SET_LOCALE = "SET_LOCALE";

// MODELS FILTERS
export const PUSH_FILTER = "PUSH_FILTER";
export const POP_FILTER = "POP_FILTER";
export const REMOVE_FILTER = "REMOVE_FILTER";

// CATEGORY ACTIONS
export const SWITCH_FILTERS = "SWITCH_FILTERS";
export const SWITCH_SORT_BY = "SWITCH_SORT_BY";

// STORES
export const SET_STORES = "SET_STORES";

// PROFILE
export const SET_STORE_PROFILE = "SET_STORE_PROFILE";

// STORE BRANCHES
export const SET_STORE_BRANCHES = "SET_STORE_BRANCHES";
export const UPDATE_STORE_BRANCH = "UPDATE_STORE_BRANCH";
export const DELETE_STORE_BRANCH = "DELETE_STORE_BRANCH";
export const CREATE_STORE_BRANCH = "CREATE_STORE_BRANCH";
export const UPDATE_BRANCH_PACKAGE = "UPDATE_BRANCH_PACKAGE";

// PACKAGES
export const SET_PACKAGES = "SET_PACKAGES";

// BRANCH ITEMS
export const SET_BRANCH_ITEMS = "SET_BRANCH_ITEMS";
export const ADD_BRANCH_ITEM = "ADD_BRANCH_ITEM";
export const UPDATE_BRANCH_ITEM = "UPDATE_BRANCH_ITEM";
export const DELETE_BRANCH_ITEM = "DELETE_BRANCH_ITEM";

// BRANDS
export const GET_BRANDS = "GET_BRANDS";
