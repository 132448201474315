import React from "react";
import { connect } from "react-redux";
import GovernmentName from "./GovernmentName";
import BranchCard from "./BranchCard";
import Pagination from "./Pagination";
import PlacesFrame from "./PlacesFrame";
import Spinner from "./Spinner";
import NoResults from "./NoResults";
import Ad from "./Ad";
import { Redirect } from 'react-router-dom';
import HelmetMetaData from "./HelmetMetaData";

import { startFetchStores, startFetchStoresInsideNeighbourhoood } from "../actions/stores";
import { startFetchPlaces } from "../actions/places";

export class StoresPage extends React.Component {
  state = {
    isFetching: false,
    isFetchStores: false,
    neighbourhoods: null,
    pickedGovernment: "",
    pickedNeighbourhood: "",
    storesInsideNeighbourhood: null,
  };

  async componentDidUpdate(prevProps, prevState) {
    //get city and state from url
    const city = this.props.match.params.city;
    const state = this.props.match.params.state;
    //listen on url change or if places prop state changed
    if(prevProps.places.places != this.props.places.places || prevProps.match.url != this.props.match.url){
      //if url dosen't provide city or state and url dosen't changed just get all cities and states data
      if(!city && !state && prevProps.match.url != this.props.match.url){
        await this.filterNeighbourhoods(null);
      }
      //if link provides only city
      if(city && !state){
        //fetch city stores
        await this.filterNeighbourhoods(city);
      }
      //if link provides city and state
      if(city && state){
        //get all neighbourhoods for current city
        const locale = this.props.match.params.locale;
        const cityData = this.props.places.places.find(({ name, name_en }) => name == city || name_en == city);
        const neighbourhoods = cityData.neighbourhoods;
        //select our city
        const selectedGovernment = neighbourhoods[0].pk;
        //get state id from all states
        const neighbourhoodId = neighbourhoods.find(({ name,name_en }) => name == state || name_en == state).pk;
        //lets change our states with new data
        this.setState({
          neighbourhoods,
          pickedGovernment: locale == "ar" ? cityData.name : cityData.name_en,
          pickedNeighbourhood: neighbourhoodId,
          //storesInsideNeighbourhood: null,
        });

        let filter = location.search.replace("?", "");
        //get page number
        const splitQuery = filter.split("&");
        const pageIndex = splitQuery.findIndex(data => data.includes("page"));
        let page = 1;
        if(pageIndex != -1){
          const pageNoIndex = splitQuery[pageIndex].split("=");
          page = Number(pageNoIndex[1]);
        }

        //get state stores and render it
        await this.handlePickNeighbourhood({target:{value:neighbourhoodId}},page);
      }
      //hide loading page
      this.setState({ isFetching: false });
    }
  }

  async componentDidMount() {
    //get locale from url
    const locale = this.props.match.params.locale;
    
    //start page loading and we will stop it in component update listener
    this.setState({ isFetching: true });
    this.setState({ locale });
    try {
      await this.props.startFetchStores("?place_type=governments");
      await this.props.startFetchPlaces();

      //this.setState({ isFetching: false });
    } catch (err) {
      // Ignore Error Message
    }
  }

  fetchStores = async (params) => {
    this.setState({ isFetchStores: true });
    try {
      await this.props.startFetchStores(params);
      this.setState({ isFetchStores: false });
    } catch (err) {
      // Ignore Error Message
    }
  };

  filterChanged = (event) => {
    if (event.target.value) {
      const neighbourhoods = this.props.places.places.find(({ name,name_en }) => name == event.target.value || name_en == event.target.value).neighbourhoods;
      const selectedGovernment = neighbourhoods[0].pk;
      this.setState({
        neighbourhoods,
        pickedGovernment: event.target.value,
        pickedNeighbourhood: "",
        storesInsideNeighbourhood: null,
      });
      this.props.history.push("/" + this.state.locale + "/stores/" + event.target.value);
    }else{
      this.setState({
        neighbourhoods: null,
      });
      this.props.history.push("/" + this.state.locale + "/stores");
    }
  }

  filterNeighbourhoods = async (cityName) => {
    if (cityName) {
      const locale = this.props.match.params.locale;
      const cityData = this.props.places.places.find(({ name, name_en }) => name == cityName || name_en == cityName);
      const neighbourhoods = cityData.neighbourhoods;
      const selectedGovernment = neighbourhoods[0].pk;
      this.setState({
        neighbourhoods,
        pickedGovernment: locale == "ar" ? cityData.name : cityData.name_en,
        pickedNeighbourhood: "",
        storesInsideNeighbourhood: null,
      });

      await this.fetchStores(`?place_type=neighbourhoods&government=${selectedGovernment}`);
    } else {
      this.setState({ pickedGovernment: "", pickedNeighbourhood: "", storesInsideNeighbourhood: null })
      this.props.startFetchStores("?place_type=governments");
      this.props.startFetchPlaces();
    }
  };

  neighbourhoodChanged = (event) => {
    this.setState({ isFetchStores: true, pickedNeighbourhood: event.target.value });
    const neighbourhoodData = this.state.neighbourhoods.find(({ pk }) => pk == event.target.value);
    const neighbourhood = this.props.match.params.locale == "ar" || (neighbourhoodData.hasOwnProperty('name_en') && !neighbourhoodData.name_en) ? neighbourhoodData.name : neighbourhoodData.name_en;
    this.props.history.push("/" + this.state.locale + "/stores/" + this.state.pickedGovernment + "/" + neighbourhood);
  }

  handlePickNeighbourhood = async (event,page = 1) => {
    this.setState({ isFetchStores: true, pickedNeighbourhood: event.target.value });

    try {
      const response = await this.props.startFetchStoresInsideNeighbourhoood(page, event.target.value);
      this.setState({ isFetchStores: false, storesInsideNeighbourhood: response.data });
    } catch (err) {
      this.setState({ isFetchStores: false, storesInsideNeighbourhood: {
        "links": {
            "next": null,
            "previous": null
        },
        "count": 0,
        "pages_count": 0,
        "results": []
       } 
      });
    }
  };

  handlePageClick = async (data) => {
    const page_number = data + 1;
    try {
      const response = await this.props.startFetchStoresInsideNeighbourhoood(
        page_number,
        this.state.pickedNeighbourhood
      );
      this.setState({ storesInsideNeighbourhood: response.data });
      //lets redirect user to new page
      const neighbourhoodData = this.state.neighbourhoods.find(({ pk }) => pk == this.state.pickedNeighbourhood);
      const neighbourhood = this.props.match.params.locale == "ar" || (neighbourhoodData.hasOwnProperty('name_en') && !neighbourhoodData.name_en) ? neighbourhoodData.name : neighbourhoodData.name_en;
      this.props.history.push("/" + this.state.locale + "/stores/" + this.state.pickedGovernment + "/" + neighbourhood + "?page=" + page_number);
    } catch (err) {
      // Ignore Error Message
    }
  };

  render() {
    const {
      stores,
      places: { places },
    } = this.props;

    //get city and state from url
    const city = this.props.match.params.city;
    const state = this.props.match.params.state;

    //set metadata title and description
    let title = this.state.locale == "ar" ? "جميع المتاجر" : "Stores";
    if(city && !state) title = this.state.locale == "ar" ? (" متاجر " + city) : (city + " stores ");
    if(city && state) title = this.state.locale == "ar" ? (" متاجر " + state) : (state + " stores ");
    let description = this.state.locale == "ar" ? "المتاجر" : "Stores";
    if(city && !state) description = this.state.locale == "ar" ? (" متاجر " + city) : (city + " stores ");
    if(city && state) description = this.state.locale == "ar" ? (" متاجر " + state) : (state + " stores ");

    if (this.state.isFetching) return <Spinner />;

    if (this.state.storesInsideNeighbourhood) {
      return (
        <React.Fragment>
          <HelmetMetaData
            title={title}
            description={description}
          ></HelmetMetaData>
          <div className="mobile-width mw-80 pt-5">
            <div className="storesHeader d-flex justify-content-between align-items-center pb-4">
              <Ad position={"store_top_rectange"} width={"85%"} height={100} />
              <div className="storesIcon">
                <span>{this.props.match.params.locale == "ar" ? "المتاجر" : "Stores"}</span>
                <i className="fas fa-store-alt"></i>
              </div>
            </div>

            <PlacesFrame>
              <div className="row flex-column align-items-end my-2">
                <span>{this.props.match.params.locale == "ar" ? "المنطقة" : "Region"}</span>
                <select
                  disabled={!this.state.neighbourhoods}
                  style={{
                    width: "300px",
                    color: "#707070",
                    borderRadius: "4px",
                    direction: "rtl",
                    border: "1px solid #ccc",
                    background: "#fff",
                  }}
                  value={this.state.pickedNeighbourhood}
                  onChange={this.neighbourhoodChanged}
                >
                  <option value="" disabled>
                    {this.props.match.params.locale == "ar" ? "اختر المنطقة" : "Choose the region"}
                  </option>
                  {this.state.neighbourhoods &&
                    this.state.neighbourhoods
                      .filter(({ parameter }) => parameter !== "government")
                      .map(({ pk, name, name_en }, index) => (
                        <option key={index} value={pk} label={this.props.match.params.locale == "ar" || !name_en ? name : name_en}>
                          {this.props.match.params.locale == "ar" || !name_en ? name : name_en}
                        </option>
                      ))}
                </select>
              </div>

              <div className="row flex-column align-items-end">
                <span>{this.props.match.params.locale == "ar" ? "المحافظة" : "Governorate"}</span>
                <select
                  style={{
                    width: "300px",
                    color: "#707070",
                    borderRadius: "4px",
                    direction: "rtl",
                    border: "1px solid #ccc",
                    background: "#fff",
                  }}
                  value={this.state.pickedGovernment}
                  onChange={this.filterChanged}
                >
                  <option value="">{this.props.match.params.locale == "ar" ? "كل المحافظات" : "All governorates"}</option>
                  {places &&
                    places.map(({ name, name_en }, index) => (
                      <option key={index} value={this.props.match.params.locale == "ar" || !name_en ? name : name_en} label={this.props.match.params.locale == "ar" || !name_en ? name : name_en}>
                        {this.props.match.params.locale == "ar" || !name_en ? name : name_en}
                      </option>
                    ))}
                </select>
              </div>
            </PlacesFrame>

            <div style={{"margin":"20px 0"}} hidden={window.innerWidth > 800}>
              <Ad position={"mobile_stores_top"} width={"100%"} height={"auto"} mobile={true} />
            </div>

            <div className="d-flex justify-content-between">
              <Ad position={"store_left_rectange"} width={180} height={500} />
              <div className="branches" style={{ width: "75%", marginTop: -20 }}>
                {this.state.isFetchStores ? (
                  <Spinner />
                ) : this.state.storesInsideNeighbourhood.results.length ? (
                  <React.Fragment>
                    {this.state.storesInsideNeighbourhood.results.map((store) => {
                      store['locale'] = this.state.locale;
                      return (
                        <BranchCard key={store.pk} {...store} comesFromSection="stores_page" />
                      )
                      })}
                    {this.state.storesInsideNeighbourhood.count > 1 && (
                      <Pagination
                        pages_count={this.state.storesInsideNeighbourhood.pages_count}
                        count={this.state.storesInsideNeighbourhood.count}
                        handlePageClick={this.handlePageClick}
                      />
                    )}
                  </React.Fragment>
                ) : (
                  this.props.match.params.locale == "ar" ?
                    <NoResults message="لا توجد متاجر لهذه المنطقة" featuredModels={[]} />
                  : <NoResults message="Cannot find stores in this region" featuredModels={[]} />
                )}
              </div>
            </div>
          </div>
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        <HelmetMetaData
          title={title}
          description={description}
        ></HelmetMetaData>
        <div className="mobile-width mw-80 pt-5">
          <div className="storesHeader d-flex justify-content-between align-items-center pb-4">
            <Ad position={"store_top_rectange"} width={"85%"} height={100} />
            <div className="storesIcon">
              <span>{this.props.match.params.locale == "ar" ? "المتاجر" : "Stores"}</span>
              <i className="fas fa-store-alt"></i>
            </div>
          </div>

          <PlacesFrame>
            <div className="row flex-column align-items-end my-2">
              <span>{this.props.match.params.locale == "ar" ? "المنطقة" : "Region"}</span>
              <select
                disabled={!this.state.neighbourhoods}
                style={{
                  width: "300px",
                  color: "#707070",
                  borderRadius: "4px",
                  direction: "rtl",
                  border: "1px solid #ccc",
                  background: "#fff",
                }}
                value={this.state.pickedNeighbourhood}
                onChange={this.neighbourhoodChanged}
              >
                <option value="" disabled>
                  {this.props.match.params.locale == "ar" ? "اختر المنطقة" : "Choose the region"}
                </option>
                {this.state.neighbourhoods &&
                  this.state.neighbourhoods
                    .filter(({ parameter }) => parameter !== "government")
                    .map(({ pk, name, name_en }, index) => (
                      <option key={index} value={pk} label={(this.props.match.params.locale == "ar" || !name_en) ? name : name_en}>
                        {(this.props.match.params.locale == "ar" || !name_en) ? name : name_en}
                      </option>
                    ))}
              </select>
            </div>

            <div className="row flex-column align-items-end">
              <span>{this.props.match.params.locale == "ar" ? "المحافظة" : "Governorate"}</span>
              <select
                style={{
                  width: "300px",
                  color: "#707070",
                  borderRadius: "4px",
                  direction: "rtl",
                  border: "1px solid #ccc",
                  background: "#fff",
                }}
                value={this.state.pickedGovernment}
                onChange={this.filterChanged}
              >
                <option value="">{this.props.match.params.locale == "ar" ? "كل المحافظات" : "All governorates"}</option>
                {places &&
                  places.map(({ name,name_en }, index) => (
                    <option key={index} value={this.props.match.params.locale == "ar" || !name_en ? name : name_en} label={this.props.match.params.locale == "ar" || !name_en ? name : name_en}>
                      {this.props.match.params.locale == "ar" || !name_en ? name : name_en}
                    </option>
                  ))}
              </select>
            </div>
          </PlacesFrame>

          <div style={{"margin":"20px 0"}} hidden={window.innerWidth > 800}>
              <Ad position={"mobile_stores_top"} width={"100%"} height={"auto"} mobile={true} />
          </div>

          <div className="d-flex justify-content-between">
            <Ad position={"store_left_rectange"} width={150} height={500} />
            <div className="branches" style={{ width: "75%", marginTop: -20 }}>
              {this.state.isFetchStores ? (
                <Spinner />
              ) : stores && stores.length ? (
                stores.map(({ place, branches }, index) => (
                  <React.Fragment key={index}>
                    {((index != 0) && (index % 3 == 0)) ?
                    <div style={{"margin":"20px 0"}} hidden={window.innerWidth > 800}>
                        <Ad position={"mobile_stores_splitter"} width={"100%"} height={"auto"} mobile={true} />
                    </div>
                    : undefined}
                    {/*<GovernmentName name={ this.props.match.params.locale == "ar" || !place.name_en ? place.name : place.name_en } />*/}
                    <div className="gov-name text-right">
                        <h3 className="gov-name__header" style={{cursor:"pointer"}} onClick={() => {
                          !this.state.pickedGovernment ? 
                          this.filterChanged({ target: { value: this.props.match.params.locale == "ar" || !place.name_en ? place.name : place.name_en } })
                          : this.neighbourhoodChanged({ target: { value: place.pk }})
                        }}>
                            {this.props.match.params.locale == "ar" || !place.name_en ? ("متاجر " + place.name) : (place.name_en + " stores")}
                        </h3>
                    </div>
                    {branches.slice(0, 2).map((branch) => {
                      branch['locale'] = this.state.locale;
                      return (
                        <BranchCard key={branch.pk} {...branch} comesFromSection="stores_page" />
                      )
                      })}
                    {!this.state.pickedGovernment && branches.length > 2 && (
                        <div className="row justify-content-end">
                          <button 
                            className="btn btn-outline-info border-0 mx-3" 
                            onClick={() => this.filterChanged({ target: { value: this.props.match.params.locale == "ar" || !place.name_en ? place.name : place.name_en } })}>
                              <i className="fas fa-caret-down mx-2"></i>
                              {this.props.match.params.locale == "ar" ? "باقي المتاجر" : "More stores"}
                          </button>
                        </div>
                    )}
                  </React.Fragment>
                ))
              ) : (
                this.props.match.params.locale == "ar" ?
                    <NoResults message="لا توجد متاجر لهذه المحافظة" featuredModels={[]} />
                  : <NoResults message="Cannot find stores in this governorate" featuredModels={[]} />
              )}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  stores: state.stores,
  places: state.places,
});

const mapDispatchToProps = (dispatch) => ({
  startFetchStores: (params) => dispatch(startFetchStores(params)),
  startFetchPlaces: () => dispatch(startFetchPlaces()),
  startFetchStoresInsideNeighbourhoood: (page, value) =>
    dispatch(startFetchStoresInsideNeighbourhoood(page, value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(StoresPage);
