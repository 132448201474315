import { SET_PACKAGES } from '../constants/action-types'

const initPackages = null;

export default (state = initPackages, action) => {
    switch(action.type) {
        case SET_PACKAGES:
            return action.packages
        default:
            return state
    }
}