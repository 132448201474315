import "regenerator-runtime/runtime";
import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import AppRouters from "./routes/AppRouters";
import configureStore from "./store/configureStore";
// import MessengerCustomerChat from 'react-messenger-customer-chat';
import "./i18n";
import "normalize.css/normalize.css";
import "@fortawesome/fontawesome-free/css/all.css";
import "bootstrap";
import "bootstrap/dist/css/bootstrap.css";
import "./styles/style.scss";

const { store, persistor } = configureStore();

ReactDOM.render(
  <Provider store={store}>
    {/* <MessengerCustomerChat
      pageId="<PAGE_ID>" // matdawarsh.Retailers
      appId="<APP_ID>"
    /> */}
    <PersistGate loading={null} persistor={persistor}>
      <Suspense fallback={<div className="floating-spinner" />}>
        <AppRouters />
      </Suspense>
    </PersistGate>
  </Provider>,
  document.getElementById("root")
);
