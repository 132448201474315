import React, { useState } from 'react'
import BranchPackages from '../BranchPackages'

const Branch = (props) => {
    const [packages, showPackages] = useState(false)
    return (
        <React.Fragment>
            <div className="branch-list-item">
                <div className="branch-list-item__options">
                    <button type="button" className="black" onClick={() => showPackages(!packages)}>
                        {packages ?
                            <i className="fas fa-arrow-up"></i> :
                            <i className="fas fa-arrow-down"></i>
                        }
                    </button>
                </div>
                <p>{props.package ? props.package.package_name : 'بدون اشتراك'}</p>
                <p>{props.name_arabic}</p>
                <p>{props.government_name}</p>
                <p>{props.neighbourhood.name}</p>
            </div>
            {packages && <BranchPackages branch_id={props.pk} active={props.active} package={props.package} />}
        </React.Fragment>
    )
}

export default Branch