import React, { Fragment, useEffect } from "react";
import { connect } from "react-redux";

//import { startFetchSections } from "../actions/sections";

import HotDealsCarousel from "./HotDealsCarousel";
import FeaturedSectionsBoxes from "./FeaturedSectionsBoxes";
import SubModelsSlider from "./SubModelsSlider";
import HelmetMetaData from "./HelmetMetaData";
import Logo from "../images/Group 692.png";

const fetchUrl = new URL(window.location.href);

const HomePage = ({ locale, isFetching }) => {
  /*useEffect(() => {
    startFetchSections();
  }, []);*/

  let title = "";
  let description = "";

  if(fetchUrl.host.includes("sa.")){
    title =
      locale === "ar"
        ? "متدورش السعودية - افضل خدمة لتعريف الجمهور بأفضل الاسعار في السوق السعودى"
        : "Matdawarsh Saudi - familiarize the public with the best prices for products in Saudi.";
    description =
      locale === "ar"
        ? "نسعى لتقديم افضل خدمة لتعريف الجمهور بأفضل اسعار المنتجات في السوق السعودى ومن ذلك   الهواتف والاجهزة المنزلية والإليكترونية وغيرها , وعمل مقارنة للأسعار بشكل دورى وعلى مدار الساعة"
        : "We strive to provide the best service to familiarize the public with the best prices for products in the Saudi and Arab market (mobiles, home and electronic devices, etc";
  }else if(fetchUrl.host.includes("kw.")){
    title =
      locale === "ar"
      ? "متدورش الكويت - افضل خدمة لتعريف الجمهور بأفضل الاسعار في السوق الكويتى"
      : "Matdawarsh Kuwait - familiarize the public with the best prices for products in Kuwait.";
    description =
      locale === "ar"
        ? "نسعى لتقديم افضل خدمة لتعريف الجمهور بأفضل اسعار المنتجات في السوق الكويتى ومن ذلك   الهواتف والاجهزة المنزلية والإليكترونية وغيرها , وعمل مقارنة للأسعار بشكل دورى "
        : "We strive to provide the best service to familiarize the public with the best prices for products in the Kuwait and Arab market (mobiles, home and electronic devices, etc";
  }
  else if(fetchUrl.host.includes("ae.")){
    title =
      locale === "ar"
      ? "متدورش الامارات - افضل خدمة لتعريف الجمهور بأفضل الاسعار في السوق الاماراتى"
      : "Matdawarsh Emirates - familiarize the public with the best prices for products in Emirates.";
    description =
      locale === "ar"
        ? "نسعى لتقديم افضل خدمة لتعريف الجمهور بأفضل اسعار المنتجات في السوق الإماراتى ومن ذلك   الهواتف والاجهزة المنزلية والإليكترونية وغيرها , وعمل مقارنة للأسعار بشكل دورى وعلى مدار الساعة "
        : "We strive to provide the best service to familiarize the public with the best prices for products in the Emirates and Arab market (mobiles, home and electronic devices, etc";
  }else{
    title =
      locale === "ar"
        ? "متدورش - افضل خدمة لتعريف الجمهور بأفضل الاسعار في السوق"
        : "Matdawarsh - familiarize the public with the best prices for products.";
    description =
      locale === "ar"
        ? "نسعى لتقديم افضل خدمة لتعريف الجمهور بأفضل اسعار المنتجات في السوق المصري والعربي ومن ذلك الموبايلات والاجهزة المنزلية والإليكترونية وغيرها"
        : "We strive to provide the best service to familiarize the public with the best prices for products in the Egyptian and Arab market (mobiles, home and electronic devices, etc";
  }
  
  if (isFetching) {
    return (
      <div className="floating-spinner">
        <img src={Logo} alt="Matdawarsh" />
      </div>
    );
  }

  return (
    <Fragment>
      <HelmetMetaData title={title} description={description}></HelmetMetaData>
      <div className="section__ads">
        <FeaturedSectionsBoxes />
        {window.innerWidth > 980 && <HotDealsCarousel position={"home_top_slider"} />}
      </div>
      <SubModelsSlider />
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  isFetching: state.sections.isFetching,
  locale: state.locales,
});

/*const mapDispatchToProps = (dispatch) => ({
  startFetchSections: () => dispatch(startFetchSections()),
});*/

export default connect(mapStateToProps)(HomePage);
