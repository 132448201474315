import React from 'react'
import { connect } from 'react-redux'

import Spinner from '../components/Spinner'
import Switches from '../components/genericComponents/Switches'
import ShadowCard from '../components/genericComponents/ShadowCard'
import NoResults from '../components/NoResults'
import Branch from './genericComponents/Branch'
import SupportInfo from './genericComponents/SupportInfo'
import NotFound from './NotFound'

import { startFetchStoreBranches } from '../actions/branches'
import { startGetPackages } from '../actions/packages'

export class AccountPaymentsPage extends React.Component {

  state = { 
    isFetching: false, 
    error: null 
  }

  async componentDidMount() {
      this.setState({ isFetching: true })
      try {
          await this.props.startFetchStoreBranches(JSON.parse(localStorage.getItem('user')).user.store_slug)
          await this.props.startGetPackages()
          this.setState({ isFetching: false })
      } catch (err) {
          this.setState({ isFetching: false, error: err })
      }
  }

  render() {

    if (this.props.profile && !this.props.profile.vendor_name) {
      return <NotFound />;
    }

    return (
      <React.Fragment>
        <SupportInfo />
        <ShadowCard>
          <Switches />
          {this.state.isFetching ? <Spinner /> :
            this.props.branches.length ? (
              <div className="table-container">
                <div className="p-3 table-contents">
                  {this.props.branches.map(branch =>
                    <Branch key={branch.pk} {...branch} />
                  )}
                </div>
              </div>
            ) : (
              <NoResults message="لا توجد فروع لهذا المتجر" />
            )
          }
        </ShadowCard>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  branches: state.branches,
  profile: state.profile
})

const mapDispatchToProps = (dispatch) => ({
  startFetchStoreBranches: (slug) => dispatch(startFetchStoreBranches(slug)),
  startGetPackages: () => dispatch(startGetPackages())
})

export default connect(mapStateToProps, mapDispatchToProps)(AccountPaymentsPage)