import API from "../api";
import {
  SET_CATEGORY_RESULTS,
  REQUEST_CATEGORY_RESULTS,
  RECIEVE_CATEGORY_RESULTS,
  SET_CATEGORY_FILTERS,
  REQUEST_CATEGORY_FILTERS,
  RECIEVE_CATEGORY_FILTERS,
  UPDATE_RANGE_SLIDER,
  UPDATE_CATEGORY_FILTERS,
  SET_CURRENT_PAGE,
  REQUEST_SPECIFIC_CATEGORY,
  RECIEVE_SPECIFIC_CATEGORY,
  SET_SPECIFIC_CATEGORY,
} from "../constants/action-types";
import { history } from "../routes/AppRouters";

// CATEGORY MODELS
// EX: MOBILE CATEGORY

export const setCategoryResults = (category) => ({
  type: SET_CATEGORY_RESULTS,
  category,
});

export const setCurrentPage = (page) => ({
  type: SET_CURRENT_PAGE,
  page,
});

export const setCategoryData = (category) => ({
  type: SET_SPECIFIC_CATEGORY,
  category,
});

export const getSpecificCategory = (slug) => (
  dispatch
) => {
  dispatch({ type: REQUEST_SPECIFIC_CATEGORY });

  return API.get(
    `basic-data/category/${slug}/`
  )
    .then((response) => {
      dispatch(setCategoryData(response.data));
      dispatch({ type: RECIEVE_SPECIFIC_CATEGORY });
      return response.data;
    })
    .catch((error) => {
      if (error.response.status === 404) {
        history.push("/404");
      }
    });
};

export const startSetCategoryResults = (keyword = "", pageNumber = 1, filter = "") => (
  dispatch
) => {
  dispatch({ type: REQUEST_CATEGORY_RESULTS });

  return API.get(
    `basic-data/category-results/${keyword}/?${filter ? `${filter}` : ""}`
  )
    .then((response) => {
      dispatch(setCategoryResults(response.data));
      dispatch({ type: RECIEVE_CATEGORY_RESULTS });
      return response.data;
    })
    .catch((error) => {
      if (error.response.status === 404) {
        history.push("/404");
      }
    });
};

export const setCategoryFilters = (filters) => ({
  type: SET_CATEGORY_FILTERS,
  filters,
});

export const startSetCategoryFilters = (keyword = "") => (dispatch) => {
  dispatch({ type: REQUEST_CATEGORY_FILTERS });

  return API.get(`basic-data/category-filters/${keyword}/`)
    .then((response) => {
      dispatch(setCategoryFilters(response.data));
      dispatch({ type: RECIEVE_CATEGORY_FILTERS });
      return response.data;
    })
    .catch((error) => {
      if (error.response.status === 404) {
        history.push("/404");
      }
    });
};

export const setSearchResults = (category) => ({
  type: SET_CATEGORY_RESULTS,
  category,
});

export const startSetSearchResults = (
  keyword = "",
  pageNumber = 1,
  filter = "",
  parameter = ""
) => (dispatch) => {
  dispatch({ type: REQUEST_CATEGORY_RESULTS });

  return API.get(
    `collector/search-results/${keyword}/?${filter ? `${filter}` : ""}${
      parameter ? `&${parameter}` : ""
    }`
  )
    .then((response) => {
      dispatch(setSearchResults(response.data));
      dispatch({ type: RECIEVE_CATEGORY_RESULTS });
    })
    .catch((error) => {
      if (error.response.status === 404) {
        history.push("/404");
      }
    });
};

export const setSearchFilters = (filters) => ({
  type: SET_CATEGORY_FILTERS,
  filters,
});

export const startSetSearchFilters = (keyword = "", filter = "", parameter) => (dispatch) => {
  dispatch({ type: REQUEST_CATEGORY_FILTERS });

  return API.get(
    `collector/search-filters/${keyword}/${filter ? `?${filter}` : ""}${
      parameter ? `?${parameter}` : ""
    }`
  )
    .then((response) => {
      dispatch(setSearchFilters(response.data));
      dispatch({ type: RECIEVE_CATEGORY_FILTERS });
    })
    .catch((error) => {
      if (error.response.status === 404) {
        history.push("/404");
      }
    });
};

export const updateRangeSlider = (range = []) => ({
  type: UPDATE_RANGE_SLIDER,
  range,
});

export const updateCategoryFilters = (title, tag, checked) => ({
  type: UPDATE_CATEGORY_FILTERS,
  title,
  tag,
  checked,
});
