import React from "react";
import { Link } from "react-router-dom";

const CategoryMenuItem = ({ category, locale, switchMenuState }) => {
  return (
    <li>
      <Link
        to={`/${locale}/category/${category.slug}`}
        onClick={() => {
          switchMenuState(false);
          document.body.style.overflow = "auto";
        }}
      >
        <span style={{ paddingLeft: 24, paddingRight: 24 }}>{locale == "ar" ? category.name_arabic : category.name_english}</span>
      </Link>
    </li>
  );
};

export default CategoryMenuItem;
