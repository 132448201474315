import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import ToolsBox from "./ToolsBox";
import CategoryMobileFilters from "./CategoryMobileFilters";
import SortByMobile from "./SortByMobile";
import SubModelGrid from "./SubModelGrid";
import SubModelList from "./SubModelList";
import Pagination from "./Pagination";
import {
  startSetCategoryResults,
  startSetSearchResults,
  startSetSearchFilters,
  setCurrentPage,
} from "../actions/category";
import filterModels from "../selectors/filterModels";
import Ad from "./Ad";

import Logo from "../images/Group 692.png";

function MainCategoryResults({
  locale,
  models,
  slug,
  page,
  parameter,
  results: { pages_count, count, currentPage, isFetching },
  layoutView,
  startSetSearchResults,
  startSetCategoryResults,
  setCurrentPage,
}) {
  let location = useLocation();
  const history = useHistory();

  const handlePageChange = (nextPage) => {
    const pageNumber = nextPage + 1;
    let filter = JSON.parse(localStorage.getItem("f_q"));
    setCurrentPage(pageNumber);
    //fix deleted f_q from localStorage if we are coming from section
    if(!filter) {
      localStorage.setItem("f_q",JSON.stringify("page=1"));
      filter = "page=1";
    }

    const regex = /page=[0-9]+/;
    let clonedFilter = filter.replace(regex, "page=" + pageNumber);

    if (location.pathname.includes("search")) {
      //startSetSearchResults(slug, pageNumber, clonedFilter, parameter);
      //startSetSearchFilters(slug, parameter); 
      history.push(`/${locale}/search/${slug}?${clonedFilter ? `${clonedFilter}` : ""}`);
    } else {
      //startSetCategoryResults(slug, pageNumber, clonedFilter);
      history.push(`/${locale}/category/${slug}?${clonedFilter ? `${clonedFilter}` : ""}`);
    }
  };

  return (
    <div className="category-results__main-details">
      {isFetching ? (
        <div className="spinner">
          <img src={Logo} alt="Matdawarsh" />
        </div>
      ) : (
        <Fragment>
          <div className="d-flex justify-content-between align-items-center">
            <Ad position={"category_middle_left"} slug={slug} width={"49%"} height={100} />
            <Ad position={"category_middle_right"} slug={slug} width={"49%"} height={100} />
          </div>
          <ToolsBox models_length={models && models.length} searchTerm={slug} locale={locale} />
          <CategoryMobileFilters
            models_length={models && models.length}
            searchTerm={slug}
            locale={locale}
          />
          <SortByMobile />
          <div className="category-results__main-details__subModels">
            {layoutView === "list"
              ? models &&
                models.map((model, index) => (
                  <React.Fragment key={index}>
                    {((index != 0) && (index % 3 == 0)) ?
                    <div style={{"margin":"20px 0", "width": "100%"}} hidden={window.innerWidth > 800}>
                      <Ad position={"mobile_products_splitter"} width={"100%"} height={"auto"} mobile={true} />
                    </div>
                    : undefined}
                    <SubModelList key={model.pk} size="combo" {...model} />
                  </React.Fragment>
                ))
              : models &&
                models.map((model, index) => (
                  <React.Fragment key={index}>
                    {((index != 0) && (index % 6 == 0)) ?
                    <div style={{"margin":"20px 0", "width": "100%"}} hidden={window.innerWidth > 800}>
                      <Ad position={"mobile_products_splitter"} width={"100%"} height={"auto"} mobile={true} />
                    </div>
                    : undefined}
                    <SubModelGrid key={model.pk} size="combo" {...model} />
                  </React.Fragment>
                ))}
          </div>
          {pages_count > 1 && (
            <Pagination
              pages_count={pages_count}
              count={count}
              pageNumber={Number(page) - 1}
              handlePageClick={(data) => handlePageChange(data)}
            />
          )}
        </Fragment>
      )}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    parameter: state.search.parameter,
    layoutView: state.layoutView,
    results: state.category.results,
    locale: state.locales,
    models: filterModels(state.category.results.data, state.sortBy),
  };
};

const mapDispatchToProps = (dispatch) => ({
  startSetSearchResults: (keyword, pageNumber, filter, parameter) =>
    dispatch(startSetSearchResults(keyword, pageNumber, filter, parameter)),
  startSetCategoryResults: (keyword, pageNumber, filter) =>
    dispatch(startSetCategoryResults(keyword, pageNumber, filter)),
  startSetSearchFilters: (keyword, parameter) =>
    dispatch(startSetSearchFilters(keyword, parameter)),
  setCurrentPage: (page) => dispatch(setCurrentPage(page)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MainCategoryResults);
