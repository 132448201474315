import React from "react";
import WidgetListItem from "./WidgetListItem";
import WidgetListItemRange from "./WidgetListItemRange";
import plus from "../images/Group 693.png";
import minus from "../images/Group 309.png";

export class Widget extends React.Component {
  state = { collapsed: false, readMore: false };

  render() {
    const { name, values } = this.props;
    const { collapsed, readMore } = this.state;
    return (
      <div className="widget text-left">
        <div className="widget__header">
          <h5 className="widget__header__title">{name}</h5>
          <span
            className="widget__header__switcher"
            onClick={() => this.setState(() => ({ collapsed: !collapsed }))}
          >
            {collapsed ? <img src={plus} alt="collapsed" /> : <img src={minus} alt="collapse" />}
          </span>
        </div>

        {values.length > 0 && (
          <div className={`collapser ${collapsed ? "hidden" : undefined}`}>
            <div className="widget__body">
              <ul className="widget__items">
                {values.map((value, index) =>
                  name === "prices" ? (
                    <WidgetListItemRange key={index} value={value} title={name} />
                  ) : readMore ? (
                    <WidgetListItem
                      key={index}
                      value={value}
                      title={name}
                      onUpdateCategoryFilters={this.props.onUpdateCategoryFilters}
                    />
                  ) : (
                    index < 4 && (
                      <WidgetListItem
                        key={index}
                        value={value}
                        title={name}
                        onUpdateCategoryFilters={this.props.onUpdateCategoryFilters}
                      />
                    )
                  )
                )}
                <p
                  hidden={values.length <= 4}
                  className="readMore"
                  onClick={() => this.setState({ readMore: !readMore })}
                >
                  {readMore ? "See Less" : "See All"}
                </p>
              </ul>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default Widget;
