import { SWITCH_FILTERS, SWITCH_SORT_BY } from '../constants/action-types'

export const switchFilters = (value) => ({
    type: SWITCH_FILTERS,
    value
})

export const switchSortBy = (value) => ({
    type: SWITCH_SORT_BY,
    value
}) 