//load flags
const egFlag = require("./images/flags/eg.svg");
const saFlag = require("./images/flags/sa.svg");
const kwFlag = require("./images/flags/kw.svg");
const aeFlag = require("./images/flags/ae.svg");

export const countriesData = [
    {
      name_en: "KSA",
      name_ar: "المملكة العربية السعودية",
      image: saFlag,
      url: "https://sa.matdawarsh.com/",
    },
    {
      name_en: "Egypt",
      name_ar: "جمهورية مصر العربية",
      image: egFlag,
      url: "https://eg.matdawarsh.com/",
    },
    {
      name_en: "UAE",
      name_ar: "الإمارات العربية المتحدة",
      image: aeFlag,
      url: "https://ae.matdawarsh.com/",
    },
    {
      name_en: "Kuwait",
      name_ar: "دولة الكويت",
      image: kwFlag,
      url: "https://kw.matdawarsh.com/",
    },
];