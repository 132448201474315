import { SET_STORES } from '../constants/action-types'

const defaultStoreState = null

export default (state = defaultStoreState, action) => {
    switch (action.type) {
        case SET_STORES:
            return action.stores
        default:
            return state;
    }
}