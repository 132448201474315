import React from 'react'
import ContentLoader from 'react-content-loader'

const SubModelsContentLoader = () => (
    <ContentLoader
        height={1300}
        width={1360}
        speed={2}
        primaryColor="#efefef"
        secondaryColor="#dad9d9"
    >
        <rect x="30" y="20" rx="8" ry="8" width="200" height="200" />
        <rect x="30" y="250" rx="0" ry="0" width="200" height="18" />
        <rect x="30" y="275" rx="0" ry="0" width="120" height="20" />

        <rect x="250" y="20" rx="8" ry="8" width="200" height="200" />
        <rect x="250" y="250" rx="0" ry="0" width="200" height="18" />
        <rect x="250" y="275" rx="0" ry="0" width="120" height="20" />

        <rect x="470" y="20" rx="8" ry="8" width="200" height="200" />
        <rect x="470" y="250" rx="0" ry="0" width="200" height="18" />
        <rect x="470" y="275" rx="0" ry="0" width="120" height="20" />

        <rect x="690" y="20" rx="8" ry="8" width="200" height="200" />
        <rect x="690" y="250" rx="0" ry="0" width="200" height="18" />
        <rect x="690" y="275" rx="0" ry="0" width="120" height="20" />

        <rect x="910" y="20" rx="8" ry="8" width="200" height="200" />
        <rect x="910" y="250" rx="0" ry="0" width="200" height="18" />
        <rect x="910" y="275" rx="0" ry="0" width="120" height="20" />

        <rect x="1130" y="20" rx="8" ry="8" width="200" height="200" />
        <rect x="1130" y="250" rx="0" ry="0" width="200" height="18" />
        <rect x="1130" y="275" rx="0" ry="0" width="120" height="20" />

        <rect x="30" y="340" rx="8" ry="8" width="200" height="200" />
        <rect x="30" y="570" rx="0" ry="0" width="200" height="18" />
        <rect x="30" y="595" rx="0" ry="0" width="120" height="20" />

        <rect x="250" y="340" rx="8" ry="8" width="200" height="200" />
        <rect x="250" y="570" rx="0" ry="0" width="200" height="18" />
        <rect x="250" y="595" rx="0" ry="0" width="120" height="20" />

        <rect x="470" y="340" rx="8" ry="8" width="200" height="200" />
        <rect x="470" y="570" rx="0" ry="0" width="200" height="18" />
        <rect x="470" y="595" rx="0" ry="0" width="120" height="20" />

        <rect x="690" y="340" rx="8" ry="8" width="200" height="200" />
        <rect x="690" y="570" rx="0" ry="0" width="200" height="18" />
        <rect x="690" y="595" rx="0" ry="0" width="120" height="20" />

        <rect x="910" y="340" rx="8" ry="8" width="200" height="200" />
        <rect x="910" y="570" rx="0" ry="0" width="200" height="18" />
        <rect x="910" y="595" rx="0" ry="0" width="120" height="20" />

        <rect x="1130" y="340" rx="8" ry="8" width="200" height="200" />
        <rect x="1130" y="570" rx="0" ry="0" width="200" height="18" />
        <rect x="1130" y="595" rx="0" ry="0" width="120" height="20" />

        <rect x="30" y="660" rx="8" ry="8" width="200" height="200" />
        <rect x="30" y="890" rx="0" ry="0" width="200" height="18" />
        <rect x="30" y="915" rx="0" ry="0" width="120" height="20" />

        <rect x="250" y="660" rx="8" ry="8" width="200" height="200" />
        <rect x="250" y="890" rx="0" ry="0" width="200" height="18" />
        <rect x="250" y="915" rx="0" ry="0" width="120" height="20" />

        <rect x="470" y="660" rx="8" ry="8" width="200" height="200" />
        <rect x="470" y="890" rx="0" ry="0" width="200" height="18" />
        <rect x="470" y="915" rx="0" ry="0" width="120" height="20" />

        <rect x="690" y="660" rx="8" ry="8" width="200" height="200" />
        <rect x="690" y="890" rx="0" ry="0" width="200" height="18" />
        <rect x="690" y="915" rx="0" ry="0" width="120" height="20" />

        <rect x="910" y="660" rx="8" ry="8" width="200" height="200" />
        <rect x="910" y="890" rx="0" ry="0" width="200" height="18" />
        <rect x="910" y="915" rx="0" ry="0" width="120" height="20" />

        <rect x="1130" y="660" rx="8" ry="8" width="200" height="200" />
        <rect x="1130" y="890" rx="0" ry="0" width="200" height="18" />
        <rect x="1130" y="915" rx="0" ry="0" width="120" height="20" />

        <rect x="30" y="980" rx="8" ry="8" width="200" height="200" />
        <rect x="30" y="1210" rx="0" ry="0" width="200" height="18" />
        <rect x="30" y="1235" rx="0" ry="0" width="120" height="20" />

        <rect x="250" y="980" rx="8" ry="8" width="200" height="200" />
        <rect x="250" y="1210" rx="0" ry="0" width="200" height="18" />
        <rect x="250" y="1235" rx="0" ry="0" width="120" height="20" />

        <rect x="470" y="980" rx="8" ry="8" width="200" height="200" />
        <rect x="470" y="1210" rx="0" ry="0" width="200" height="18" />
        <rect x="470" y="1235" rx="0" ry="0" width="120" height="20" />

        <rect x="690" y="980" rx="8" ry="8" width="200" height="200" />
        <rect x="690" y="1210" rx="0" ry="0" width="200" height="18" />
        <rect x="690" y="1235" rx="0" ry="0" width="120" height="20" />

        <rect x="910" y="980" rx="8" ry="8" width="200" height="200" />
        <rect x="910" y="1210" rx="0" ry="0" width="200" height="18" />
        <rect x="910" y="1235" rx="0" ry="0" width="120" height="20" />

        <rect x="1130" y="980" rx="8" ry="8" width="200" height="200" />
        <rect x="1130" y="1210" rx="0" ry="0" width="200" height="18" />
        <rect x="1130" y="1235" rx="0" ry="0" width="120" height="20" />

    </ContentLoader>
)

export default SubModelsContentLoader