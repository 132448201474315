import React, { useState } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";

import { startUserLogin } from "../../actions/auth";

import FormInput from "../genericComponents/FormInput";
import FetchError from "../FetchError";
import pageImage from "../../images/Group -1@2x.png";

const UserLoginForm = ({ t, locale, startUserLogin }) => {
  const [error, setError] = useState(null);

  const submitHandler = async (values, bag) => {
    try {
      setError(null);
      await startUserLogin(values);
    } catch (error) {
      setError(error.message);
      bag.setSubmitting(false);
    }
  };

  return (
    <div className="authSystem">
      <div className={`auth ${locale === "ar" ? "flex-row-reverse" : ""} `}>
        <div className="auth__form">
          <div className={`auth__header ${locale === "ar" ? "flex-row-reverse" : "flex-row"}`}>
            <span className="vertical" />
            <h2>{t("login.title")}</h2>
            <span className="horizontial" />
          </div>
          <Formik
            initialValues={{
              email: "",
              password: "",
            }}
            onSubmit={submitHandler}
            validationSchema={Yup.object().shape({
              email: Yup.string()
                .email("Email is not valid.")
                .required("Email is required."),
              password: Yup.string()
                .min(8, "Password must be at least 8 characters.")
                .required("Password is required."),
            })}
          >
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              values,
              errors,
              touched,
              isSubmitting,
            }) => (
              <form
                onSubmit={handleSubmit}
                className={locale === "ar" ? "text-right" : "text-left"}
              >
                <FetchError message={error} />
                <div className="account__form__inner">
                  <div className="single-input">
                    <FormInput
                      autoCapitalize="none"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                      label={t("login.email")}
                      placeholder={t("login.e_placeholder")}
                      type="email"
                      name="email"
                      id="email"
                      className={locale === "ar" ? "text-right" : "text-left"}
                      error={touched.email && errors.email}
                    />
                  </div>
                  <div className="single-input">
                    <FormInput
                      autoCapitalize="none"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.password}
                      label={t("login.password")}
                      placeholder={t("login.p_placeholder")}
                      type="password"
                      name="password"
                      id="password"
                      className={locale === "ar" ? "text-right" : "text-left"}
                      error={touched.password && errors.password}
                    />
                  </div>
                  <div
                    className={`user__actions__forgot mt-2 d-flex w-100 ${
                      locale === "ar" ? "justify-content-start" : "justify-content-end"
                    }`}
                  >
                    <Link to={`/${locale}/user/account-security/reset-password`}>
                      {t("login.forgot_password")}
                    </Link>
                  </div>
                  <div
                    className={`single-input submitForm d-flex mt-5 align-items-center justify-flex-start ${
                      locale === "ar" ? "flex-row-reverse" : "flex-row"
                    }`}
                  >
                    <button
                      type="submit"
                      className="m-0"
                      title={t("login.login")}
                      name="login"
                      id="login"
                      disabled={isSubmitting}
                    >
                      {t("login.login")}
                    </button>
                    <p className="ml-3 mr-3 mb-0">
                      {t("login.new_user")}
                      <span className="ml-2 mr-2">
                        <Link to={`/${locale}/user/signup`}>{t("login.join")}</Link>
                      </span>
                    </p>
                  </div>
                </div>
              </form>
            )}
          </Formik>
        </div>
        <div className="auth__image">
          <img src={pageImage} alt="auth Image" />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  locale: state.locales,
});

const mapDispatchToProps = (dispatch) => ({
  startUserLogin: (values) => dispatch(startUserLogin(values)),
});

export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
)(UserLoginForm);
