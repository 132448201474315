import { 
    IS_NOTIFICATIONS_OPENED,
    FETCH_USER_NOTIFICATIONS_REQUEST,
    FETCH_USER_NOTIFICATIONS_SUCCESS,
    FETCH_USER_NOTIFICATIONS_FAILURE,
    SET_USER_NOTIFICATIONS,
    CLEAR_USER_NOTIFICATIONS,
    DELETE_USER_NOTIFICATION
} from '../constants/action-types';


const notificationsState = {
    isOpened: false,
    data: [],
    isFetching: false,
    error: null
}

export default (state = notificationsState, action) => {
    switch(action.type) {
        case IS_NOTIFICATIONS_OPENED: 
            return {
                ...state,
                isOpened: !state.isOpened
            }
        case SET_USER_NOTIFICATIONS: 
            return {
                ...state,
                data: action.notifications.results,
                isFetching: false
            }
        case FETCH_USER_NOTIFICATIONS_REQUEST:
            return {
                ...state,
                isFetching: true
            }
        case FETCH_USER_NOTIFICATIONS_SUCCESS:
        case FETCH_USER_NOTIFICATIONS_FAILURE:
            return {
                ...state,
                isFetching: false,
                error: action.message ? action.message : null
            }
        case CLEAR_USER_NOTIFICATIONS: 
            return notificationsState;
        case DELETE_USER_NOTIFICATION: 
            return {
                ...state,
                data: state.data.filter(({ id }) => id !== action.id )
            }
        default: 
            return state;
    }
}