import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import SliderContent from "./SliderContent";
import SubModelsContentLoader from "./SubModelsContentLoader";
import Ad from "./Ad";

function SubModelsSlider({ sections }) {
  return (
    <Fragment>
      <div style={{"margin":"20px 0"}} hidden={window.innerWidth > 800}>
        <Ad position={"mobile_home_after_sections"} width={"100%"} height={"auto"} mobile={true} />
      </div>
      {sections.length &&
        [sections[0]].map((section, index) => <SliderContent key={index} {...section} />)}
      <div
        className={`d-flex justify-content-between align-items-center ${
          window.innerWidth > 800 ? "py-5" : ""
        }`}
      >
        <Ad position={"home_middle_left"} width={"49%"} height={100} />
        <Ad position={"home_middle_right"} width={"49%"} height={100} />
      </div>
      {sections
        .filter((section, index) => index != 0 && index != sections.length - 1)
        .map((section, mapIndex) => (
          <React.Fragment key={mapIndex}>
            {((mapIndex != 0) && ((mapIndex + 1) % 3 == 0)) ?
            <div style={{"margin":"20px 0"}} hidden={window.innerWidth > 800}>
              <Ad position={"mobile_home_splitter"} width={"100%"} height={"auto"} mobile={true} />
            </div>
            : undefined}
            <SliderContent key={mapIndex} {...section} />
          </React.Fragment>
        ))}
      <div
        className={`d-flex justify-content-between align-items-center ${
          window.innerWidth > 800 ? "py-5" : ""
        }`}
      >
        <Ad position={"home_bottom_left"} width={"49%"} height={100} />
        <Ad position={"home_bottom_right"} width={"49%"} height={100} />
      </div>
      {sections.length &&
        [sections[sections.length - 1]].map((section, index) => (
          <SliderContent key={index} {...section} />
        ))}
    </Fragment>
  );
}

const mapStateToProps = (state) => ({
  sections: state.sections.sections,
});

export default connect(mapStateToProps)(SubModelsSlider);
