import { has } from "lodash";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import CategoryMenuItem from "./category-menu-item";

const SectionMenuItem = ({ section, locale, switchMenuState }) => {
  const [isExpanded, toggleExpanded] = useState(false);
  const categories = section.categories;
  const hasChildren = section.categories.length === 0;

  const handleSectionClick = (e) => {
    e.preventDefault();
    toggleExpanded(!isExpanded);
  };

  if (hasChildren) {
    return (
      <li>
        <Link
          to={`/${locale}/section/${section.slug}`}
          onClick={() => {
            switchMenuState(false);
            document.body.style.overflow = "auto";
          }}
        >
          <span style={{ fontWeight: 900 }}>{locale == "ar" ? section.name_arabic : section.name_english}</span>
          {locale === "ar" ? (
            <i className="fas fa-chevron-left"></i>
          ) : (
            <i className="fas fa-chevron-right"></i>
          )}
        </Link>
      </li>
    );
  } else {
    return (
      <li key={section.slug}>
        <a href="#" onClick={handleSectionClick}>
          <span style={{ fontWeight: 900 }}>{locale == "ar" ? section.name_arabic : section.name_english}</span>
          {locale === "ar" ? (
            <i className={`fas fa-chevron-${!isExpanded ? "left" : "down"}`}></i>
          ) : (
            <i className={`fas fa-chevron-${!isExpanded ? "right" : "down"}`}></i>
          )}
        </a>
        {isExpanded && (
          <ul
            className={`mobile-menu__sections__list navbar-nav mr-auto ${
              locale === "ar" ? "direction-rtl" : undefined
            }`}
          >
            {categories.map((category) => (
              <CategoryMenuItem
                key={category.slug}
                category={category}
                locale={locale}
                switchMenuState={switchMenuState}
              />
            ))}
          </ul>
        )}
      </li>
    );
  }
};

export default SectionMenuItem;
