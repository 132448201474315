import {
    SET_STORE_BRANCHES,
    UPDATE_STORE_BRANCH,
    DELETE_STORE_BRANCH,
    CREATE_STORE_BRANCH,
    UPDATE_BRANCH_PACKAGE
} from '../constants/action-types'
import API from '../api'
import { history } from '../routes/AppRouters'

const setStoreBranches = data => ({
    type: SET_STORE_BRANCHES,
    data
})

export const startFetchStoreBranches = (store_slug) => async dispatch => {
    try {
        const response = await API.get(`store/store-branches/${store_slug}/`);
        dispatch(setStoreBranches(response.data))
    } catch (err) {
        const errorMessage = err.message
        throw new Error(errorMessage)
    }
}

const createStoreBranch = data => ({
    type: CREATE_STORE_BRANCH,
    data
})

export const startCreateStoreBranch = (values) => async dispatch => {
    try {
        const response = await API.post('store/store-branch/create/', values)
        dispatch(createStoreBranch(response.data))
    } catch (err) {
        const errorMessage = err.response.data.message.includes('branch name is already existed') && 'اسم الفرع موجود قبل كدا'
        throw new Error(errorMessage)
    }
}

export const startGetStoreBranch = (id) => async dispatch => {
    try {
        const response = await API.get(`store/store-branch/retrieve/?store_branch_pk=${id}`)
        return response
    } catch (err) {
        if(err.response.status === 404) {
            history.push('/404')
        }
    }
}

export const startGetStorePublicBranch = (slug) => async dispatch => {
    try {
        const response = await API.get(`store/public-store-branch/${slug}/`)
        return response
    } catch (err) {
        if(err.response.status === 404) {
            history.push('/404')
        }
    }
}


const updateStoreBranch = data => ({
    type: UPDATE_STORE_BRANCH,
    data
})

export const startUpdateStoreBranch = (id, values) => async dispatch => {
    try {
        await API.put(`store/store-branch/update/${id}/`, values)
        dispatch(updateStoreBranch({ ...values, id }))
    } catch (err) {
        const errorMessage = err.message
        throw new Error(errorMessage)
    }
}

const removeStoreBranch = id => ({
    type: DELETE_STORE_BRANCH,
    id
})

export const startRemoveStoreBranch = (id) => async dispatch => {
    try {
        await API.delete(`store/store-branch/destroy/${id}/`)
        dispatch(removeStoreBranch(id))
    } catch (err) {
        const errorMessage = err.message
        throw new Error(errorMessage)
    }
}

const updateBranchPackage = (id, data) => ({
    type: UPDATE_BRANCH_PACKAGE,
    id, 
    data
})

export const startCreateBranchPackage = (branch_id, data) => async dispatch => {
    try {
        const response = await API.post(`store/store-branch-payment-package-create/?store_branch_pk=${branch_id}`, { ...data })
        dispatch(updateBranchPackage(branch_id, response.data))
    } catch (err) {
        throw new Error(err)
    }
}

export const startFetchWorkDomains = () => async dispatch => {
    try {
        const response = await API.get('/basic-data/work-domains/')
        return response
    } catch (err) {
        throw new Error(err)
    }
}