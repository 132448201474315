import React from 'react'
import { connect } from 'react-redux'
import { sortBy } from '../actions/sort'
import { switchSortBy } from '../actions/category_actions'

const SortByMobile = ({ sort, sortBy, switchSortBy, isSortByOpened }) => {

    const sortByHandler = (text) => {
        sortBy(text);
        switchSortBy(false);
    }

    return (
        <div className="sortBy" hidden={!isSortByOpened}>
            <div className="sortBy__header">
                <span>Sort By</span>
                <button name="close" onClick={sortByHandler}><i className="fas fa-times"></i></button>
            </div>
            <div className="sortBy__options">
                <div className="">
                    <p>Popular</p>
                    <span onClick={() => sortByHandler('')} className={sort === '' ? 'active' : undefined}>
                        {sort === '' && <i className="fas fa-check"></i>}
                    </span>
                </div>
                <div className="">
                    <p>Price: High to Low</p>
                    <span onClick={() => sortByHandler('price: high to low')} className={sort === 'price: high to low' ? 'active' : undefined}>
                        {sort === 'price: high to low' && <i className="fas fa-check"></i>}
                    </span>
                </div>
                <div className="">
                    <p>Price: Low to High</p>
                    <span onClick={() => sortByHandler('price: low to high')} className={sort === 'price: low to high' ? 'active' : undefined}>
                        {sort === 'price: low to high' && <i className="fas fa-check"></i>}
                    </span>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    sort: state.sortBy,
    isSortByOpened: state.category_actions.isSortByOpened
})

const mapDispatchToProps = (dispatch) => ({
    sortBy: (text) => dispatch(sortBy(text)),
    switchSortBy: (value) => dispatch(switchSortBy(value))
})

export default connect(mapStateToProps, mapDispatchToProps)(SortByMobile)
