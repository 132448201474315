import React from 'react'
import { Link } from 'react-router-dom' 

const EmptySetHandler = () => (
    <div className="handler">
        <div className="handler__flag handler__flag--emptySet">
            <i className="far fa-heart"></i>
        </div>
        <div className="handler__message handler__message--emptySet text-center">
            <p>Okay, your favorites are empty</p>
            <span>If you like something, you can add to your favorites.</span>
        </div>
        <div className="handler__backHome">
            <Link to="/">start explore</Link>
        </div>
    </div>
)

export default EmptySetHandler