import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { connect } from "react-redux";
import { createBrowserHistory } from "history";
import PrivateRoutes from "./PrivateRoutes";
import PublicRoutes from "./PublicRoutes";

// commonComponents
import Header from "../components/Header";
import MobileHeader from "../components/MobileHeader";
import Navigation from "../components/Navigation";
import Footer from "../components/Footer";

// auth module
import UserLoginForm from "../components/auth/UserLoginForm";
import UserSignupForm from "../components/auth/UserSignupForm";
import UserResetPasswordForm from "../components/auth/UserResetPasswordForm";
import UserUpdatePasswordForm from "../components/auth/UserUpdatePasswordForm";

// screens
import SectionPage from "../components/SectionPage";
import HomePage from "../components/HomePage";
import Favorites from "../components/Favorites";
import CategoriesPage from "../components/CategoriesPage";
import CategoryPage from "../components/CategoryPage";
import SearchPage from "../components/SearchPage";
import NotFound from "../components/NotFound";
import ModelPage from "../components/ModelPage";
import StoresPage from "../components/StoresPage";
import StorePage from "../components/StorePage";

// account module
import AccountUserInformationPage from "../components/AccountUserInformationPage";
import AccountStorePage from "../components/AccountStorePage";
import AccountStoreBranchesPage from "../components/AccountStoreBranchesPage";
import AccountPaymentsPage from "../components/AccountPaymentsPage";
import AccountStoreBranch from "../components/AccountStoreBranch";
import AccountReportsPage from "../components/AccountReports";
import AccountStoreReportPage from "../components/AccountReportsPage";
import UserProfile from "../components/UserProfile";

// static pages module
import AboutUs from "../components/static/AboutUs";
import ContactUs from "../components/static/ContactUs";

import { checkUserStatus, startUserLogout, userLogin } from "../actions/auth";

import Logo from "../images/Group 692.png";

export const history = createBrowserHistory();

export class AppRouters extends React.Component {
  state = {
    isFetching: false,
  };

  async componentDidMount() {
    try {
      this.setState({ isFetching: true });
      const auth = JSON.parse(localStorage.getItem("user"));

      await this.props.onTryAutoLogin(auth.token);
      this.props.userLogin(auth);
      this.setState({ isFetching: false });
    } catch (err) {
      this.props.startUserLogout();
      this.setState({ isFetching: false });
    }
  }


  render() {
    if (this.state.isFetching) {
      return (
        <div className="floating-spinner">
          <img src={Logo} alt="Matdawarsh" />
        </div>
      );
    }

    return (
      <Router history={history}>
        <>
          <Header />
          <MobileHeader />
          <Navigation />
          <section className={window.innerWidth < 800 ? "mt-5 pt-3" : ""}>
            <div id="wrapper">
              <Switch>
                <PublicRoutes path="/:locale(en|ar)?/" component={HomePage} exact />
                <PublicRoutes path="/:locale(en|ar)?/user/login" component={UserLoginForm} />
                <PublicRoutes path="/:locale(en|ar)?/user/signup" component={UserSignupForm} />
                <PublicRoutes
                  path="/:locale(en|ar)?/user/account-security/reset-password"
                  component={UserResetPasswordForm}
                  exact
                />
                <PublicRoutes
                  path="/:locale(en|ar)?/user/account-security/reset-password/update/:uid/:token"
                  component={UserUpdatePasswordForm}
                />
                <PublicRoutes path="/:locale(en|ar)?/section/:slug" component={SectionPage} />
                {/*<PublicRoutes path="/:locale(en|ar)?/stores" component={StoresPage} />*/}
                <PublicRoutes path="/:locale(en|ar)?/stores/:city/:state" component={StoresPage} />
                <PublicRoutes path="/:locale(en|ar)?/stores/:city" component={StoresPage} />
                <PublicRoutes path="/:locale(en|ar)?/stores" component={StoresPage} />
                <PublicRoutes path="/:locale(en|ar)?/store/:store_slug" component={StorePage} />
                <PrivateRoutes path="/:locale(en|ar)?/favorites" component={Favorites} />
                <PrivateRoutes
                  path="/account/user-information"
                  component={AccountUserInformationPage}
                  exact
                />
                <PrivateRoutes path="/account/store" component={AccountStorePage} exact />
                <PrivateRoutes
                  path="/account/store/branches"
                  component={AccountStoreBranchesPage}
                  exact
                />
                <PrivateRoutes
                  path="/account/store/:store_slug/branch/:branch_id"
                  component={AccountStoreBranch}
                  exact
                />
                <PrivateRoutes
                  path="/account/store/subscription-and-payments"
                  component={AccountPaymentsPage}
                  exact
                />
                <PrivateRoutes
                  path="/account/store/reports"
                  component={AccountReportsPage}
                  exact
                />
                <PrivateRoutes
                  path="/account/store/:store_slug/branch/:branch_id/report"
                  component={AccountStoreReportPage}
                  exact
                />
                <PrivateRoutes path="/:locale(en|ar)/profile" component={UserProfile} />
                <PublicRoutes path="/:locale(en|ar)?/categories" component={CategoriesPage} />
                <PublicRoutes
                  //path="/:locale(en|ar)?/category/:slug/:page"
                  path="/:locale(en|ar)?/category/:slug"
                  component={CategoryPage}
                  exact
                />
                <PublicRoutes
                  //path="/:locale(en|ar)?/search/:keyword/:page"
                  path="/:locale(en|ar)?/search/:keyword"
                  component={SearchPage}
                  exact
                />
                <PublicRoutes path="/:locale(en|ar)?/model/:slug" component={ModelPage} />
                <PublicRoutes path="/:locale(en|ar)?/about-us" component={AboutUs} />
                <PublicRoutes path="/:locale(en|ar)?/contact-us" component={ContactUs} />
                <Route component={NotFound} />
              </Switch>
            </div>
          </section>
          <Footer />
        </>
      </Router>
    );
  }
}

const mapStateToProps = (state) => ({
  isLoggedIn: state.auth.isLoggedIn,
  locale: state.locales,
});

const mapDispatchToProps = (dispatch) => ({
  onTryAutoLogin: (token) => dispatch(checkUserStatus(token)),
  startUserLogout: () => dispatch(startUserLogout()),
  userLogin: (auth) => dispatch(userLogin(auth)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AppRouters);
