import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import SectionMenuItem from "./section-menu-item";
import { startUserLogout } from "../../actions/auth";
import { changeLocale } from "../../actions/locales";
import { countriesData } from "../../config";
const fetchUrl = new URL(window.location.href);

const MobileMenu = ({
  t,
  locale,
  i18n,
  switchMenuState,
  sections,
  isLoggedIn,
  startUserLogout,
  changeLocale,
  user,
  showMenu,
}) => {
  let selectedCountryData = {};
  if(fetchUrl.host.includes("eg.")){
    selectedCountryData = countriesData.find(data => data.name_en == "Egypt");
  }else if(fetchUrl.host.includes("sa.")){
    selectedCountryData = countriesData.find(data => data.name_en == "KSA");
  }else if(fetchUrl.host.includes("ae.")){
    selectedCountryData = countriesData.find(data => data.name_en == "UAE");
  }else if(fetchUrl.host.includes("kw.")){
    selectedCountryData = countriesData.find(data => data.name_en == "Kuwait");
  }
  selectedCountryData.isExpanded = false;
  const [selectedCountry, setSelectedCountry] = useState(selectedCountryData);
  const [countryExpanded, setCountryExpanded] = useState(false);

  useEffect(() => {
    /*let selectedCountry = {};
    if(fetchUrl.host.includes("eg.")){
      selectedCountry = countriesData.find(data => data.name_en == "Egypt");
    }else if(fetchUrl.host.includes("sa.")){
      selectedCountry = countriesData.find(data => data.name_en == "KSA");
    }else if(fetchUrl.host.includes("ae.")){
      selectedCountry = countriesData.find(data => data.name_en == "UAE");
    }else if(fetchUrl.host.includes("kw.")){
      selectedCountry = countriesData.find(data => data.name_en == "Kuwait");
    }
    selectedCountry.isExpanded = false;
    setSelectedCountry(selectedCountry);*/
  },[])

  const handleCountryExpand = () => {
    setCountryExpanded(!countryExpanded);
  }

  const closeMobileMenu = () => {
    switchMenuState(false);
    document.body.style.overflow = "auto";
  };

  return (
    <div
      className={`mobile-menu ${locale === "en" ? "direction-ltr" : undefined} ${
        showMenu ? "show" : undefined
      }`}
    >
      <div id="wrapper">
        <div className={`mobile-menu__modules ${locale === "ar" ? "flex-row-reverse" : undefined}`}>
          <div className="mobile-menu__module">
            <Link to={`/${locale}/`} onClick={closeMobileMenu}>
              <span className="mobile-menu__module__box">
                <i className="fa fa-home"></i>
              </span>
            </Link>
            <small className="mobile-menu__module__heading">{t("header.home")}</small>
          </div>
          <div className="mobile-menu__module">
            <Link to="/stores" onClick={closeMobileMenu}>
              <span className="mobile-menu__module__box">
                <i className="fas fa-store-alt"></i>
              </span>
            </Link>
            <small className="mobile-menu__module__heading">{t("navigation.stores")}</small>
          </div>
          <div className="mobile-menu__module" hidden={isLoggedIn}>
            <Link to={`/${locale}/user/login`}>
              <span className="mobile-menu__module__box">
                <i className="fas fa-user"></i>
              </span>
            </Link>
            <small className="mobile-menu__module__heading">{t("header.login_alone")}</small>
          </div>
          <div className="mobile-menu__module" hidden={isLoggedIn}>
            <Link to={`/${locale}/user/signup`}>
              <span className="mobile-menu__module__box">
                <i className="fas fa-user-plus"></i>
              </span>
            </Link>
            <small className="mobile-menu__module__heading">{t("header.signup")}</small>
          </div>
          <div className="mobile-menu__module" hidden={!isLoggedIn}>
            <Link to={`/${locale}/favorites`} onClick={closeMobileMenu}>
              <span className="mobile-menu__module__box">
                <i className="fas fa-heart"></i>
              </span>
            </Link>
            <small className="mobile-menu__module__heading">{t("header.favorites")}</small>
          </div>
          {user.account_type === 'store' && (
            <div className="mobile-menu__module" hidden={!isLoggedIn}>
              <Link to={`/account/user-information`} onClick={closeMobileMenu}>
                <span className="mobile-menu__module__box">
                  <i className="fas fa-user"></i>
                </span>
              </Link>
              <small className="mobile-menu__module__heading">{t("header.my_account")}</small>
            </div>
          )}
          {user.account_type === 'branch' && (
            <div className="mobile-menu__module" hidden={!isLoggedIn}>
              <Link to={`/account/store/${user.store_slug}/branch/${user.branch_pk}`} onClick={closeMobileMenu}>
                <span className="mobile-menu__module__box">
                  <i className="fas fa-user"></i>
                </span>
              </Link>
              <small className="mobile-menu__module__heading">{t("header.my_account")}</small>
            </div>
          )}
          {user.account_type === 'normal' && (
            <div className="mobile-menu__module" hidden={!isLoggedIn}>
              <Link to={`/${locale}/profile`} onClick={closeMobileMenu}>
                <span className="mobile-menu__module__box">
                  <i className="fas fa-user"></i>
                </span>
              </Link>
              <small className="mobile-menu__module__heading">{t("header.my_account")}</small>
            </div>
          )}
          <div className="mobile-menu__module">
            <span
              className="mobile-menu__module__box font-weight-bold"
              onClick={() => {
                closeMobileMenu();
                const newLang = locale === "ar" ? "en" : "ar";
                document.location.pathname = `/${newLang}/${document.location.pathname
                  .replace(/^\//, "")
                  .split("/")
                  .splice(1)
                  .join("/")}`;
              }}
            >
              {locale === "ar" ? "E" : "A"}
            </span>
            <small className="mobile-menu__module__heading">{t("header.language_word")}</small>
          </div>
        </div>
        <div className="mobile-menu__sections">
          <div
            className={`mobile-menu__sections__header ${
              locale === "ar" ? "direction-rtl" : undefined
            }`}
          >
            <Link to={`/${locale}/categories`} onClick={() => closeMobileMenu()}>
              <h3>{t("navigation.all_categories")}</h3>
              {locale === "ar" ? (
                <i className="fas fa-chevron-left"></i>
              ) : (
                <i className="fas fa-chevron-right"></i>
              )}
            </Link>
          </div>
          <ul
            className={`mobile-menu__sections__list navbar-nav mr-auto ${
              locale === "ar" ? "direction-rtl" : undefined
            }`}
          >
            {sections.map((section) => (
              <SectionMenuItem
                key={section.slug}
                section={section}
                locale={locale}
                switchMenuState={switchMenuState}
              />
            ))}
            <li>
              <a onClick={handleCountryExpand}>
                <span style={{ fontWeight: 900 }}>
                  {locale == "ar" ? "اليلد":"Country"}
                </span>
                <div>
                  <span>{selectedCountry["name_" + locale]}</span>
                  <img src={selectedCountry.image} width="31" height="20" style={{margin: "0 4px"}} />
                  {locale === "ar" ? (
                    <i className={`fas fa-chevron-${!countryExpanded ? "left" : "down"}`}></i>
                  ) : (
                    <i className={`fas fa-chevron-${!countryExpanded ? "right" : "down"}`}></i>
                  )}
                </div>
              </a>
              {countryExpanded && (
                <ul
                  className={`mobile-menu__sections__list navbar-nav mr-auto ${
                    locale === "ar" ? "direction-rtl" : undefined
                  }`}
                >
                  {countriesData.map((eachData) => (
                    <li>
                      <a href={eachData.url}>
                        <div>
                          <img src={eachData.image} width="31" height="20" />
                          <span>{eachData["name_" + locale]}</span>
                        </div>
                      </a>
                    </li>
                  ))}
                </ul>
              )}
            </li>
          </ul>
        </div>
        {isLoggedIn && (
          <div
            className={`mobile-menu__logout ${locale === "ar" ? "flex-row-reverse" : undefined}`}
          >
            <div className="mobile-menu__logout__icon">
              <i className="fas fa-sign-out-alt"></i>
            </div>
            <div className="mobile-menu__logout__title" onClick={() => startUserLogout()}>
              <p>{t("header.logout")}</p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isLoggedIn: state.auth.isLoggedIn,
  user: state.auth.user,
  locale: state.locales,
  sections: state.sections.sections,
});

const mapDispatchToProps = (dispatch) => ({
  startUserLogout: () => dispatch(startUserLogout()),
  changeLocale: (locale) => dispatch(changeLocale(locale)),
});

export default compose(withTranslation(), connect(mapStateToProps, mapDispatchToProps))(MobileMenu);
