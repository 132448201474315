import React, { useState, useEffect } from "react";
import API from "../api";

function Ad({ width, height, position, slug, mobile, }) {
  const [isFetching, setIsFetching] = useState(false);
  const [ad, setAd] = useState(null);

  useEffect(() => {
    async function fetchAd() {
      try {
        setIsFetching(true);
        const response = await API.get(
          `basic-data/advertisements/?position=${position}${slug ? `&page_slug=${slug}` : ""}`
        );
        setAd(response.data);
        setIsFetching(false);
      } catch (err) {
        // Ignore Error Message
        setIsFetching(false);
      }
    }

    if((window.innerWidth < 800 && mobile) || (window.innerWidth > 800 && !mobile)) fetchAd();
  }, []);

  if ((window.innerWidth < 800 && !mobile) || (window.innerWidth > 800 && mobile)) return null;

  return (
    <div style={{ ...styles.ad, width, height, border: ad && ad.code ? '' : '1px solid #eee' }}>
      <div dangerouslySetInnerHTML={{ __html: ad && ad.code }} />
      {ad && ad.code ? '' : <span className="squareAd__identifier" style={styles.ad_identifier}>AD</span>}
    </div>
  );
}

const styles = {
  ad: {
    position: "relative",
    borderRadius: "5px",
    overflow: "hidden",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  ad_identifier: {
    borderRadius: "3px",
    border: "1px solid #ff1c1c",
    backgroundColor: "#fff",
    padding: "2px 8px",
    fontSize: "10px",
    position: "absolute",
    fontWeight: 900,
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#ff1c1c",
    top: "5px",
    left: "5px",
  },
};

export default Ad;
