import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import Spinner from '../components/Spinner'
import Switches from '../components/genericComponents/Switches'
import ShadowCard from '../components/genericComponents/ShadowCard'
import NoResults from '../components/NoResults'
import Branch from './genericComponents/Branch'
import SupportInfo from './genericComponents/SupportInfo'
import NotFound from './NotFound'
import BranchListItem from '../components/genericComponents/BranchListItem'

import {
  startFetchStoreBranches, startRemoveStoreBranch, startCreateStoreBranch,
  startFetchWorkDomains
} from '../actions/branches'

export class AccountReportsPage extends React.Component {
  state = { 
    isFetching: false, 
    error: null, 
    isAddClicked: false, 
    work_domains: null,
    branchPk: null,
  }

  getStoreSlug = () => {
    return JSON.parse(localStorage.getItem('user')).user.store_slug;
  };

  async componentDidMount() {
    this.setState({ isFetching: true })
    try {
      await this.props.startFetchStoreBranches(this.getStoreSlug())
      await this.props.startFetchPlaces()
      const response = await this.props.startFetchWorkDomains()
      this.setState({ isFetching: false, work_domains: response.data })
    } catch (err) {
      this.setState({ isFetching: false, error: err })
    }
  }

  render() {

    if (this.props.profile && !this.props.profile.vendor_name) {
      return <NotFound />;
    }

    return (
      <React.Fragment>
        <SupportInfo />
        <ShadowCard>
          <Switches />
          <div className="branches-header d-flex justify-content-between align-items-center p-3">
            <div></div>
            <h3 className="">الفروع</h3>
          </div>
          {this.state.isFetching ? <Spinner /> :
              this.props.branches.length ? (
                <div className="table-container">
                  <div className="p-3 table-contents">
                      {this.props.branches.map(branch =>
                          <BranchListItem key={branch.pk}>
                              <div className="branch-list-item__options">
                                  <button type="button" className="blue">
                                      <Link to={`/account/store/${this.getStoreSlug()}/branch/${branch.pk}/report`}>
                                          <i className="fas fa-eye"></i>
                                      </Link>
                                  </button>
                              </div>
                              <p>{branch.name_arabic}</p>
                              <p>{branch.government_name}</p>
                              <p>{branch.neighbourhood.name}</p>
                          </BranchListItem>
                      )}
                  </div>
                </div>
              ) : (
                      <NoResults message="لا توجد فروع لهذا المتجر" />
                  )
          }
        </ShadowCard>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  branches: state.branches,
  places: state.places.places,
  profile: state.profile
})

const mapDispatchToProps = (dispatch) => ({
  startFetchStoreBranches: (slug) => dispatch(startFetchStoreBranches(slug)),
  startRemoveStoreBranch: (id) => dispatch(startRemoveStoreBranch(id)),
  startCreateStoreBranch: (values) => dispatch(startCreateStoreBranch(values)),
  startFetchPlaces: () => dispatch(startFetchPlaces()),
  startFetchWorkDomains: () => dispatch(startFetchWorkDomains())
})

export default connect(mapStateToProps, mapDispatchToProps)(AccountReportsPage)