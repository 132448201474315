import React, { Component } from 'react'
import { Carousel } from 'react-responsive-carousel'
import "react-responsive-carousel/lib/styles/carousel.min.css"
import API from '../api'

import Logo from '../images/Group 692.png'

export default class HotDealsCarousel extends Component {

    state = { isFetching: false, deals: null }

    async componentDidMount() {
        try {
            this.setState({ isFetching: true })
            const response = await API.get(`basic-data/advertisements/?position=${this.props.position}${this.props.slug ? `&page_slug=${this.props.slug}` : ''}`)
            this.setState({ deals: response.data.code.split("\r\n\r\n"), isFetching: false })
        } catch (error) {
            this.setState({ isFetching: false })
        }
    }

    render() {

        if (this.state.isFetching || !this.state.deals) {
            return (
                <div className="hot-deals-carousel contrast d-flex justify-content-center align-items-center">
                    <img src={Logo} alt="Matdawarsh" />
                </div>
            )
        }

        return (
            <div className="hot-deals-carousel">
                <Carousel
                    showArrows={true}
                    showStatus={false}
                    showThumbs={false}
                    showIndicators={false}
                    infiniteLoop={true}
                    stopOnHover={true}
                    autoPlay={true}
                    swipeable={true}
                    emulateTouch={true}
                    transitionTime={500}
                >
                    {this.state.deals && this.state.deals.map((deal, index) =>
                        <div dangerouslySetInnerHTML={{ __html: deal }} key={index} />
                    )}
                </Carousel>
            </div>
        )
    }
}

