import {
    SET_SECTIONS,
    FETCH_SECTIONS_REQUEST,
    FETCH_SECTIONS_SUCCESS,
    FETCH_SECTIONS_FAILURE
} from '../constants/action-types'
import API from '../api'


const setSections = sections => ({
    type: SET_SECTIONS,
    sections
})

export const startFetchSections = () => async dispatch => {
    dispatch({ type: FETCH_SECTIONS_REQUEST })
    try {
        const response = await API.get('collector/section-list/');
        if(response.data.results) response.data.results.map(data => data.featured_models = []);
        dispatch(setSections(response.data.results))
        dispatch({ type: FETCH_SECTIONS_SUCCESS })
    }
    catch (error) {
        dispatch({
            type: FETCH_SECTIONS_FAILURE,
            message: error.response.data || 'something went wrong.'
        })
    }
}

export const getSectionFeaturedModels = (sectionId) => dispatch => {
    return API.get(`collector/section-model/${sectionId}/`);
}
