import { SET_PACKAGES } from '../constants/action-types'
import API from '../api'

const setPackages = (packages) => ({
    type: SET_PACKAGES,
    packages
})

export const startGetPackages = () => async dispatch => {
    try {
        const response = await API.get('basic-data/packages-payment-methods/')
        dispatch(setPackages(response.data))
    } catch (err) {
        const errorMessage = err.message
        throw new Error(errorMessage)
    }
}