import { SORT_BY } from '../constants/action-types';

const defaultSorting = ''

export default (state = defaultSorting, action) => {
    switch(action.type) {
        case SORT_BY:
            return action.sortBy
        default: 
            return state;
    }
};