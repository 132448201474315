import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { Formik } from 'formik'
import * as Yup from 'yup'

import { startFetchStoreProfile, startUpdateStoreProfile } from '../actions/profile'
import { startFetchPlaces } from '../actions/places'

import picture from '../images/picture.svg'

import Avatar from './genericComponents/Avatar'
import FormInput from './genericComponents/FormInput'
import FormSelectOption from './genericComponents/FormSelectOption'
import ShadowCard from './genericComponents/ShadowCard'
import Switches from './genericComponents/Switches'
import SupportInfo from './genericComponents/SupportInfo'
import Spinner from './Spinner'

export class AccountUserInformationPage extends Component {

    state = {
      isFetching: false,
      avatar: null,
      isEditing: false
    }

    async componentDidMount() {
      try {
        this.setState({ isFetching: true })
        await this.props.startFetchStoreProfile()
        await this.props.startFetchPlaces()
        this.setState({ isFetching: false })
      } catch (error) {
        // fetch log error
        this.setState({ isFetching: false })
      }
    }

    changeFormStateHandler = () => {
      this.setState({ isEditing: !this.state.isEditing })
    }

    submitHandler = async (values, bag) => {
      this.setState({ isFetching: true })
      try {
        this.state.avatar ?
          await this.props.startUpdateStoreProfile({ ...values, vendor_image: this.state.avatar }, 'vendor') :
          await this.props.startUpdateStoreProfile({ ...values }, 'vendor')
          this.setState({ isFetching: false, isEditing: false })
      } catch (error) {
        bag.setSubmitting(false)
        this.setState({ isFetching: false })
      }
    }

    toBase64 = (file) => {
      let reader = new FileReader()

      reader.onload = () => {
        this.setState({ avatar: reader.result })
      }
      reader.readAsDataURL(file)
    }

    onFileChangeHandler = (event) => {
      const files = Object.values(event.target.files).filter(image => image.type !== 'image');
      files.forEach(file => this.toBase64(file))
    }

    render() {
      return (
          <React.Fragment>
            <SupportInfo />
            <ShadowCard>
                <Switches />
                <div className="my-account__actions">
                    <Link to={'/account/store'}><i className="fas fa-arrow-left"></i></Link>
                    <button type="button" onClick={this.changeFormStateHandler}><i className="fas fa-pen"></i></button>
                </div>
                {this.state.isFetching ? <Spinner /> : (
                    <div className="my-account py-5 flex-column flex-lg-row">
                        <div className="avatar-container">
                            {!this.state.avatar ?
                                <Avatar
                                  src={this.props.profile ? this.props.profile.vendor_image : picture}
                                  onFileChangeHandler={this.onFileChangeHandler}
                                  hidden={!this.state.isEditing}
                                /> :
                                <Avatar
                                  src={this.state.avatar}
                                  onFileChangeHandler={this.onFileChangeHandler}
                                  hidden={!this.state.isEditing}
                                />
                            }

                        </div>
                        <div className="flex-1 user-info-container">
                            <Formik
                                initialValues={{
                                    vendor_name: this.props.profile ? this.props.profile.vendor_name : '',
                                    vendor_mobile: this.props.profile ? this.props.profile.vendor_mobile : '',
                                    vendor_government: this.props.profile ? this.props.profile.vendor_government : '',
                                    vendor_neighbourhood: this.props.profile ? this.props.profile.vendor_neighbourhood : '',
                                    vendor_address: this.props.profile ? this.props.profile.vendor_address : '',
                                    about_vendor: this.props.profile ? this.props.profile.about_vendor : ''
                                }}
                                onSubmit={this.submitHandler}
                                validationSchema={
                                    Yup.object().shape({
                                        vendor_name: Yup.string().required('ادخل اسم التاجر'),
                                        vendor_mobile: Yup.string().required('ادخل رقم الموبايل'),
                                        vendor_government: Yup.string().required('اختر المحافظة'),
                                        vendor_neighbourhood: Yup.string().required('اختر المنطقة'),
                                        vendor_address: Yup.string().required('ادخل العنوان'),
                                        about_vendor: Yup.string()
                                    })
                                }
                            >
                                {({
                                    handleSubmit,
                                    handleChange,
                                    handleBlur,
                                    values,
                                    errors,
                                    touched,
                                    isValid,
                                    isSubmitting
                                }) => (
                                        <div className="auth__form mx-100">
                                            <form onSubmit={handleSubmit} className="m-0">
                                                <div className="d-flex justify-content-start flex-column flex-lg-row text-right direction-rtl">
                                                    <div className="flex-1 dynamic-padding">
                                                        <FormInput
                                                            autoCapitalize="none"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            autoFocus
                                                            value={values.vendor_name}
                                                            label="اسم التاجر"
                                                            required
                                                            disabled={!this.state.isEditing}
                                                            placeholder="ادخل الاسم"
                                                            type="text"
                                                            name="vendor_name"
                                                            error={touched.vendor_name && errors.vendor_name}
                                                        />
                                                        <FormInput
                                                            autoCapitalize="none"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.vendor_address}
                                                            label="العنوان"
                                                            required
                                                            disabled={!this.state.isEditing}
                                                            placeholder="ادخل العنوان"
                                                            type="text"
                                                            name="vendor_address"
                                                            error={touched.vendor_address && errors.vendor_address}
                                                        />
                                                        <FormSelectOption
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.vendor_neighbourhood}
                                                            options={this.props.places && this.props.places.find(place => place.name === values.vendor_government)}
                                                            disabled={!values.vendor_government}
                                                            label="المنطقة"
                                                            required
                                                            disabled={!this.state.isEditing}
                                                            name="vendor_neighbourhood"
                                                            error={touched.vendor_neighbourhood && errors.vendor_neighbourhood}
                                                        />
                                                    </div>
                                                    <div className="flex-1 px-100 dynamic-padding">
                                                        <FormInput
                                                            autoCapitalize="none"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.vendor_mobile}
                                                            label="موبايل"
                                                            placeholder="ادخل رقم الموبايل"
                                                            type="text"
                                                            name="vendor_mobile"
                                                            required
                                                            disabled={!this.state.isEditing}
                                                            error={touched.vendor_mobile && errors.vendor_mobile}
                                                        />
                                                        <FormSelectOption
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.vendor_government}
                                                            options={this.props.places && this.props.places}
                                                            label="المحافظة"
                                                            required
                                                            disabled={!this.state.isEditing}
                                                            name="vendor_government"
                                                            error={touched.vendor_government && errors.vendor_government}
                                                        />
                                                        <FormInput
                                                            autoCapitalize="none"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.about_vendor}
                                                            label="نبذه عن التاجر"
                                                            placeholder="ادخل نبذه عن التاجر"
                                                            disabled={!this.state.isEditing}
                                                            type="text"
                                                            name="about_vendor"
                                                            error={touched.about_vendor && errors.about_vendor}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="text-right mt-5">
                                                  <button
                                                      type="submit"
                                                      className="btn btn-info px-5 pt-1 pb-1"
                                                      disabled={!isValid || isSubmitting}
                                                      hidden={!this.state.isEditing}
                                                  >
                                                      حفظ
                                                  </button>
                                                </div>
                                            </form>
                                        </div>
                                    )}
                            </Formik>
                        </div>
                    </div>
                )}
            </ShadowCard>
          </React.Fragment>
      )
    }
}

const mapStateToProps = (state) => ({
    profile: state.profile,
    places: state.places.places
})

const mapDispatchToProps = (dispatch) => ({
    startFetchStoreProfile: () => dispatch(startFetchStoreProfile()),
    startUpdateStoreProfile: (values, type) => dispatch(startUpdateStoreProfile(values, type)),
    startFetchPlaces: () => dispatch(startFetchPlaces())
})

export default connect(mapStateToProps, mapDispatchToProps)(AccountUserInformationPage)