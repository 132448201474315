import React, { useState } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import ModelFeatures from "./ModelFeatures";
import Store from "./Store";
import StoreParent from "./StoreParent";
import Ad from "./Ad";

import defaultImage from "../images/default.jpg";

const fetchUrl = new URL(window.location.href);

const ModelMainDetails = ({
  t,
  locale,
  sub_model: { pk, name, description, features, items, brand, category, least_price_items },
}) => {
  const [isFlipped, setFlip] = useState(true);
  const [srcImage, setSrcImage] = useState(least_price_items.length ? (
    least_price_items[0].store_branch && least_price_items[0].store_branch.store_logo || 
    least_price_items[0].website.image) : '');
  const [readMore, setReadMore] = useState(false);

  let lowestPerStore = {};
  Object.keys(items.online).map((i) => {
    lowestPerStore[i] = items.online[i][0].price;
  });
  return (
    <div className="model">
      <div
        className={`model__name model__name--mobile text-center ${
          locale === "ar" ? "direction-rtl" : {}
        }`}
      >
        <h1>{name[locale]}</h1>
      </div>
      <div
        className={`brand-category brand-category--mobile ${
          locale === "ar" ? "flex-row-reverse" : "flex-row"
        }`}
      >
        <span>
          {locale === "ar" ? "الفئة" : "category"} :
          <Link to={`/${locale}/category/${category.slug}`}>{category.name[locale]}</Link>
        </span>
        <span className="mx-5">
          {locale === "ar" ? "البراند" : "brand"} :
          <Link to={`/${locale}/category/${category.slug}?brands=${brand.name.en}`}>
            {brand.name[locale]}
          </Link>
        </span>
      </div>
      <div className={`model__name ${locale === "ar" ? "text-right direction-rtl" : "text-left"}`}>
        <h1>{name[locale]}</h1>
      </div>
      <div className={`brand-category ${locale === "ar" ? "flex-row-reverse" : "flex-row"}`}>
        <span>
          {locale === "ar" ? "الفئة" : "category"} :
          <Link to={`/${locale}/category/${category.slug}`}>{category.name[locale]}</Link>
        </span>
        <span className="mx-5">
          {locale === "ar" ? "البراند" : "brand"} :
          <Link to={`/${locale}/category/${category.slug}?brands=${brand.name.en}`}>
            {brand.name[locale]}
          </Link>
        </span>
      </div>
      <div style={{"margin":"8px 0"}} hidden={window.innerWidth > 800}>
        <Ad position={"mobile_model_before_price"} width={"100%"} height={"auto"} mobile={true} />
      </div>
      {least_price_items.length ? (
        <div className={`online-store best-price w-100 ${locale === "ar" ? "direction-rtl" : ""}`}>
          {least_price_items.length && (
            <div className="online-store__information">
              {locale === "ar" ? <p>افضل سعر</p> : <p>Best Price</p>}
              <div className="online-store__information__price big-size">
                <span className="value">{least_price_items[0].price}</span>
                <span className="currency">{
                  fetchUrl.host.includes("sa.") ? t("sub_models.currency_sa")
                  :
                  fetchUrl.host.includes("kw.") ? t("sub_models.currency_kw")
                  :
                  fetchUrl.host.includes("ae.") ? t("sub_models.currency_ae")
                  :
                  t("sub_models.currency_eg")
                }</span>
              </div>
              <div className="online-store__information__avatar">
                <img
                  src={srcImage}
                  alt={least_price_items[0].website.slug}
                  onError={() => setSrcImage(defaultImage)}
                />
              </div>
            </div>
          )}
          <div className="online-store__redirect">
            {least_price_items[0].store_branch ? (
              <Link to={{pathname: `/${locale}/store/${least_price_items[0].store_branch.slug}`, state: {comesFromSection: "product_page", productId: pk} }}>
                {locale === "ar" ? "الى التاجر" : "Store"}
              </Link>
            ) : (
              <a href={least_price_items[0].link} target="_blank">
                {t("sub_models.to_store")}
                {locale === "ar" ? (
                  <i className="fas fa-caret-left px-2"></i>
                ) : (
                  <i className="fas fa-caret-right px-2"></i>
                )}
              </a>
            )}
          </div>
        </div>
      ) : (
        ""
      )}
      <ModelFeatures features={features} />
      {Object.keys(items["online"]).length ? (
        <div className="model__items-online-stores">
          <div
            className={`model-items-onlne-stores__slug ${
              locale === "ar" ? "text-right" : undefined
            }`}
          >
            <p>
              ({Object.keys(items["online"]).length}) {t("model.online_stores")}
            </p>
          </div>
          <div className="scroll-wrapper">
            {Object.keys(items["online"]).map((store, index) => (
              <React.Fragment key={index}>
              {items["online"][store].length > 1 ? (
                <React.Fragment>
                  <StoreParent
                    {...items["online"][store][0]}
                    length={items["online"][store].length}
                    t={t}
                    isFlipped={isFlipped}
                    setFlip={setFlip}
                    lowestPerStore={lowestPerStore[store]}
                  />
                  {items["online"][store].map((item, index) => (
                    <Store
                      key={index}
                      isFlipped={isFlipped}
                      width={"w-75"}
                      isFromModel={true}
                      {...item}
                    />
                  ))}
                </React.Fragment>
              ) : (
                items["online"][store].map((item, index) => (
                  <Store key={index} width={"w-100"} isFromModel={true} {...item} />
                ))
              )}
              </React.Fragment>
            ))}
          </div>
        </div>
      ) : (
        ""
      )}
      <div className="py-3">
        <Ad position={"model_middle_right"} width={"100%"} height={100} />
      </div>
      {Object.keys(items["offline"]).length ? (
        <div className="model__items-online-stores">
          <div
            className={`model-items-onlne-stores__slug orange ${
              locale === "ar" ? "text-right" : undefined
            }`}
          >
            <p>
              ({Object.keys(items["offline"]).length}) {t("model.direct_traders")}
            </p>
          </div>
          <div className="scroll-wrapper">
            {Object.keys(items["offline"]).map((store) =>
              items["offline"][store].map((item, index) => (
                <Store
                  key={index}
                  isFromModel={true}
                  width={"w-100"}
                  isDirectTrader={true}
                  productId={pk}
                  {...item}
                />
              ))
            )}
          </div>
        </div>
      ) : (
        ""
      )}
      <div className={`model__description ${locale === "ar" ? "text-right" : undefined}`}>
        <div className="model__description__slug">
          <p>{t("model.slug")}</p>
        </div>

        {description && description.hasOwnProperty(locale) && description[locale] ? (description[locale].split(" ").length > 30 && !readMore ? (
          <React.Fragment>
            <p
              className={locale === "ar" ? "direction-rtl" : undefined}
              dangerouslySetInnerHTML={{
                __html: `${description[locale]
                  .split(" ")
                  .splice(0, 30)
                  .join(" ")}...`,
              }}
            ></p>
            <span className="readmore-description" onClick={() => setReadMore(!readMore)}>
              {locale === "ar" ? "المزيد" : "Read more"}
            </span>
          </React.Fragment>
        ) : (
          <p
            className={locale === "ar" ? "direction-rtl" : undefined}
            dangerouslySetInnerHTML={{
              __html: description[locale],
            }}
          ></p>
        )) : (
          <h1 style={{textAlign: "center", fontSize: "24px"}}>{locale === "ar" ? "لا يوجد وصف" : "No description"}</h1>
        )}
      </div>
      <div style={{"margin":"8px 0"}} hidden={window.innerWidth > 800}>
        <Ad position={"mobile_model_after_desc"} width={"100%"} height={"auto"} mobile={true} />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  locale: state.locales,
});

export default compose(withTranslation(), connect(mapStateToProps))(ModelMainDetails);
