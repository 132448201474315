import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { startCreateBranchPackage } from '../actions/branches'

import Spinner from './Spinner'

const BranchPackages = (props) => {
    const [isFetching, setIsFetching] = useState(false)
    const [selectedPackage, setSelectedPackage] = useState(props.package ? props.package.package_name : props.packages[0].name)
    const [selectedPricesForPackage, setSelectedPricesForPackage] = useState(props.package ? props.packages && props.packages.find(({ name }) => name == props.package.package_name).prices : props.packages[0].prices)
    const [isPriceSelected, setIsPriceSelected] = useState(props.package ? props.package.package : '')
    const [selectedPayment, setSelectedPayment] = useState(props.package ? props.package.payment_method_type : props.payment_methods[0].method)
    const [selectedInfoForPayment, setselectedInfoForPayment] = useState(props.package ? props.payment_methods.find(method => method.method === props.package.payment_method_type).info : props.payment_methods[0].info)
    const [ispaymentMethodSelected, setSelectedPaymentMethod] = useState(props.package ? props.package.payment_method_pk : '')
    const [promocode, setPromocode] = useState('');

    const getPaymentInfo = () => {
      if (selectedPayment) {

        if (ispaymentMethodSelected && selectedPayment === 'banks') {
          const account_number = selectedInfoForPayment && selectedInfoForPayment.find((payment) => payment.pk == ispaymentMethodSelected).account_number;
          return `رقم الحساب: ${account_number}`
        }
        
        if (ispaymentMethodSelected && selectedPayment === 'mobiles') {
          const account_number = selectedInfoForPayment && selectedInfoForPayment.find((payment) => payment.pk == ispaymentMethodSelected).mobile_number;
          return `رقم الموبايل: ${account_number}`
        }
  
        return props.payment_methods.find((payment) => payment.method == selectedPayment).info[0].info;
      }

      return  null;
    }

    useEffect(() => {
      if (selectedPayment === 'cash' || selectedPayment === 'fawry') {
        setselectedInfoForPayment(null);
      }
    }, [props.package])

    const paymentMethodsMArabicName = {
      'fawry': 'فوري',
      'cash': 'كاش',
      "mobiles": 'موبايل',
      'banks': 'البنك'
    };

    const selectedPackageHandler = (event, id) => {
      if (props.active && props.package) return;

      const prices = props.packages && props.packages.find(({ pk }) => pk === id).prices
      setSelectedPricesForPackage(prices)
      setSelectedPackage(event.target.name)
    }


    const selectedPaymentMethodHandler = (event, methodName) => {
      if (props.active && props.package) return;
        
      setSelectedPayment(event.target.name)
      setSelectedPaymentMethod('')
      if (methodName === 'cash' || methodName === 'fawry') {
        setselectedInfoForPayment(null)
      } else  {
        const info = props.payment_methods.find(({ method }) => method === methodName).info
        setselectedInfoForPayment(info)
      }
    }

    const prepareSave = () => {
      const infos = props.payment_methods.find(({ method }) => method === selectedPayment).info;
      const selectedMethod = ispaymentMethodSelected ? infos.find(({ pk }) => pk == ispaymentMethodSelected) : infos[0];
      let details = ''

      for (let key in selectedMethod) {
        details += `\n ${key}: ${selectedMethod[key]}`
      }

      if (selectedPackage === 'الباقة المجانية') {
        return {
          package: 13,
          payment_method_type: 'cash',
          payment_method_pk: 1,
          payment_method_details: "\n pk: 1\n info: الدفع كاش من خلال مندوبنا",
          promo_code: "free"
        }
      } else {
        return {
          package: isPriceSelected || props.packages.find(({ pk }) => pk === packageId).prices[0].pk,
          payment_method_type: selectedPayment || 'cash',
          payment_method_pk: ispaymentMethodSelected || infos[0].pk,
          payment_method_details: details,
          promo_code: promocode
        }
      }
    }

    const subscripeHandler = async () => {
      try {
        setIsFetching(true)
        await props.startCreateBranchPackage(props.branch_id, prepareSave())
        setIsFetching(false)
      } catch (err) {
        // Ignore Error Message
      }
    };

    if (isFetching) return <Spinner />;

    return (
        <div className="packages-wrapper">
            <div className="d-flex justify-content-between align-items-center">
                <h3 style={{ margin: 0, fontSize: '18px', fontWeight: 'bold' }} hidden={props.active === false || props.package === null}>
                    <span style={{ color: 'green', padding: '0 10px' }}>{props.package && props.package.start_date}</span>
                    :
                    <span style={{ color: 'red', padding: '0 10px' }}>{props.package && props.package.end_date}</span>
                </h3>
                <h3 className="package-title flex-1">باقات الاشتراك</h3>
            </div>

            <div className="packages">
              {props.packages.map(({ pk, name }) => (
                <label key={pk} htmlFor={name}>
                  <span>{name}</span>
                  <input 
                    type="radio" 
                    name={name} 
                    id={name} 
                    value={name} 
                    checked={selectedPackage === name} 
                    onChange={(event) => {
                      selectedPackageHandler(event, pk)
                      setIsPriceSelected('')
                    }
                    } 
                  />
                </label>
              ))}
            </div>

            {selectedPackage !== 'الباقة المجانية' && (
              <React.Fragment>
                <h3 className="package-title">مدة الاشتراك</h3>
                <div className="periods">
                    {selectedPricesForPackage && selectedPricesForPackage.map(price => (
                        <div
                            className={`period ${isPriceSelected === price.pk ? 'selected' : undefined}`}
                            key={price.pk}
                            onClick={() => {
                                if (props.active && props.package) return;
                                setIsPriceSelected(price.pk)
                            }
                            }
                        >
                            <span>{price.period}</span>
                            <strong>{price.price}</strong>
                            <span>جنيه</span>
                        </div>
                    ))}
                </div>
                
                <h3 className="package-title">طريقة الدفع</h3>
                <div className="packages">
                    {props.payment_methods.map(({ method }, index) => (
                      <label key={index} htmlFor={method}>
                        <span>{paymentMethodsMArabicName[method]}</span>
                        <input type="radio" name={method} id={method} value={method} checked={selectedPayment === method} onChange={(event) => selectedPaymentMethodHandler(event, method)} />
                      </label>
                    ))}
                </div>
                
                {selectedInfoForPayment && (
                  <React.Fragment>
                    <h3 className="package-title">اختر</h3>
                    <select
                        disabled={props.active && props.package}
                        style={{
                            width: '300px',
                            background: 'transparent',
                            textAlign: 'right',
                            direction: 'rtl',
                            border: '1px solid #ccc',
                            borderRadius: '3px'
                        }}
                        value={ispaymentMethodSelected} onChange={(event) => setSelectedPaymentMethod(event.target.value)}>
                        <option value=""></option>
                        {selectedInfoForPayment && selectedInfoForPayment.map(payment => (
                            selectedPayment === 'banks' ?
                                <option key={payment.pk} value={payment.pk} label={payment.bank_name}>{payment.bank_name}</option>
                                :
                                <option key={payment.pk} value={payment.pk} label={payment.network}>{payment.network}</option>
                        ))}
                    </select>
                  </React.Fragment>
                )}
                <h5 className="mt-2" style={{ color: '#333', fontSize: 16, fontWeight: "bold" }}>{getPaymentInfo()}</h5>

                <label className="package-title mt-3 mb-0">لديك برومو كود؟</label>
                <input  
                  type="text" 
                  className="form-control" 
                  placeholder="برومو كود" 
                  value={promocode} 
                  onChange={(event) => setPromocode(event.target.value)} 
                  style={{ 
                    width: '30%',
                    padding: 10,
                    marginLeft: 'auto',
                    textAlign: 'right'
                  }}
                />
              </React.Fragment>
            )}

            <div className="text-left">
                <button
                    className="btn btn-danger px-5"
                    type="button"
                    hidden={props.active && props.package}
                    onClick={subscripeHandler}
                    disabled={isFetching || selectedPackage !== 'الباقة المجانية'  && (!isPriceSelected || (['mobiles', 'banks'].includes(selectedPayment) && !ispaymentMethodSelected))}
                >اشترك</button>
            </div>
        </div >
    )
}

const mapStateToProps = (state) => ({
    packages: state.packages.packages,
    payment_methods: state.packages.payment_methods
})

const mapDispatchToProps = (dispatch) => ({
    startCreateBranchPackage: (branch_id, data) => dispatch(startCreateBranchPackage(branch_id, data))
})

export default connect(mapStateToProps, mapDispatchToProps)(BranchPackages)