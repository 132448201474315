import API from '../api'
import {
    SET_BRANCH_ITEMS,
    ADD_BRANCH_ITEM,
    UPDATE_BRANCH_ITEM,
    DELETE_BRANCH_ITEM
} from '../constants/action-types'


const setBranchItems = (items) => ({
    type: SET_BRANCH_ITEMS,
    items
})

export const startFetchBranchItems = (page = 1, branch_id,word = null,category = null,brand = null) => async dispatch => {
    try {
        let query = `store_branch_pk=${branch_id}&page=${page}`;
        if(word) query += "&name=" + word;
        if(category) query += "&category=" + category;
        if(brand) query += "&brand=" + brand;
        const response = await API.get(`store/store-branch-items-list/?${query}`)
        dispatch(setBranchItems(response.data))
    } catch (err) {
        throw new Error('something went wrong')
    }
}

const addBranchItem = (item) => ({
    type: ADD_BRANCH_ITEM,
    item
})

export const startAddBranchItem = (branch_id, values) => async dispatch => {
    try {
        const response = await API.post(`store/store-branch-item-create/?store_branch_pk=${branch_id}`, values)
        dispatch(addBranchItem(response.data))
    } catch (err) {
        throw new Error('something went wrong')
    }
}

const updateBranchItem = (id, updates) => ({
    type: UPDATE_BRANCH_ITEM,
    id,
    updates
})

export const startUpdateBranchItem = (branch_id, item_id, updates) => async dispatch => {
    try {
        await API.put(`store/store-branch-item-update/${item_id}/?store_branch_pk=${branch_id}`, updates)
        dispatch(updateBranchItem(item_id, updates))
    } catch (err) {
        throw new Error('something went wrong')
    }
}

const deleteBranchItem = (item_id) => ({
    type: DELETE_BRANCH_ITEM,
    item_id
})

export const startDeleteBranchItem = (branch_id, item_id) => async dispatch => {
    try {
        await API.delete(`store/store-branch-item-destroy/${item_id}/?store_branch_pk=${branch_id}`)
        dispatch(deleteBranchItem(item_id))
    } catch (err) {
        // Ignore Error Message
    }
}
