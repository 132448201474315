import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";

import FormInput from "../genericComponents/FormInput";
import pageImage from "../../images/Group 197@2x.png";

import API from "../../api";

const UserSignupForm = ({ history, t, locale }) => {
  const submitHandler = async (values, bag) => {
    try {
      await API.post(`users/signup/${values.type}/`, values);
      return history.push(`/${locale}/user/login`);
    } catch (error) {
      bag.setErrors(error.response.data);
      bag.setSubmitting(false);
    }
  };

  return (
    <div className="authSystem">
      <div className={`auth ${locale === "ar" ? "flex-row-reverse" : ""} `}>
        <div className="auth__form">
          <div className={`auth__header ${locale === "ar" ? "flex-row-reverse" : "flex-row"}`}>
            <span className="vertical"></span>
            <h2>{t("signup.title")}</h2>
            <span className="horizontial"></span>
          </div>
          <Formik
            initialValues={{
              email: "",
              password1: "",
              password2: "",
              type: "normal",
            }}
            onSubmit={submitHandler}
            validationSchema={Yup.object().shape({
              email: Yup.string()
                .email("Email is not valid.")
                .required("Email is required."),
              password1: Yup.string()
                .min(8, "Password must be at least 8 characters.")
                .required("Password is required."),
              password2: Yup.string()
                .oneOf([Yup.ref("password1"), null], "Passwords must match.")
                .required("Confirm password is required."),
              type: Yup.string().required("Type is required."),
            })}
          >
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              values,
              errors,
              touched,
              isSubmitting,
            }) => (
              <form
                onSubmit={handleSubmit}
                className={locale === "ar" ? "text-right" : "text-left"}
              >
                <div className="account__form__inner">
                  <div className="single-input">
                    <FormInput
                      autoCapitalize="none"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                      label={t("signup.email")}
                      placeholder={t("signup.e_placeholder")}
                      type="email"
                      name="email"
                      id="email"
                      className={locale === "ar" ? "text-right" : "text-left"}
                      error={touched.email && errors.email}
                    />
                  </div>
                  <div className="single-input">
                    <FormInput
                      autoCapitalize="none"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.password1}
                      label={t("signup.password")}
                      placeholder={t("login.p_placeholder")}
                      type="password"
                      name="password1"
                      id="password1"
                      className={locale === "ar" ? "text-right" : "text-left"}
                      error={touched.password1 && errors.password1}
                    />
                  </div>
                  <div className="single-input">
                    <FormInput
                      autoCapitalize="none"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.password2}
                      label={t("signup.confirm_password")}
                      placeholder={t("signup.p_placeholder")}
                      type="password"
                      name="password2"
                      id="password2"
                      className={locale === "ar" ? "text-right" : "text-left"}
                      error={touched.password2 && errors.password2}
                    />
                  </div>
                  <div className={`mt-3 ${locale === "ar" ? "direction-rtl" : "direction-ltr"}`}>
                    <label htmlFor="normal">
                      {t("signup.normal_user")}
                      <input
                        type="radio"
                        name="type"
                        checked={values.type === "normal"}
                        id="normal"
                        value="normal"
                        onChange={handleChange}
                      />
                    </label>
                    <label htmlFor="store">
                      {t("signup.store")}
                      <input
                        type="radio"
                        name="type"
                        checked={values.type === "store"}
                        id="store"
                        value="store"
                        onChange={handleChange}
                      />
                    </label>
                  </div>
                  <div
                    className={`single-input submitForm d-flex align-items-center justify-flex-start ${
                      locale === "ar" ? "flex-row-reverse" : "flex-row"
                    }`}
                  >
                    <button
                      type="submit"
                      className="m-0"
                      title={t("signup.signup")}
                      name="signup"
                      id="signup"
                      disabled={isSubmitting}
                    >
                      {t("signup.signup")}
                    </button>
                    <p className="ml-3 mr-3 mb-0">
                      {t("signup.already_have_account")}
                      <span className="ml-3 mr-3">
                        <Link to={`/${locale}/user/login`}>{t("signup.enter")}</Link>
                      </span>
                    </p>
                  </div>
                </div>
              </form>
            )}
          </Formik>
        </div>
        <div className="auth__image">
          <img src={pageImage} alt="auth Image" />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  locale: state.locales,
});

export default compose(withTranslation(), connect(mapStateToProps))(UserSignupForm);
