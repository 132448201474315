import { 
    GET_CATEGORIES,
    REQUEST_CATEGORIES,
    REQUEST_CATEGORIES_SUCCESS,
    REQUEST_CATEGORIES_FAILURE
} from '../constants/action-types'

const defaultCategories = {
    data: [],
    isFetching: false,
    error: null
}

export default (state = defaultCategories, action) => {
    switch (action.type) {
        case GET_CATEGORIES:
            return {
                ...state,
                data: action.categories
            }
        case REQUEST_CATEGORIES:
            return {
                ...state,
                isFetching: true
            }
        case REQUEST_CATEGORIES_SUCCESS:
        case REQUEST_CATEGORIES_FAILURE:
            return {
                ...state,
                isFetching: false,
                error: action.message ? action.message : null
            }
        default:
            return state
    }
}