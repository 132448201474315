import API from '../api'
import { 
    SET_PLACES,
    FETCH_PLACES_REQUEST,
    FETCH_PLACES_SUCCESS,
    FETCH_PLACES_FAILURE 
} from '../constants/action-types'

export const setPlaces = (places) => ({
    type: SET_PLACES,
    places
})

export const startFetchPlaces = () => dispatch => {
    dispatch({ type: FETCH_PLACES_REQUEST })

    API.get('/store/places/').then(
        response => {
            dispatch(setPlaces(response.data));
            dispatch({ type: FETCH_PLACES_SUCCESS })
        },
        error => { 
            dispatch({
                type: FETCH_PLACES_FAILURE,
                message: error.response.data || 'something went wrong.'
            })
            // Ignore Error Message 
        })
}
