import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import { history } from "../routes/AppRouters";
import { sortBy } from "../actions/sort";
import { setLayoutView } from "../actions/layout_view";

export class ToolsBox extends React.Component {
  onSortChange = (e) => {
    this.props.sortBy(e.target.value);
  };

  render() {
    const { t, locale, sort, layoutView, setLayoutView, models_length, searchTerm } = this.props;
    return (
      <div className="tools-box ">
        <div className={`tools-box__calculations ${locale === "ar" ? "flex-row-reverse" : ""}`}>
          <span>{t("search.resultMessage")} </span>
          <span>{searchTerm}</span>
          <small>({models_length} results)</small>
        </div>
        <div className="tools-box__modes">
          <div className="tools-box__modes__sort">
            <label>Sort by</label>
            <select
              name="orderBy"
              className="form-control"
              value={sort}
              onChange={this.onSortChange}
            >
              <option value="">popular</option>
              <option value="price low to high">price: low to high</option>
              <option value="price high to low">price: high to low</option>
            </select>
          </div>
          <span className="box__modes__pipe"></span>
          <div className="tools-box__modes__list-view">
            <i
              className={`fa fa-th ${layoutView === "grid" ? "layout__active" : undefined}`}
              title="Grid"
              onClick={() => setLayoutView("grid")}
            ></i>
            <i
              className={`fa fa-list ${layoutView === "list" ? "layout__active" : undefined}`}
              title="List"
              onClick={() => setLayoutView("list")}
            ></i>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  sort: state.sortBy,
  layoutView: state.layoutView,
});

const mapDispatchToProps = (dispatch) => ({
  sortBy: (text) => dispatch(sortBy(text)),
  setLayoutView: (text) => dispatch(setLayoutView(text)),
});

export default compose(withTranslation(), connect(mapStateToProps, mapDispatchToProps))(ToolsBox);
