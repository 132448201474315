import API from '../api'
import {
    SET_STORE_PROFILE
} from '../constants/action-types'

const setStoreProfile = (values) => ({
    type: SET_STORE_PROFILE,
    values
})

export const startFetchStoreProfile = () => async dispatch => {
    try {
        const response = await API.get('users/store-profile/')
        dispatch(setStoreProfile(response.data))
    } catch (error) {
        const message = error.response
        throw new Error(message)
    }
}


export const startUpdateStoreProfile = (values = {}, type = '') => async dispatch => {
    try {
        const response = await API.put(`users/store-profile/?data=${type}`, values)
        dispatch(setStoreProfile(response.data));
        const auth = JSON.parse(localStorage.getItem('user'));
        const transformedUser = { ...auth, user: { ...auth.user, store_slug: response.data.slug } };
        localStorage.setItem('user', JSON.stringify(transformedUser));
    } catch (error) {
        const message = error.response
        throw new Error(message)
    }
}