import API from '../api'
import {
    GET_BRANDS
} from '../constants/action-types'


export const getBrands = (brands) => ({
    type: GET_BRANDS,
    brands
})

export const startGetBrands = () => async dispatch => {
    try {
        const response = await API.get('basic-data/brands/')
        dispatch(getBrands(response.data))
    } catch (err) {
        // Ignore Error Messages
    }
}