import React from 'react'
import { history } from '../routes/AppRouters'
import { connect } from 'react-redux'
import { Slider, Rail, Handles, Tracks } from 'react-compound-slider'
import { updateRangeSlider } from '../actions/category'

export function Handle({ // your handle component
    handle: { id, value, percent }, 
    getHandleProps
  }) {
    return (
      <div
        style={{
          left: `${percent}%`,
          position: 'absolute',
          marginLeft: -15,
          marginTop: 30,
          zIndex: 2,
          width: 20,
          height: 20,
          border: 0,
          textAlign: 'center',
          cursor: 'pointer',
          borderRadius: '50%',
          backgroundColor: '#0B88EE',
          color: '#333',
        }}
        {...getHandleProps(id)}
      >
        <div className="handle__value">
          {value}
        </div>
      </div>
    )
  }

  function Track({ source, target, getTrackProps }) { // your own track component
    return (
      <div
        style={{
          position: 'absolute',
          height: 10,
          zIndex: 1,
          marginTop: 35,
          backgroundColor: '#0B88EE',
          borderRadius: 5,
          cursor: 'pointer',
          left: `${source.percent}%`,
          width: `${target.percent - source.percent}%`,
        }}
        {...getTrackProps()} // this will set up events if you want it to be clickeable (optional)
      />
    )
  }

export class  WidgetListItemRange extends React.Component {

    state = {
        values: []
    }

    sliderValues = (values) => {
        this.setState({ values })
    }

    componentDidMount() {
      const prices = history.location.search;
      
      if(prices) {
        this.setState({ values: [Number(prices.split('=')[1].split('to')[0]), Number(prices.split('=')[1].split('to')[1])] }) 
      } else {
        this.setState({ values: [this.props.value.min, this.props.value.max] })
      }
    }

    onRangeSubmit = () => {
      this.props.updateRangeSlider(this.state.values);
    }

    render() {
        return (
            <li className="widget__item">
                {   
                    <React.Fragment>
                        <Slider
                            className="slider"
                            domain={[this.props.value.min, this.props.value.max]}
                            values={this.state.values}
                            step={10}
                            onChange={this.sliderValues}
                        >
                            <Rail>
                            {({ getRailProps }) => (  // adding the rail props sets up events on the rail
                                <div className="rail" {...getRailProps()} /> 
                            )}
                            </Rail>
                            <Handles>
                            {({ handles, getHandleProps }) => (
                                <div className="slider-handles">
                                {handles.map(handle => (
                                    <Handle
                                    key={handle.id}
                                    handle={handle}
                                    getHandleProps={getHandleProps}
                                    />
                                ))}
                                </div>
                            )}
                            </Handles>
                            <Tracks left={false} right={false}> 
                            {({ tracks, getTrackProps }) => (
                                <div className="slider-tracks">
                                  {tracks.map(({ id, source, target }) => (
                                      <Track
                                      key={id}
                                      source={source}
                                      target={target}
                                      getTrackProps={getTrackProps}
                                      />
                                  ))}
                                </div>
                            )}
                            </Tracks>
                            </Slider>
                            <div className="filter__price-action">
                              <span className="filter__price__text">
                                  EGP {this.state.values[0]} - EGP {this.state.values[1]}
                              </span>
                              <button className="btn btn-primary" title="filter" onClick={this.onRangeSubmit}>Filter</button>
                            </div>
                    </React.Fragment>
                }
            </li>
        )
    }
}

const mapDispatchToProps = (dispatch) => ({
    updateRangeSlider: (range) => dispatch(updateRangeSlider(range))
});

export default connect(undefined, mapDispatchToProps)(WidgetListItemRange)