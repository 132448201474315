import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import { setLayoutView } from "../actions/layout_view";
import { switchFilters, switchSortBy } from "../actions/category_actions";
import sort from "../images/sort.svg";

const CategoryMobileFilters = ({
  t,
  locale,
  layoutView,
  searchTerm,
  setLayoutView,
  switchFilters,
  switchSortBy,
  models_length,
}) => {
  return (
    <div className="category-mobile-actions">
      <div
        className={`category-mobile-actions__title ${locale === "ar" ? "flex-row-reverse" : ""}`}
      >
        <span>{t("search.resultMessage")} </span>
        <span>{searchTerm}</span>
        <small>({models_length} results)</small>
      </div>
      <div className="category-mobile-actions__options">
        <div className="category-mobile-actions__options__filters">
          <p onClick={() => switchFilters(true)}>
            filter <i className="fas fa-filter"></i>
          </p>
        </div>
        <div className="category-mobile-actions__options__sort">
          <p onClick={() => switchSortBy(true)}>
            sort <img src={sort} alt="sort" />
          </p>
        </div>
        <div className="category-mobile-actions__options__layoutView">
          {layoutView === "list" ? (
            <i className="fa fa-th" onClick={() => setLayoutView("grid")}></i>
          ) : (
            <i className="fa fa-list" onClick={() => setLayoutView("list")}></i>
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  sort: state.sortBy,
  layoutView: state.layoutView,
});

const mapDispatchToProps = (dispatch) => ({
  setLayoutView: (text) => dispatch(setLayoutView(text)),
  switchFilters: (value) => dispatch(switchFilters(value)),
  switchSortBy: (value) => dispatch(switchSortBy(value)),
});

export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
)(CategoryMobileFilters);
