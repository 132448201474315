import React, { useEffect } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import { startUserLogout } from "../actions/auth";
import { isNotificationsOpened, startFetchUserNotifications } from "../actions/notifications";
import NotificationContainer from "./NotificationContainer";
import { Link, withRouter } from "react-router-dom";
import SearchInput from "./SearchInput";
import { countriesData } from "../config";
//load dynamic logo
let selectedCountry = {};
let defaultLogo = "";
const fetchUrl = new URL(window.location.href);
if(fetchUrl.host.includes("eg.")){
  defaultLogo = require("../images/Egypt-logo.png");
  selectedCountry = countriesData.find(data => data.name_en == "Egypt");
}else if(fetchUrl.host.includes("sa.")){
  defaultLogo = require("../images/ksa-logo.png");
  selectedCountry = countriesData.find(data => data.name_en == "KSA");
}else if(fetchUrl.host.includes("ae.")){
  defaultLogo = require("../images/UAE-logo.png");
  selectedCountry = countriesData.find(data => data.name_en == "UAE");
}else if(fetchUrl.host.includes("kw.")){
  defaultLogo = require("../images/kuwait-logo.png");
  selectedCountry = countriesData.find(data => data.name_en == "Kuwait");
}else{
  defaultLogo = require("../images/Group 692.png");
}

const Header = ({
  location,
  match,
  locale,
  t,
  isLoggedIn,
  startUserLogout,
  user,
  isNotificationsClicked,
  isNotificationsOpened,
  startFetchUserNotifications,
}) => {
  return (
    <header className={`header ${location.pathname.includes("/user/") ? "d-block" : undefined}`}>
      <div id="wrapper">
        <div className={`header__main ${locale === "ar" ? "flex-row-reverse" : ""}`}>
          <div
            className={`header__main__logo ${
              locale === "ar" && location.pathname.includes("/user/")
                ? "text-right"
                : locale === "en" && location.pathname.includes("/user/")
                ? "text-left"
                : "text-center"
            }
                    `}
          >
            <Link to={`/${locale}/`}>
              <img src={defaultLogo} alt={"متدورش"} />
            </Link>
          </div>
          {location.pathname.includes("/user/") ? null : <SearchInput />}
          <div className={`header__main__user ${locale === "ar" ? "flex-row-reverse" : ""}`}>
            <div
              className="language"
              onClick={() => {
                const newLang = locale === "ar" ? "en" : "ar";
                document.location.pathname = `/${newLang}/${document.location.pathname
                  .replace(/^\//, "")
                  .split("/")
                  .splice(1)
                  .join("/")}`;
              }}
            >
              <span>{locale === "ar" ? "E" : "A"}</span>
              <p>{locale === "ar" ? "English" : "العربية"}</p>
            </div>
            <div
              className="notifications"
              hidden={!isLoggedIn}
              onClick={() => {
                isNotificationsOpened();
                startFetchUserNotifications();
              }}
            >
              <span
                className={`far fa-bell ${isNotificationsClicked ? "coloredBell" : undefined}`}
              ></span>
              {isNotificationsClicked && <NotificationContainer />}
            </div>
            <div
              className={`user ${locale === "ar" ? "direction-rtl" : ""}`}
              hidden={location.pathname.includes("/user/")}
            >
              {isLoggedIn ? (
                <React.Fragment>
                  <i className="far fa-user"></i>
                  <span>
                    <span style={{ margin: "0 8px" }}>{user.email}</span>
                    <i className="fa fa-caret-down"></i>
                  </span>
                  <ul className={`userOptions ${locale === "ar" ? "text-right" : "text-left"}`}>
                    {user.account_type === "store" && (
                      <Link to={`/account/user-information`}>
                        <li>
                          <i className="ml-1 mr-1 far fa-user"></i>
                          {t("header.account_info")}
                        </li>
                      </Link>
                    )}
                    {user.account_type === "branch" && (
                      <Link to={`/account/store/${user.store_slug}/branch/${user.branch_pk}`}>
                        <li>
                          <i className="ml-1 mr-1 far fa-user"></i>
                          {t("header.account_info")}
                        </li>
                      </Link>
                    )}
                    {user.account_type === "normal" && (
                      <Link to={`/${locale}/profile`}>
                        <li>
                          <i className="ml-1 mr-1 far fa-user"></i>
                          {t("header.my_account")}
                        </li>
                      </Link>
                    )}
                    <Link to={`/${locale}/favorites`}>
                      <li>
                        <i className="ml-1 mr-1 far fa-heart"></i>
                        {t("header.favorites")}
                      </li>
                    </Link>
                    <li className="text-center" onClick={() => startUserLogout()}>
                      {t("header.logout")}
                    </li>
                  </ul>
                </React.Fragment>
              ) : (
                <Link to={`/${locale}/user/login`}>
                  <i className="far fa-user"></i>
                  <span>{t("header.login")}</span>
                </Link>
              )}
            </div>
            <div className={`dropdown ${locale === "ar" ? "direction-rtl" : ""}`} hidden={location.pathname.includes("/user/")}>
                <button className="btn dropdown-toggle country-item" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <img src={selectedCountry.image} width="31" height="20" />
                    {selectedCountry["name_" + locale]}
                </button>
                <div className="dropdown-menu dopdown-menu-custom-width" aria-labelledby="dropdownMenuButton">
                  {countriesData.map(eachData =>
                    (<a className={`dropdown-item country-item ${eachData.name_en == selectedCountry.name_en ? "active":""}`} href={eachData.url} key={eachData.name_en}>
                      <img src={eachData.image} width="31" height="20" />
                      {eachData["name_" + locale]}
                    </a>)
                  )}
                </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

const mapStateToProps = (state) => ({
  locale: state.locales,
  isLoggedIn: state.auth.isLoggedIn,
  user: state.auth.user,
  isNotificationsClicked: state.notifications.isOpened,
});

const mapDispatchToProps = (dispatch) => ({
  startUserLogout: () => dispatch(startUserLogout()),
  isNotificationsOpened: () => dispatch(isNotificationsOpened()),
  startFetchUserNotifications: () => dispatch(startFetchUserNotifications()),
});

export default compose(
  withRouter,
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
)(Header);
