import React from "react";
import { connect } from "react-redux";

import Spinner from "../components/Spinner";
import NoResults from "./NoResults";
import Image from "./genericComponents/Image";
import classnames from 'classnames'
import { Link } from 'react-router-dom'
import { startGetStorePublicBranch } from "../actions/branches";
import { createVisitBranchReport, createVisitSocialReport } from "../actions/reports";
import { FacebookShareButton, FacebookIcon, WhatsappShareButton, WhatsappIcon, TwitterShareButton, TwitterIcon } from "react-share";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";

import defaultImage from "../images/default.jpg";
import HelmetMetaData from "./HelmetMetaData";

import city_delivery from "../images/city_delivery.png";
import delivery from "../images/delivery.png";
import installment from "../images/installment.png";

const fetchUrl = new URL(window.location.href);

export class StorePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isFetching: false,
      branch: null,
      selectedImage: null
    };
    this.myRef = React.createRef()  
  }

  selectImageHandler(imagePosition) {
    this.setState({ selectedImage: [...this.state.branch.images].find((image, index) => imagePosition === index).store_branch_image })
  }

  async componentDidMount() {
    this.setState({ isFetching: true });
    //get branch info
    try {
      const response = await this.props.startGetStorePublicBranch(
        this.props.match.params.store_slug
      );
      this.setState({ branch: response.data });
    } catch (err) {
      // Ignore Error Message
    }
    //get data from user device
    const reportData = {
      storeId: this.state.branch.pk,
      isMobile: isMobile.any,
      isComingFromSocial: document.referrer ? document.referrer : null,
      comesFromSection: this.props.location.state && 
                        this.props.location.state.comesFromSection ? this.props.location.state.comesFromSection : null,
      productId: this.props.location.state && 
                  this.props.location.state.productId ? this.props.location.state.productId : null,
    }
    //send report to backend
    try{
      await this.props.createVisitBranchReport(reportData);
    }catch(error){
      //avoid error if report fails
    }
    this.setState({ isFetching: false });
  }

  executeScroll = () => this.myRef.current.scrollIntoView({ behavior: 'smooth' });

  comparePrices = (itemId) => this.props.history.push(`/${this.props.locale}/model/${itemId}`);

  async postSocialContactReport({isWhatsapp = false, isMessenger = false, isPhone = false}){
    try{
      await this.props.createVisitSocialReport({
        storeId: this.state.branch.pk,
        isWhatsapp,
        isMessenger,
        isPhone,
      });
    }catch(error){
      //
    }
  }
  
  GroupItemsByCategory() {
      const categories = {};
      this.state.branch.items.map(item => {
        if(categories[this.props.locale === 'en' ? item.category.name : item.category.name_ar]) {
          categories[this.props.locale === 'en' ? item.category.name : item.category.name_ar].push(item)
        } else {
          categories[this.props.locale === 'en' ? item.category.name : item.category.name_ar] = [item]
        }
      })

      return Object.keys(categories).map(category =>
        <div className="featuresGroup" key={category}>
          {window.innerWidth > 800 ? (
            <div className={`featuresGroup__heading ${this.props.locale === 'en' ? 'flex-row-reverse' : ''}`}>
              <span></span>
              <Link to={`/${this.props.locale}/category/${categories[category][0].category.slug}`}>
                <h3>{category}</h3>
              </Link>
            </div>
          ) : (
            <div className={`featuresGroup__heading ${this.props.locale === 'en' ? 'text-left' : 'direction-rtl'}`}>
              <span></span>
              <Link to={`/${this.props.locale}/category/${categories[category][0].category.slug}`}>
                <h3>{category}</h3>
              </Link>
            </div>
          )}
          {window.innerWidth > 800 ? (
            <div className={`featuresGroup__slider ${this.props.locale === 'en' ? 'direction-ltr' : 'direction-rtl'}`}>
              <Slider {...{
                dots: true,
                arrows: false,
                infinite: false,
                autoplay: false,
                draggable: true,
                speed: 500,
                pauseOnHover: true,
                slidesToShow: 6, // condition logic
                slidesToScroll: 6,
                initialSlide: 0,
                responsive: [
                  {
                    breakpoint: 1024,
                    settings: {
                      slidesToShow: 5,
                      slidesToScroll: 5,
                      infinite: true,
                      dots: false,
                    },
                  },
                  {
                    breakpoint: 900,
                    settings: {
                      dots: true,
                      slidesToShow: 4,
                      slidesToScroll: 4,
                    },
                  },
                  {
                    breakpoint: 800,
                    settings: {
                      dots: true,
                      slidesToShow: 3,
                      slidesToScroll: 3,
                    },
                  },
                  {
                    breakpoint: 600,
                    settings: {
                      dots: false,
                      slidesToShow: 2,
                      slidesToScroll: 2,
                    },
                  },
                  {
                    breakpoint: 500,
                    settings: {
                      dots: false,
                      slidesToShow: 2,
                      slidesToScroll: 2,
                    },
                  },
                  {
                    breakpoint: 320,
                    settings: {
                      dots: false,
                      slidesToShow: 1,
                      slidesToScroll: 1,
                    },
                  },
                ],
              }}>
                {categories[category].map(item =>
                  <div className="subModel w-auto" key={item.pk}>
                    <div className="subModel__avatar">
                      <img src={item.sub_model_image || defaultImage} alt={this.props.locale === 'en' ? item.name : item.sub_model_arabic_name} />
                    </div>
                    <div className="subModel__details">
                      <Link to={`/${this.props.locale}/model/${item.sub_model_slug}`} style={{ color: '#7e7e7e' }}>
                        <h2 className={`subModel__details__title mb-2 ${this.props.locale === 'en' ? 'text-left' : 'text-right'}`} title={this.props.locale === 'en' ? item.name : item.sub_model_arabic_name}>{this.props.locale === 'en' ? item.name : item.sub_model_arabic_name}</h2>
                      </Link>
                      <div className={this.props.locale === 'en' ? 'text-left' : 'text-right'}>
                        <p className="subModel__details__price d-block">
                          <span className="value">{item.price}</span>
                          <span className="currency">{this.props.locale === 'en' ? 'EGP' : 'جنيه'}</span>
                        </p>
                      </div>
                      <div className="subModel__details__actions row m-1 justify-content-between align-items-center text-center">
                        <span className="subModel__details__actions__btn badge badge-primary py-2" onClick={() => this.comparePrices(item.sub_model_slug)}>
                          {this.props.locale == 'en' ? 'Compare' : 'قارن'}
                        </span>
                        <span className="subModel__details__actions__btn badge badge-warning py-2" onClick={this.executeScroll}>
                          {this.props.locale == 'en' ? 'Buy' : 'اشتري'}
                        </span>
                      </div>
                    </div>
                  </div>
                )}
              </Slider>
            </div>
          ) : (
            <div className={`overflow-wrapper ${this.props.locale === 'ar' ? 'text-right' : ''}`}>
              <div className={`featuresGroup__slider featuresGroup__slider--mobile d-inline-flex justify-content-center`}>
                {categories[category].map(item =>
                  <div className="subModel combo" key={item.pk}>
                    <div className="subModel__avatar">
                      <img src={item.sub_model_image || defaultImage} alt={this.props.locale === 'en' ? item.name : item.sub_model_arabic_name} />
                    </div>
                    <div className="subModel__details">
                      <Link to={`/${this.props.locale}/model/${item.sub_model_slug}`} style={{ color: '#7e7e7e' }}>
                        <h2 className={`subModel__details__title mb-2 ${this.props.locale === 'en' ? 'text-left' : 'text-right'}`} title={this.props.locale === 'en' ? item.name : item.sub_model_arabic_name}>{this.props.locale === 'en' ? item.name : item.sub_model_arabic_name}</h2>
                      </Link>
                      <div className={this.props.locale === 'en' ? 'text-left' : 'text-right'}>
                        <p className="subModel__details__price d-block">
                          <span className="value">{item.price}</span>
                          <span className="currency">{this.props.locale === 'en' ? 'EGP' : 'جنيه'}</span>
                        </p>
                      </div>
                      <div className="d-flex flex-column m-1 justify-content-center align-items-center text-center">
                        <span className="badge badge-primary py-2 w-100" onClick={() => this.comparePrices(item.sub_model_slug)}>
                          {this.props.locale == 'en' ? 'Compare' : 'قارن'}
                        </span>
                        <span className="badge badge-warning py-2 w-100 mt-1" onClick={this.executeScroll}>
                          {this.props.locale == 'en' ? 'Buy' : 'اشتري'}
                        </span>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      )
    }

  render() {
    if (this.state.isFetching || !this.state.branch) return <Spinner />;
    const shareUrl = `${fetchUrl.protocol}//${fetchUrl.host}${this.props.location.pathname}`;

    return (
      <div className="store p-3">
        <HelmetMetaData
          title={this.state.branch.name_arabic}
          description={this.state.branch.work_domain.name}
          image={this.state.branch.store_logo}
        ></HelmetMetaData>
        <div className={`row ${this.props.locale === 'en' ? 'direction-ltr text-left' : 'direction-rtl text-right'}`} style={style.storeBorder} ref={this.myRef}>
          <div className={`store-details flex-2 px-5 order-2 ${this.props.locale === 'en' ? 'direction-ltr' : 'direction-rtl'}`}>
            <h1
              style={{
                fontSize: 20,
                margin: 0,
                padding: "10px 0",
                color: "#707070",
                fontWeight: "bold",
              }}
            >
              {this.props.locale === 'en' ? this.state.branch.name_english : this.state.branch.name_arabic}
            </h1>
            <p style={{ fontSize: 16, color: "#707070", margin: 0, padding: "10px 0" }}>
              {this.state.branch.work_domain.name}
            </p>
            {window.innerWidth < 800 && (
              <div className="gallery-images gallery-images--smaller order-1"               
              style={{
                borderTop: "solid 1px #d5d5d5",
                paddingTop: 20,
                marginBottom: 10
              }}>
                <div className="gallery-images__selected">
                  <img src={this.state.selectedImage ? this.state.selectedImage : this.state.branch.store_logo} />
                </div>
                <div className="images d-flex justify-content-start align-items-center flex-wrap my-3">
                  {this.state.branch.images.map((image, index) =>
                    <div key={index}
                      onClick={() => this.selectImageHandler(index)}
                      className={classnames('model-images__slider-wrapper__thumbs__thumb', { 'selected': image.store_branch_image === this.state.selectedImage })}>
                      <img src={image.store_branch_image} />
                    </div>
                  )}
                </div>
                <div className="share text-center">
                  <p style={{ margin: 0, color: "#0b88ee", fontSize: 14 }}>
                    {this.props.locale === 'en' ? 'Share Store' : 'شير المتجر'}  
                    <i className="fas fa-share mx-2 text-secondary"></i>
                  </p>
                  <div className="d-flex justify-content-center mt-2">
                    <FacebookShareButton
                      url={shareUrl}
                      quote={this.state.branch.name_arabic}
                      hashtag={"متدورش"}
                    >
                      <FacebookIcon size={25} round={true} />
                    </FacebookShareButton>
                    <TwitterShareButton
                      url={shareUrl}
                      title={this.state.branch.name_arabic}
                      hashtags={["متدورش"]}
                      className="mx-2"
                    >
                      <TwitterIcon size={25} round={true} />
                    </TwitterShareButton>
                    <WhatsappShareButton
                      url={shareUrl}
                      title={this.state.branch.name_arabic}
                      hashtag={"متدورش"}
                    >
                      <WhatsappIcon size={25} round={true} />
                    </WhatsappShareButton>
                  </div>
                </div>
              </div>
            )}
            <div style={{ borderTop: "solid 1px #d5d5d5" }}></div>
            <p style={{ color: "#707070", fontSize: 12, fontWeight: 'bold', margin: 0 }}>
              {this.props.locale === 'en' ? 'Call Us' : 'للشراء اتصل بنا'}
            </p>
            <div
              className={`row justify-content-between ${window.innerWidth > 800 ? 'd-flex' : ''}`}
              style={{
                margin: "5px 0"
              }}
            >
              <div className={window.innerWidth < 800 ? 'col-6' : ''} style={{ padding: "5px 0" }}>
                <p style={{ margin: 0, color: "#0b88ee", fontSize: 14 }}>
                  {this.props.locale === 'en' ? 'Land Line' : 'تليفون ارضى'}
                </p>
                <p onClick={() => this.postSocialContactReport({ isPhone:true })} style={{ textDecoration: "underline"}}><a href={"tel:" + this.state.branch.land_line_number} style={{ margin: 0, fontWeight: "bold", color: "#707070", fontSize: 14 }}>
                  {this.state.branch.land_line_number}
                </a></p>
              </div>
              <div className={window.innerWidth < 800 ? 'col-6' : ''} style={{ padding: "5px 0" }}>
                <p style={{ margin: 0, color: "#0b88ee", fontSize: 14 }}>
                  {this.props.locale === 'en' ? 'Mobile Number' : 'موبايل'}
                </p>
                <p onClick={() => this.postSocialContactReport({ isPhone:true })} style={{ textDecoration: "underline"}}><a href={"tel:" + this.state.branch.mobile_number} style={{ margin: 0, fontWeight: "bold", color: "#707070", fontSize: 14 }}>
                  {this.state.branch.mobile_number}
                </a></p>
              </div>
              <div className={window.innerWidth < 800 ? 'col-12' : ''} style={{ padding: "5px 0" }}>
                <div className="row">
                  {this.state.branch.whatsapp && this.state.branch.whatsapp.length === 11 && (
                    <div className={window.innerWidth < 800 ? this.props.locale === 'en' ? 'text-left col-6' : 'text-right col-6' : 'ml-2 text-center'}>
                      <p style={{ margin: 0, color: "#0b88ee", fontSize: 12 }}>
                        {this.props.locale === 'en' ? 'Whatsapp' : 'واتس اب'}
                      </p>
                      <a onClick={() => this.postSocialContactReport({ isWhatsapp:true })} href={`https://wa.me/+2${this.state.branch.whatsapp}`} target="_blank">
                        <i className="fab fa-whatsapp"></i>
                      </a>
                    </div>
                  )}
                  {this.state.branch.messenger_link && (
                    <div className={window.innerWidth < 800 ? this.props.locale === 'en' ? 'text-left' : 'text-right' : 'text-center'}>
                      <p style={{ margin: 0, color: "#0b88ee", fontSize: 12 }}>
                        {this.props.locale === 'en' ? 'Messenger' :  'ماسنجر'}
                      </p>
                      <a onClick={() => this.postSocialContactReport({ isMessenger:true })} href={this.state.branch.messenger_link} target="_blank">
                        <i className="fab fa-facebook-messenger"></i>
                      </a>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div style={{ borderTop: "solid 1px #d5d5d5" }}></div>
            <div className="store-bottom">
              <div className="flex-column flex-lg-row align-items-center justify-content-lg-between d-flex" style={{ padding: "5px 0", gap:"16px" }}>
                <div>
                  <p style={{ color: "#707070", fontSize: 12, fontWeight: 'bold', margin: 0 }}>
                    {this.props.locale === 'en' ? 'Social media' : ' تابعنا ع السوشيال ميديا'}
                  </p>
                  <div 
                    className="d-flex"               
                    style={{
                      margin: "5px 0"
                    }}
                  >
                    {this.state.branch.store_facebook_page && (
                      <a href={this.state.branch.store_facebook_page} target="_blank" className="social-pages">
                        <i className="fab fa-facebook"></i>
                      </a>
                    )}
                    {this.state.branch.store_twitter_page && (
                      <a href={this.state.branch.store_twitter_page} target="_blank" className="social-pages">
                        <i className="fab fa-twitter"></i>
                      </a>
                    )}
                    {this.state.branch.store_youtube_page && (
                      <a href={this.state.branch.store_youtube_page} target="_blank" className="social-pages">
                        <i className="fab fa-youtube"></i>
                      </a>
                    )}
                    {this.state.branch.store_instagram_page && (
                      <a href={this.state.branch.store_instagram_page} target="_blank" className="social-pages">
                        <i className="fab fa-instagram"></i>
                      </a>
                    )}
                  </div>
                </div>
                <div className="store-options">
                  <div className="store-options__flex">
                    {this.state.branch.installment && (<div className="store-option">
                      <img src={installment}/>
                      <span>{this.props.locale == "ar" ? "بيع تقسيط" : "Installment"}</span>
                    </div>)}
                    {this.state.branch.city_delivery && (<div className="store-option">
                      <img src={city_delivery}/>
                      <span>{this.props.locale == "ar" ? "شحن محافظات" : "Shipment to cities"}</span>
                    </div>)}
                    {this.state.branch.delivery && (<div className="store-option">
                      <img src={delivery}/>
                      <span>{this.props.locale == "ar" ? "يوجد توصيل" : "Delivery"}</span>
                    </div>)}
                  </div>
                </div>
              </div>
            </div>
            <div style={{ borderTop: "solid 1px #d5d5d5" }}></div>
            <div className="store-bottom">
              <div className="address">
                <p style={{ margin: 0, color: "#0b88ee", fontSize: 14 }}>
                  {this.props.locale === 'en' ? 'Address' : 'العنوان'}
                </p>
                <p
                  style={{
                    margin: 0, fontWeight: "bold", color: "#707070", fontSize: 14 
                  }}
                >
                  {this.state.branch.address}
                </p>
                {/* Hyperlink city and region */}
                <div style={{marginTop:"8px"}}>
                  <p style={{"cursor":"pointer", textDecoration: "underline", marginBottom: "0"}} 
                    onClick={() => this.props.history.push(`/${this.props.locale}/stores/${this.props.locale == "ar" || !this.state.branch.government_name_en ? this.state.branch.government_name : this.state.branch.government_name_en}`)}>
                      {this.props.locale == "ar" ? "محافظة" : "Governate"} - {this.props.locale == "ar" || !this.state.branch.government_name_en ? this.state.branch.government_name : this.state.branch.government_name_en}
                  </p>
                  <p style={{"cursor":"pointer", textDecoration: "underline", marginTop:"8px"}} 
                    onClick={() => this.props.history.push(`/${this.props.locale}/stores/${this.props.locale == "ar" || !this.state.branch.government_name_en ? this.state.branch.government_name : this.state.branch.government_name_en}/${this.props.locale == "ar" || !this.state.branch.neighbourhood.name_en ? this.state.branch.neighbourhood.name : this.state.branch.neighbourhood.name_en}`)}>
                      {this.props.locale == "ar" ? "منطقة" : "Region"} - {this.props.locale == "ar" || !this.state.branch.neighbourhood.name_en ? this.state.branch.neighbourhood.name : this.state.branch.neighbourhood.name_en}
                  </p>
                </div>
              </div>
            </div>
          </div>

          {window.innerWidth > 800 && (
            <div className="gallery-images gallery-images--smaller order-1">
              <div className="gallery-images__selected">
                <img src={this.state.selectedImage ? this.state.selectedImage : this.state.branch.store_logo} />
              </div>
              <div className="images d-flex justify-content-start align-items-center flex-wrap my-3">
                {this.state.branch.images.map((image, index) =>
                  <div key={index}
                    onClick={() => this.selectImageHandler(index)}
                    className={classnames('model-images__slider-wrapper__thumbs__thumb', { 'selected': image.store_branch_image === this.state.selectedImage })}>
                    <img src={image.store_branch_image} />
                  </div>
                )}
              </div>
              <div className="share text-center">
                <p style={{ margin: 0, color: "#0b88ee", fontSize: 14 }}>
                  {this.props.locale === 'en' ? 'Share Store' : 'شير المتجر'}  
                  <i className="fas fa-share mx-2 text-secondary"></i>
                </p>
                <div className="d-flex justify-content-center mt-2">
                  <FacebookShareButton
                    url={shareUrl}
                    quote={this.state.branch.name_arabic}
                    hashtag={"متدورش"}
                  >
                    <FacebookIcon size={25} round={true} />
                  </FacebookShareButton>
                  <TwitterShareButton
                    url={shareUrl}
                    title={this.state.branch.name_arabic}
                    hashtags={["متدورش"]}
                    className="mx-2"
                  >
                    <TwitterIcon size={25} round={true} />
                  </TwitterShareButton>
                  <WhatsappShareButton
                    url={shareUrl}
                    title={this.state.branch.name_arabic}
                    hashtag={"متدورش"}
                  >
                    <WhatsappIcon size={25} round={true} />
                  </WhatsappShareButton>
                </div>
              </div>
            </div>
          )}
        </div>
        {this.state.branch.items.length ? this.GroupItemsByCategory() : <NoResults message="لا توجد منتجات لهذا المتجر" featuredModels={[]} />}
      </div>
    );
  }
}

const style = {
  storeBorder: {
    padding: "20px",
    borderRadius: 10,
    boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)",
    border: "solid 1px #d5d5d5",
    backgroundColor: "#ffffff",
  },
};

const mapStateToProps = (state) => ({
  locale: state.locales,
});

const mapDispatchToProps = (dispatch) => ({
  startGetStorePublicBranch: (slug) => dispatch(startGetStorePublicBranch(slug)),
  createVisitBranchReport: ({storeId,productId,isMobile,isComingFromSocial}) => dispatch(createVisitBranchReport({storeId,productId,isMobile,isComingFromSocial})),
  createVisitSocialReport: ({storeId,isWhatsapp,isMessenger,isPhone}) => dispatch(createVisitSocialReport({storeId,isWhatsapp,isMessenger,isPhone})),
});

export default connect(mapStateToProps, mapDispatchToProps)(StorePage);
