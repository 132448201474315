import React from "react";

const Avatar = (props) => {
  const { onFileChangeHandler, hidden, ...rest } = props;
  return (
    <div className="Avatar-wrapper mt-4">
      <div className="avatar m-auto">
        <img {...rest} alt="image" />
      </div>
      <label className="file-select" hidden={hidden}>
        <span>
          رفع صورة
          <i className="fas fa-camera"></i>
        </span>
        <input type="file" className="fileUpload" accept="image/*" onChange={onFileChangeHandler} />
      </label>
    </div>
  );
};

export default Avatar;
