import { IS_PLACES_VISITED } from '../constants/action-types';

const isPlacesVisited = false;

export default (state = isPlacesVisited, action) => {
    switch(action.type) {
        case IS_PLACES_VISITED:
            return action.isPlacesVisited;
        default:
            return state;
    }
};