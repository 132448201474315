import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { Formik } from 'formik'
import NotFound from './NotFound'
import * as Yup from 'yup'
import API from '../api';
const axios = require('axios');

import { startFetchStoreProfile, startUpdateStoreProfile } from '../actions/profile'
import { startFetchPlaces } from '../actions/places'

import picture from '../images/picture.svg'
import Avatar from './genericComponents/Avatar'
import FormInput from './genericComponents/FormInput'
import FormSelectOption from './genericComponents/FormSelectOption'
import ShadowCard from './genericComponents/ShadowCard'
import Switches from './genericComponents/Switches'
import SupportInfo from './genericComponents/SupportInfo'
import NoResults from '../components/NoResults'
import Spinner from './Spinner'

import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie, Doughnut } from 'react-chartjs-2';

const chartZero = {
  id: "show-zero",
  beforeDraw: (chart, args, options) => {
    const data = chart.data.datasets[0].data;
    if(data.length > 0){
      var isAllZero = data.reduce((a, b) => a + b) > 0 ? false : true;
      if (isAllZero > 1) return;
    }
    chart.tooltip.handleEvent = () => {return null};
  },
  beforeInit: function(chart, args, options) {
    const data = chart.data.datasets[0].data;
    if(data.length > 0){
      var isAllZero = data.reduce((a, b) => a + b) > 0 ? false : true;
      if (!isAllZero) return;
    }
    // when all data values are zero...
    chart.data.datasets[0].data = data.map((e, i) => i > 0 ? 0 : 1); //add one segment
    chart.data.datasets[0].backgroundColor = '#d2dee2'; //change bg color
    chart.data.datasets[0].borderWidth = 0; //no border
    chart.legend.handleEvent = () => {return null};
  }
}

ChartJS.register(ArcElement, Tooltip, Legend,chartZero);

export class AccountStoreReportPage extends Component {
  backgroundColors = [
    'rgba(255, 99, 132, ?)',
    'rgba(54, 162, 235, ?)',
    'rgba(255, 206, 86, ?)',
    'rgba(75, 192, 192, ?)',
    "rgba(121, 158, 68, ?)",
    'rgba(255, 159, 64, ?)',
    "rgba(77, 68, 158, ?)",
    'rgba(153, 102, 255, ?)',
    "rgba(54, 162, 235, ?)",
    "rgba(158, 68, 133, ?)",
    "rgba(158, 68, 78, ?)",
    "rgba(68, 145, 158, ?)",
    "rgba(68, 158, 119, ?)",
    "rgba(158, 155, 68, ?)",
    "rgba(158, 118, 68, ?)",
    "rgba(158, 96, 68, ?)",
  ]
  state = {
      branch_id: 0,
      disabled: true,
      avatar: null,
      isFetching: false,
      dateRange: {
        startDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
        endDate: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0),
      },
      visits:{},
      visitsTotal:0,
      visitClicks:{},
      visitsClicksTotal:0,
      topProducts:[],
      visitsChartData: {
        labels: [],
        datasets: [
          {
            label: '# of Counts',
            data: [],
            backgroundColor: [],
            borderColor: [],
            borderWidth: 1,
          },
        ],
      },
      visitsClicksChartData: {
        labels: [],
        datasets: [
          {
            label: '# of Counts',
            data: [],
            backgroundColor: [],
            borderColor: [],
            borderWidth: 1,
          },
        ],
      },
  }

  async componentDidMount() {
    /*this.setState({ isFetching: true })
    try {
      await this.props.startFetchStoreProfile()
      await this.props.startFetchPlaces()
      this.setState({ isFetching: false })
    } catch (error) {
      // fetch log error
      this.setState({ isFetching: false })
    }*/
    this.setState({branch_id: this.props.match.params.branch_id});
    await this.getData();
  }

  getRandomColor() {
      const letters = '0123456789ABCDEF'.split('');//random number
      let color = '#33';
      for (var i = 0; i < 6; i++ ) {
          color += letters[Math.floor(Math.random() * 16)];//random color
      }
      return color;
  }

  async getData(){
    this.setState({
      isFetching: true,
    });
    try{
      //get visits
      /*const visitsData = await new Promise((resolve,reject) => {
        setTimeout(() => {
          resolve({
            facebook: 90,
            twitter: 20,
            google: 100,
            direct: 10,
            lol: 11,
            kako: 40,
            sas: 33
          })
        },1000)
      });
      const visitsClicksData = await new Promise((resolve,reject) => {
        setTimeout(() => {
          resolve({
            whatsapp: 90,
            messenger: 20,
            phone: 100,
          })
        },1000)
      });
      //get products
      const productsData = await new Promise((resolve,reject) => {
        setTimeout(() => {
          resolve([
            {
              pk: 90,
              slug: "test",
              name_en: "شريحتين دراك كروم 4 جيجابايت رام 64 جيجابايت 4 جي إل تي إي 3L 2020 5029D",
              name_ar: "شريحتين دراك كروم 4 جيجابايت رام 64 جيجابايت 4 جي إل تي إي 3L 2020 5029D",
              image_url: "https://picsum.photos/200/300",
            },
            {
              pk: 91,
              slug: "test2",
              name_en: "هواوي ميت 20 برو بشريحتي اتصال - 128 جيجا ، 6 جيجا رام ، الجيل الرابع ال تي اي ، اخضر زمردي",
              name_ar: "هواوي ميت 20 برو بشريحتي اتصال - 128 جيجا ، 6 جيجا رام ، الجيل الرابع ال تي اي ، اخضر زمردي",
              image_url: "https://picsum.photos/200/300",
              click_count: 32,
            },
            {
              pk: 92,
              slug: "microsoft-xbox-360-kinect-250-gb-black",
              name_en: "مايكروسوفت اكس بوكس 360 كينيكت 250 جيجا اسود",
              name_ar: "مايكروسوفت اكس بوكس 360 كينيكت 250 جيجا اسود",
              image_url: "https://picsum.photos/200/300",
              click_count: 2,
            },
          ])
        },1000)
      });*/
      const branch_id = this.props.match.params.branch_id;
      const startDate = new Date(this.state.dateRange.startDate).toISOString().split("T")[0];
      const endDate = new Date(this.state.dateRange.endDate).toISOString().split("T")[0];
      //get actual data
      const fetchVisitsData = await API.get(`/visit-tracker/visits-count/${branch_id}/?from=${startDate}&to=${endDate}`);
      const visitsData = fetchVisitsData.data;
      const fetchVisitsClicksData = await API.get(`/visit-tracker/contacts-count/${branch_id}/?from=${startDate}&to=${endDate}`);
      const visitsClicksData = fetchVisitsClicksData.data;
      const fetchProductsData = await API.get(`/visit-tracker/products/${branch_id}/?from=${startDate}&to=${endDate}`);
      const productsData = fetchProductsData.data;
      //set data
      this.setState({
        isFetching: false,
        visits: visitsData,
        visitsTotal: Object.values(visitsData).length > 0 ? Object.values(visitsData).reduce((x,y) => x + y) : 0,
        visitClicks: visitsClicksData,
        visitsClicksTotal: Object.values(visitsClicksData).length > 0 ? Object.values(visitsClicksData).reduce((x,y) => x + y) : 0,
        topProducts: productsData,
        visitsChartData:{
          labels: Object.keys(visitsData).map(data => (data.charAt(0).toUpperCase() + data.slice(1))),
          datasets: [
            {
              label: '# of Votes',
              data: Object.values(visitsData),
              backgroundColor: this.backgroundColors.map(data => data.replace("?","0.2")),
              borderColor: this.backgroundColors.map(data => data.replace("?","1")),
              borderWidth: 1,
            },
          ],
        },
        visitsClicksChartData:{
          labels: Object.keys(visitsClicksData).map(data => (data.charAt(0).toUpperCase() + data.slice(1))),
          datasets: [
            {
              label: '# of Votes',
              data: Object.values(visitsClicksData),
              backgroundColor: this.backgroundColors.map(data => data.replace("?","0.2")),
              borderColor: this.backgroundColors.map(data => data.replace("?","1")),
              borderWidth: 1,
            },
          ],
        },
      })
    }catch(error){
      console.log(error.message);
    }
  }

  changeFormStateHandler = () => {
    this.setState({ disabled: !this.state.disabled })
  }

  async setDateRange(value, isStartDate = true){
    if(isStartDate){
      this.setState({
        dateRange:{
          startDate: value,
          endDate: this.state.dateRange.endDate,
        },
      })
    }else{
      this.setState({
        dateRange:{
          startDate: this.state.dateRange.startDate,
          endDate: value,
        },
      })
    }
  }

  render() {
    if (this.props.profile && !this.props.profile.vendor_name) {
      return <NotFound />;
    }
      return (
        <React.Fragment>
          <SupportInfo />
            <ShadowCard>
              <Switches />
              <div className="my-account__actions">
                  <Link to={'/account/store/reports'}><i className="fas fa-arrow-left"></i></Link>
              </div>
              {this.state.isFetching ? <Spinner /> : (
                  <div className="report-container" dir="rtl">
                    <div className="report-filter">
                      <div className="report-datepicker">
                        <DatePicker
                          closeOnScroll={true}
                          selected={this.state.dateRange.startDate}
                          onChange={(date) => this.setDateRange(date, true)}
                          selectsStart
                          startDate={this.state.dateRange.startDate}
                          endDate={this.state.dateRange.endDate}
                          monthsShown={2}
                        />
                        <DatePicker
                          closeOnScroll={true}
                          selected={this.state.dateRange.endDate}
                          onChange={(date) => this.setDateRange(date, false)}
                          selectsEnd
                          startDate={this.state.dateRange.startDate}
                          endDate={this.state.dateRange.endDate}
                          monthsShown={2}
                        />
                      </div>
                      <button type="button" className="btn btn-primary" onClick={() => this.getData()}>تصفية</button>
                    </div>
                    <div className='row'>
                      <div className='col-xs-12 col-md-6'>
                        <div className="card report-card">
                          <div className="card-header">
                            الإحصائيات
                          </div>
                          <div className="card-body">
                            <h6>عدد الزيارات : {this.state.visitsTotal}</h6>
                            {Object.entries(this.state.visits).map((data) =>
                            <div>
                              <span key={data[0]}> {(data[0].charAt(0).toUpperCase() + data[0].slice(1))} &#x200F;: {data[1]}</span>
                            </div>
                            )}
                            <hr></hr>
                            <h6>عدد التواصل : {this.state.visitsClicksTotal}</h6>
                            {Object.entries(this.state.visitClicks).map((data) =>
                              <div>
                                <span key={data[0]}> {(data[0].charAt(0).toUpperCase() + data[0].slice(1))} &#x200F;: {data[1]}</span>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className='col-xs-12 col-md-6'>
                        <div className="card report-card">
                          <div className="card-header">
                            عدد زيارات الموقع
                          </div>
                          <div className="card-body">
                            <Doughnut data={this.state.visitsChartData} />
                          </div>
                        </div>
                      </div>
                      <div className='col-xs-12 col-md-6'>
                        <div className="card report-card">
                          <div className="card-header">
                            التواصل
                          </div>
                          <div className="card-body">
                            <Doughnut data={this.state.visitsClicksChartData} />
                          </div>
                        </div>
                      </div>
                      <div className='col-xs-12 col-md-6'>
                        <div className="card report-card">
                          <div className="card-header">
                            زيارات من خلال المنتجات
                          </div>
                          <div className="card-body">
                            {this.state.topProducts.length <= 0 && (<NoResults message="لا توجد معلومات" />)}
                            {this.state.topProducts.map(each => 
                            <div className='product-card'>
                              <img src={each.image_url} />
                              <div className='product-info'>
                                <span title={each.name_ar} className='product-name'>
                                  {each.name_ar}
                                </span>
                                {each.click_count && <span>
                                  عدد النقر : {each.click_count}
                                </span>}
                              </div>
                              <div className='online-store__redirect orange d-flex align-items-center'>
                                <Link to={`/ar/model/${each.slug}`}>
                                  المنتج
                                  <i className="fas fa-caret-left px-2"></i>
                                </Link>
                              </div>
                            </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              }
          </ShadowCard>
        </React.Fragment>
      )
  }
}

const mapStateToProps = (state) => ({
    profile: state.profile,
    places: state.places.places
})

const mapDispatchToProps = (dispatch) => ({
    startFetchStoreProfile: () => dispatch(startFetchStoreProfile()),
    startUpdateStoreProfile: (values, type) => dispatch(startUpdateStoreProfile(values, type)),
    startFetchPlaces: () => dispatch(startFetchPlaces())
})

export default connect(mapStateToProps, mapDispatchToProps)(AccountStoreReportPage)