import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { Field, Formik } from 'formik'
import * as Yup from 'yup'
import Select from 'react-select'

import Spinner from '../components/Spinner'
import Switches from '../components/genericComponents/Switches'
import ShadowCard from '../components/genericComponents/ShadowCard'
import FloatingCard from '../components/genericComponents/FloatingCard'
import BranchListItem from '../components/genericComponents/BranchListItem'
import FormInput from '../components/genericComponents/FormInput'
import FormSelectOption from '../components/genericComponents/FormSelectOption'
import NoResults from './NoResults'
import Pagination from './Pagination'
import Image from './genericComponents/Image'

import API from '../api'

import {
    startGetStoreBranch,
    startUpdateStoreBranch,
    startFetchWorkDomains,
    startRemoveStoreBranch
} from '../actions/branches'

import {
    startFetchBranchItems,
    startUpdateBranchItem,
    startDeleteBranchItem,
    startAddBranchItem
} from '../actions/branch_items'

import { startGetCategories } from '../actions/categories'
import { startGetBrands } from '../actions/brands'

export class AccountStoreBranch extends React.Component {

    state = {
        isFetching: false,
        isFetchingItems: false,
        isUpdateMode: false,
        branch: null,
        pickedItem: null,
        work_domains: null,
        sub_models: [],
        showPassword: false,
        sendSubModelRequest: false,
        requestNotificationGranted: false,
        confirmDelete: false,
        itemPk: null,
        category: null,
        brand: null,
        searchBrand: null,
        searchCategory: null,
        searchWord: "",
    }

    async componentDidMount() {
        this.setState({ isFetching: true })
        try {
            const response = await this.props.startGetStoreBranch(this.props.match.params.branch_id)
            const domains = await this.props.startFetchWorkDomains()
            this.setState({ branch: response.data, work_domains: domains.data })
            await this.props.startGetBrands()
            await this.props.startGetCategories()
            //start fetching data from url
            await this.setStatesFromUrl();
            //stop loading
            this.setState({ isFetching: false })
        } catch (err) {
            // Ignore Error Message
        }
    }

    async componentDidUpdate(prevProps, prevState) {
      if (this.state.category && this.state.brand) {
        try {
          const response = await API.get(`collector/sub-models-list/${this.state.category}/${this.state.brand}/?store_branch_pk=${this.props.match.params.branch_id}`)
          this.setState({ sub_models: response.data, brand: null })
        } catch (error) {
          // Ignore Error Message
        }
      }
      //check if search is submited
      if(prevProps.location.search != this.props.location.search){
        this.setState({isFetchingItems:true});
        //start fetching data from url
        await this.setStatesFromUrl();
        this.setState({isFetchingItems:false});
      }
    }

    async setStatesFromUrl(){
      //start fetching query parameters
      let filter = location.search.replace("?", "");
      //set default data to change it while fetching data from url
      const splitQuery = filter.split("&");
      let page = 1;
      let name = null;
      let brand = null;
      let category = null;
      let categoryId = null;
      let brandId = null;
      //lets fetch query parameter data
      for(let query of splitQuery){
        const splitSearch = query.split("=");
        const key = splitSearch[0];
        let value = splitSearch[1];
        value = decodeURIComponent(value);
        if(key.toLowerCase() == "name") name = value;
        if(key.toLowerCase() == "brand") brand = value;
        if(key.toLowerCase() == "category") category = value;
        if(key.toLowerCase() == "page") page = Number(value);
      }
      //get category id to set values in select options element
      if(category) {
        const findCategory = this.props.categories.data.find(data => data.name['en'] == category);
        if(findCategory) {
          categoryId = findCategory.pk;
          this.setState({searchCategory:{value:findCategory.pk, label:category}});
        }
      }
      //get brank id to set values in select options element
      if(brand) {
        const findBrand = this.props.brands.find(data => data.name['en'] == brand);
        if(findBrand) {
          brandId = findBrand.pk;
          this.setState({searchBrand:{value:findBrand.pk, label:brand}});
        }
      }
      //if we have a name in query parameters set name data
      if(name) this.setState({searchWord: name});
      //lets get branch items with filter
      await this.props.startFetchBranchItems(page, this.props.match.params.branch_id, name, categoryId, brandId);
    }

    //event listener on search button //to detect changes from filter and build url with query parameters
    searchOnProducts = () => {
      const location = this.props.location.pathname + "?";
      let queryParameter = "";
      //if filter has category
      if(this.state.searchCategory){
        if(queryParameter) queryParameter += "&";
        queryParameter += "category=" + this.state.searchCategory.label;
      }
      //if filter has brand
      if(this.state.searchBrand){
        if(queryParameter) queryParameter += "&";
        queryParameter += "brand=" + this.state.searchBrand.label;
      }
      //if filter has name
      if(this.state.searchWord){
        if(queryParameter) queryParameter += "&";
        queryParameter += "name=" + this.state.searchWord;
      }
      if(queryParameter){
        const finalLocation = location + queryParameter;
        this.props.history.push(finalLocation);
      }else{
        this.props.history.push(this.props.location.pathname);
      }
    }

    submitHandler = async (values, bag) => {
      try {
        await this.props.startUpdateStoreBranch(this.props.match.params.branch_id, values)
        this.setState({ isUpdateMode: false })
      } catch (error) {
        bag.setSubmitting(false)
      }
    }

    submitItemFormHandler = async (values, bag) => {
      if (this.state.pickedItem.pk) {
        try {
          await this.props.startUpdateBranchItem(this.props.match.params.branch_id, this.state.pickedItem.pk, {
            sub_model: values.sub_model.value,
            price: values.price,
            condition: values.condition
          })
          this.setState({ pickedItem: null })
        } catch (error) {
          bag.setSubmitting(false)
        }
      } else {
        try {
          await this.props.startAddBranchItem(this.props.match.params.branch_id, {
            sub_model: values.sub_model.value,
            price: values.price,
            condition: values.condition
          })
          this.setState({ pickedItem: null })
        } catch (error) {
          bag.setSubmitting(false)
        }
      }
    }

    switchFormStatus = () => {
      this.setState(prevState => ({ isUpdateMode: !prevState.isUpdateMode }))
    }

    handlePickBranchItem = (itemId) => {
      const item = this.props.branch_items.items.find(item => item.sub_model === itemId)
      this.setState({ pickedItem: item, category: item.category.pk, brand: item.brand.pk })
    }

    handlePageClick = async data => {
      const page_number = data + 1;
      try {
        let currentLocation = location.pathname + location.search;
        //check if current url has page before just replace page with new one
        const isLinkHasPage = currentLocation.includes("page");
        if(isLinkHasPage){
          const regex = /page=[0-9]+/;
          currentLocation = currentLocation.replace(regex, "page=" + page_number);
        }else{
          if(location.search) currentLocation += "&";
          if(!location.search) currentLocation += "?";
          currentLocation += "page=" + page_number;
        }
        this.props.history.push(currentLocation);
        //await this.props.startFetchBranchItems(page_number, this.props.match.params.branch_id);
      } catch (err) {
        // Ignore Error Message
      }
    }

    submitSubModelNotificationRequest = async (values, bag) => {
      try {
        await API.post(`store/model-request-notification-create/?store_branch_pk=${this.props.match.params.branch_id}`, {
          ...values,
          category: values.category.value,
          brand: values.brand.value
        })
        this.setState({ requestNotificationGranted: true })
        setTimeout(() => {
          this.setState({ sendSubModelRequest: false, requestNotificationGranted: false })
        }, 2000)
      } catch (err) {
        // Ignore Error Message
      }
    }
    
    isAddProductDisabled = () => {
      if (this.state.branch.package) {
        const maximum_items = this.state.branch.package ? this.state.branch.package.maximum_items : 50;
        return maximum_items === this.props.branch_items.items.length;
      } 
      return false;                
    };

    getOptions = (options) => {
      //check if no options implemented just return empty array to avoid map errors
      if(!options) return [];
      return options && options.map((item) => ({ value: item.sub_model || item.pk, label: item.name['en'] || item.name }));
    }

    render() {
        if (this.state.isFetching || this.state.branch === null) {
          return (
            <ShadowCard>
              {this.props.account_type !== 'branch' && <Switches />}
              <Spinner />
            </ShadowCard>
          )
        }

        return (
            <ShadowCard>
                {this.props.account_type !== 'branch' && <Switches />}
                <Formik
                    initialValues={{
                        name_english: this.state.branch ? this.state.branch.name_english : '',
                        name_arabic: this.state.branch ? this.state.branch.name_arabic : '',
                        mobile_number: this.state.branch ? this.state.branch.mobile_number : '',
                        land_line_number: this.state.branch ? this.state.branch.land_line_number : '',
                        address: this.state.branch ? this.state.branch.address : '',
                        work_domain_id: this.state.branch ? this.state.branch.work_domain.pk : '',
                        neighbourhood_id: this.state.branch ? this.state.branch.neighbourhood.pk : '',
                        email: this.state.branch ? this.state.branch.branch_profile.email : '',
                        branch_password: this.state.branch ? this.state.branch.branch_profile.password : '',
                        store_facebook_page: this.state.branch  ? this.state.branch.store_facebook_page : '',
                        store_twitter_page: this.state.branch  ? this.state.branch.store_twitter_page : '',
                        store_youtube_page: this.state.branch  ? this.state.branch.store_youtube_page : '',
                        store_instagram_page: this.state.branch  ? this.state.branch.store_instagram_page : '',
                        messenger_link: this.state.branch  ? this.state.branch.messenger_link : '',
                        whatsapp: this.state.branch  ? this.state.branch.whatsapp : '',
                        installment: this.state.branch  ? this.state.branch.installment : false,
                        delivery: this.state.branch  ? this.state.branch.delivery : false,
                        city_delivery: this.state.branch  ? this.state.branch.city_delivery : false
                    }}
                    onSubmit={this.submitHandler}
                    validationSchema={
                      Yup.object().shape({
                        mobile_number: Yup.string().required('ادخل رقم الموبايل').max(11, 'رقم الموبايل  لا يمكن ان يكون اكثر من 11 رقم'),
                        land_line_number: Yup.string().required('ادخل رقم الخط الارضى').max(11, 'رقم الخط الارضى لا يمكن ان يكون اكثر من 11 رقم'),
                        address: Yup.string().required('ادخل العنوان'),
                        work_domain_id: Yup.string().required('اختر النشاط'),
                        branch_password: Yup.string().required('ادخل كلمة السر').min(6, 'كلمة السر تتكون من 6 ارقام على الاقل')
                      })
                    }
                >
                    {({
                        handleSubmit,
                        handleChange,
                        handleBlur,
                        values,
                        errors,
                        touched,
                        isValid,
                        isSubmitting
                    }) => (
                            <div className="auth__form mx-100">
                                <form onSubmit={handleSubmit} className="m-0">
                                    <div className="my-account__actions">
                                        {this.props.account_type !== 'branch' &&
                                          <Link to={'/account/store/branches'}><i className="fas fa-arrow-left"></i></Link>
                                        }
                                        {this.props.account_type !== 'branch' &&
                                          <button type="button" className="blue" title="تعديل"
                                            onClick={this.switchFormStatus}
                                          >
                                            <i className="fas fa-pen"></i>
                                          </button>
                                        }
                                        {this.props.account_type !== 'branch' && this.state.isUpdateMode &&
                                          <button
                                              className="blue"
                                              type="submit"
                                              disabled={!isValid || isSubmitting}
                                              title="حفظ"
                                          >
                                            حفظ
                                          </button>
                                        }
                                        {this.state.branch && this.state.branch.active ?
                                            <button type="button" className="green px-5">مفعل</button> :
                                            <button type="button" className="darkBlue px-5">غير مفعل</button>
                                        }
                                    </div>
                                    <div className="d-flex flex-column flex-lg-row justify-content-start text-right direction-rtl px-3">
                                        <div>
                                            <div className="gallery-images">
                                                <div className="images d-flex justify-content-between align-items-center flex-wrap">
                                                    <Image size="big"
                                                        branch_id={this.state.branch.pk}
                                                        isUpdateMode={this.state.isUpdateMode}
                                                        image={this.state.branch.images[0] ? this.state.branch.images[0].store_branch_image : ''}
                                                        image_id={this.state.branch.images[0] ? this.state.branch.images[0].pk : null} />
                                                    <Image size="small" branch_id={this.state.branch.pk}
                                                        isUpdateMode={this.state.isUpdateMode}
                                                        image={this.state.branch.images[1] ? this.state.branch.images[1].store_branch_image : ''}
                                                        image_id={this.state.branch.images[1] ? this.state.branch.images[1].pk : null} />
                                                    <Image size="small" branch_id={this.state.branch.pk}
                                                        isUpdateMode={this.state.isUpdateMode}
                                                        image={this.state.branch.images[2] ? this.state.branch.images[2].store_branch_image : ''}
                                                        image_id={this.state.branch.images[2] ? this.state.branch.images[2].pk : null} />
                                                    <Image size="small" branch_id={this.state.branch.pk}
                                                        isUpdateMode={this.state.isUpdateMode}
                                                        image={this.state.branch.images[3] ? this.state.branch.images[3].store_branch_image : ''}
                                                        image_id={this.state.branch.images[3] ? this.state.branch.images[3].pk : null} />
                                                    <Image size="small" branch_id={this.state.branch.pk}
                                                        isUpdateMode={this.state.isUpdateMode}
                                                        image={this.state.branch.images[4] ? this.state.branch.images[4].store_branch_image : ''}
                                                        image_id={this.state.branch.images[4] ? this.state.branch.images[4].pk : null} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex-1 px-4 mobile-padding">
                                            <FormInput
                                                autoCapitalize="none"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.name_arabic}
                                                disabled
                                                label="اسم الفرع"
                                                placeholder="ادخل الاسم"
                                                type="text"
                                                name="name_arabic"
                                            />
                                            {!this.state.isUpdateMode && <p style={{ 'paddingTop': '5px', 'borderBottom': '1px solid #ccc' }}></p>}
                                            <FormSelectOption
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                disabled={!this.state.isUpdateMode}
                                                value={values.work_domain_id}
                                                domains={this.state.work_domains && this.state.work_domains}
                                                label="النشاط"
                                                name="work_domain_id"
                                                error={touched.work_domain_id && errors.work_domain_id}
                                            />
                                            {!this.state.isUpdateMode && <p style={{ 'paddingTop': '5px', 'borderBottom': '1px solid #ccc' }}></p>}
                                            <FormInput
                                                autoCapitalize="none"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.mobile_number}
                                                disabled={!this.state.isUpdateMode}
                                                label="موبايل"
                                                placeholder="ادخل رقم الموبايل"
                                                type="text"
                                                name="mobile_number"
                                                error={touched.mobile_number && errors.mobile_number}
                                            />
                                            {!this.state.isUpdateMode && <p style={{ 'paddingTop': '5px', 'borderBottom': '1px solid #ccc' }}></p>}
                                            <FormInput
                                              onChange={handleChange}
                                              onBlur={handleBlur}
                                              value={values.store_facebook_page}
                                              disabled={!this.state.isUpdateMode}
                                              label=" صفحة فيس بوك"
                                              placeholder=""
                                              type="text"
                                              name="store_facebook_page"
                                              error={touched.store_facebook_page && errors.store_facebook_page}
                                            />
                                            {!this.state.isUpdateMode && <p style={{ 'paddingTop': '5px', 'borderBottom': '1px solid #ccc' }}></p>}
                                          <FormInput
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.store_twitter_page}
                                            disabled={!this.state.isUpdateMode}
                                            label="صفحة تويتر"
                                            placeholder=""
                                            type="text"
                                            name="store_twitter_page"
                                            error={touched.store_twitter_page && errors.store_twitter_page}
                                          />
                                          {!this.state.isUpdateMode && <p style={{ 'paddingTop': '5px', 'borderBottom': '1px solid #ccc' }}></p>}
                                          <FormInput
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.store_instagram_page}
                                            disabled={!this.state.isUpdateMode}
                                            label="صفحة انستجرام"
                                            placeholder=""
                                            type="text"
                                            name="store_instagram_page"
                                            error={touched.store_instagram_page && errors.store_instagram_page}
                                          />
                                            {!this.state.isUpdateMode && <p style={{ 'paddingTop': '5px', 'borderBottom': '1px solid #ccc' }}></p>}
                                            <FormInput
                                                autoCapitalize="none"
                                                value={values.email}
                                                disabled={!this.state.isUpdateMode}
                                                disabled
                                                label="اسم المستخدم"
                                                type="text"
                                                name="email"
                                            />
                                        </div>
                                        <div className="flex-1 mobile-padding">
                                            <FormInput
                                                autoCapitalize="none"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.name_english}
                                                disabled
                                                label="اسم الفرع بالانجليزى"
                                                placeholder="ادخل اسم الفرع بالانجليزى"
                                                type="text"
                                                name="name_english"
                                            />
                                            {!this.state.isUpdateMode && <p style={{ 'paddingTop': '5px', 'borderBottom': '1px solid #ccc' }}></p>}
                                            <FormInput
                                                autoCapitalize="none"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.land_line_number}
                                                max={11}
                                                label="تليفون ارضى"
                                                placeholder="ادخل رقم التليفون الارضى"
                                                type="text"
                                                disabled={!this.state.isUpdateMode}
                                                name="land_line_number"
                                                error={touched.land_line_number && errors.land_line_number}
                                            />
                                            {!this.state.isUpdateMode && <p style={{ 'paddingTop': '5px', 'borderBottom': '1px solid #ccc' }}></p>}
                                            <FormInput
                                                autoCapitalize="none"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.address}
                                                label="العنوان"
                                                disabled={!this.state.isUpdateMode}
                                                placeholder="ادخل العنوان"
                                                type="text"
                                                name="address"
                                                error={touched.address && errors.address}
                                            />
                                            {!this.state.isUpdateMode && <p style={{ 'paddingTop': '5px', 'borderBottom': '1px solid #ccc' }}></p>}
                                                                                                                                                    <FormInput
                                                      onChange={handleChange}
                                                      onBlur={handleBlur}
                                                      value={values.messenger_link}
                                                      disabled={!this.state.isUpdateMode}
                                                      label="ماسنجر"
                                                      placeholder=""
                                                      type="text"
                                                      name="messenger_link"
                                                      error={touched.messenger_link && errors.messenger_link}
                                                    />
                                                    {!this.state.isUpdateMode && <p style={{ 'paddingTop': '5px', 'borderBottom': '1px solid #ccc' }}></p>}
                                                    <FormInput
                                                      onChange={handleChange}
                                                      onBlur={handleBlur}
                                                      value={values.whatsapp}
                                                      disabled={!this.state.isUpdateMode}
                                                      label="واتس اب"
                                                      placeholder=""
                                                      type="text"
                                                      name="whatsapp"
                                                      error={touched.whatsapp && errors.whatsapp}
                                                    />
                                                    {!this.state.isUpdateMode && <p style={{ 'paddingTop': '5px', 'borderBottom': '1px solid #ccc' }}></p>}
                                          <FormInput
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.store_youtube_page}
                                            disabled={!this.state.isUpdateMode}
                                            label=" صفحة يوتيوب"
                                            placeholder=""
                                            type="text"
                                            name="store_youtube_page"
                                            error={touched.store_youtube_page && errors.store_youtube_page}
                                          />
                                            {!this.state.isUpdateMode && <p style={{ 'paddingTop': '5px', 'borderBottom': '1px solid #ccc' }}></p>}
                                            <FormInput
                                                autoCapitalize="none"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.branch_password}
                                                label="كلمة السر"
                                                disabled={!this.state.isUpdateMode}
                                                placeholder="ادخل كلمة السر"
                                                type={this.state.showPassword ? 'text' : 'password'}
                                                name="branch_password"
                                                error={touched.branch_password && errors.branch_password}
                                            />
                                            <div style={{ position: 'relative' }}>
                                                <span
                                                    style={{
                                                        position: 'absolute',
                                                        left: 0,
                                                        top: '-25px',
                                                        zIndex: 1,
                                                        cursor: 'pointer'
                                                    }}
                                                    onClick={() => this.setState(prevState => ({ showPassword: !prevState.showPassword }))}
                                                >
                                                    {this.state.showPassword ?
                                                        <i className="fas fa-eye"></i> :
                                                        <i className="fas fa-eye-slash"></i>
                                                    }
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    {<div className="d-flex flex-column flex-lg-row justify-content-start text-right direction-rtl py-5 px-3">
                                      <div className="flex-1" style={{maxWidth: "260px"}}></div>
                                      <div className="d-flex flex-column flex-1">
                                        <label>
                                          <Field type="checkbox" name="installment" disabled={!this.state.isUpdateMode} />
                                          هل تقدم خدمة البيع بالتقسيط
                                        </label>
                                        <label>
                                          <Field type="checkbox" name="city_delivery" disabled={!this.state.isUpdateMode} />
                                          هل تقدم خدمة شحن البضاعة لمحافظات اخري
                                        </label>
                                        <label>
                                          <Field type="checkbox" name="delivery" disabled={!this.state.isUpdateMode} />
                                          هل تقدم خدمة توصيل البضاعة للعميل
                                        </label>
                                      </div>
                                      <div className="flex-1"></div>
                                    </div>}
                                </form>
                            </div>
                        )}
                </Formik>
                <div className="products products--bordered text-right">
                    <h3>المنتجات</h3>
                </div>
                <div className="branches-header d-flex flex-column flex-lg-row justify-content-between align-items-center p-3">
                    <button 
                        className='my-3'
                        type="button" 
                        onClick={() => this.setState({ pickedItem: {} })}
                        disabled={this.isAddProductDisabled()}
                    >اضافة منتج
                    </button>
                    <div className="filter-container d-flex flex-column-reverse flex-lg-row">
                      <button 
                        type="button"
                        onClick={() => this.searchOnProducts()}
                      >بحث</button>
                      <input 
                        style={{"direction":"rtl","::placeholder":"font-size: 14px"}}
                        onChange={(event) => this.setState({searchWord:event.target.value})}
                        value={this.state.searchWord}
                        className="filter-input form-control"
                        placeholder='إسم المنتج'
                      ></input>
                      <Select 
                        menuPortalTarget={document.querySelector('body')}
                        options={this.getOptions(this.props.brands && this.props.brands.sort((a, b) => {
                          return a.name['en'] > b.name['en'] ? 1 : -1;
                        }) && this.props.brands.unshift({pk:0,name:{en:"العلامة التجارية"}}) && this.props.brands)}
                        placeholder="العلامة التجارية"
                        className='react-select-container filter-input'
                        classNamePrefix="react-select"
                        value={this.state.searchBrand}
                        onChange={(option) => {
                          //check if selected default value and null selected state
                          if(!option.value) return this.setState({ searchBrand: null });
                          //store new brand id
                          this.setState({ searchBrand: option });
                        }}
                      />
                      <Select 
                        menuPortalTarget={document.querySelector('body')}
                        options={this.getOptions(this.props.categories && this.props.categories.data.sort((a, b) => {
                          return a.name['en'] > b.name['en'] ? 1 : -1;
                        }) && this.props.categories.data.unshift({pk:0,name:{en:"القسم"}}) && this.props.categories.data)}
                        className='react-select-container filter-input'
                        classNamePrefix="react-select"
                        value={this.state.searchCategory}
                        onChange={(option) => {
                          //check if selected default value and null selected state
                          if(!option.value) return this.setState({ searchCategory: null });
                          //store new category id
                          this.setState({ searchCategory: option });
                        }}
                        placeholder="القسم"
                      />
                    </div>
                    {/* {!this.state.branch.package && (
                      <span className="red-text">
                          هذا الفرع غير مشترك في أي من الباقات المتاحة لذا لحد الأقصى لعدد المنتجات المضافة هو 10 منتجات فقط -
                      </span>
                    )} */}
                </div>
                {this.state.isFetchingItems ? <Spinner /> : (this.props.branch_items.items && this.props.branch_items.items.length ?
                    <div className="table-container">
                      <div className="p-3 table-contents">
                          {this.props.branch_items.items.map(item =>
                              <BranchListItem key={item.pk}>
                                  <div className="branch-list-item__options">
                                      <button className="red" type="button" onClick={() => this.setState({ confirmDelete: true, itemPk: item.pk })}>
                                          <i className="fas fa-trash"></i>
                                      </button>
                                      <button type="button" className="blue" onClick={() => this.handlePickBranchItem(item.sub_model)}>
                                          <i className="fas fa-pen"></i>
                                      </button>
                                  </div>
                                  <p>{item.price}</p>
                                  <p className="flex-2">{item.name}</p>
                                  <p>{item.brand.name}</p>
                                  <p>{item.category.name}</p>
                              </BranchListItem>
                          )}
                          <Pagination
                              pages_count={this.props.branch_items.pages_count}
                              count={this.props.branch_items.count}
                              handlePageClick={this.handlePageClick}
                          />
                      </div>
                    </div>
                    : <NoResults message="لا توجد منتجات لهذا المتجر" />
                )}
                {this.state.itemPk && (
                  <div className="modal d-block" role="dialog" style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
                    <div className="modal-dialog modal-dialog-centered" role="document">
                      <div className="modal-content">
                        <div className="modal-header">
                          <h5 className="modal-title">حذف منتج</h5>
                        </div>
                        <div className="modal-body text-center">
                          <p>هل انت متاكد من انك تريد حذف هذا المنتج ؟</p>
                        </div>
                        <div className="modal-footer">
                          <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={() => this.setState({ itemPk: null })}>رجوع</button>
                          <button type="button" className="btn btn-danger" onClick={() => {
                            this.props.startDeleteBranchItem(this.state.branch.pk, this.state.itemPk);
                            this.setState({ itemPk: null });
                          }}>حذف</button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {this.state.pickedItem && (
                    <FloatingCard isAddClicked={!!this.state.pickedItem}>
                        <Formik
                            initialValues={{
                                sub_model: this.state.pickedItem.name ? { value: this.state.pickedItem.sub_model, label: this.state.pickedItem.name }: '',
                                price: this.state.pickedItem.price ? this.state.pickedItem.price : '',
                                condition: this.state.pickedItem.condition ? this.state.pickedItem.condition : '',
                                category: this.state.pickedItem.category ? { value: this.state.pickedItem.category.pk, label: this.state.pickedItem.category.name } : '',
                                brand: this.state.pickedItem.brand ? { value: this.state.pickedItem.brand.pk, label: this.state.pickedItem.brand.name } : '',
                            }}
                            onSubmit={this.submitItemFormHandler}
                            validationSchema={
                                Yup.object().shape({
                                    sub_model: Yup.string().required('اختر المنتج'),
                                    price: Yup.string().required('اختر السعر'),
                                    condition: Yup.string().required('اختر حالة الجهاز'),
                                    category: Yup.string().required('اختر القسم'),
                                    brand: Yup.string().required('اختر العلامة التجارية'),
                                })
                            }
                        >
                            {({
                                handleSubmit,
                                handleChange,
                                handleBlur,
                                values,
                                errors,
                                touched,
                                isValid,
                                isSubmitting,
                                setFieldValue
                            }) => (
                                    <div className="auth__form mx-100 flex-form">
                                        <form onSubmit={handleSubmit} className="m-0">
                                            <div className="text-right px-1">
                                                <h2 style={{ fontSize: '18px', fontWeight: 'bold' }}>اضافة منتج</h2>
                                            </div>
                                            <div className="form-fields row text-right direction-rtl">
                                                <div className="col-12 col-lg-6">
                                                    <React.Fragment>
                                                      <div className="single-input">
                                                        <label>
                                                          القسم
                                                          <strong className="red-text mr-3">*</strong>
                                                        </label>
                                                        <Select 
                                                          menuPortalTarget={document.querySelector('body')}
                                                          options={this.getOptions(this.props.categories && this.props.categories.data.sort((a, b) => {
                                                            return a.name['en'] > b.name['en'] ? 1 : -1;
                                                          }))}
                                                          className='react-select-container'
                                                          classNamePrefix="react-select"
                                                          value={values.category}
                                                          onChange={(option) => {
                                                            setFieldValue('category', option);
                                                            setFieldValue('brand', '');
                                                            this.setState({ category: option.value });
                                                          }}
                                                          placeholder=""
                                                        />
                                                      </div>
                                                      {touched.category && errors.category && <FetchError message={errors.category} />}
                                                    </React.Fragment>
                                                    <React.Fragment>
                                                      <div className="single-input">
                                                        <label>
                                                          العلامة التجارية
                                                          <strong className="red-text mr-3">*</strong>
                                                        </label>
                                                        <Select 
                                                          menuPortalTarget={document.querySelector('body')}
                                                          options={this.getOptions(this.props.brands && this.props.brands.sort((a, b) => {
                                                            return a.name['en'] > b.name['en'] ? 1 : -1;
                                                          }))}
                                                          placeholder=""
                                                          className='react-select-container'
                                                          classNamePrefix="react-select"
                                                          isDisabled={!values.category}
                                                          value={values.brand}
                                                          onChange={(option) => {
                                                            setFieldValue('brand', option);
                                                            this.setState({ brand: option.value });
                                                          }}
                                                        />
                                                      </div>
                                                      {touched.brand && errors.brand && <FetchError message={errors.brand} />}
                                                    </React.Fragment>
                                                    <React.Fragment>
                                                      <div className="single-input">
                                                        <label>
                                                          اسم المنتج
                                                          <strong className="red-text mr-3">*</strong>
                                                        </label>
                                                        <Select 
                                                          menuPortalTarget={document.querySelector('body')}
                                                          options={this.getOptions(this.state.sub_models && this.state.sub_models)}
                                                          value={values.sub_model}
                                                          className='react-select-container'
                                                          classNamePrefix="react-select"
                                                          placeholder=""
                                                          onChange={(option) => setFieldValue('sub_model', option)}
                                                        />
                                                      </div>
                                                      {touched.sub_model && errors.sub_model && <FetchError message={errors.sub_model} />}
                                                    </React.Fragment>
                                                </div>
                                                <div className="col-12 col-lg-6">
                                                    <FormSelectOption
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.condition}
                                                        required
                                                        conds={[
                                                            { name: { en: 'new', ar: 'جديد' } },
                                                            { name: { en: 'used', ar: 'مستعمل' } },
                                                            { name: { en: 'like_new', ar: 'كسر زيرو' } }
                                                        ]}
                                                        label="حالة الجهاز"
                                                        required
                                                        name="condition"
                                                        error={touched.condition && errors.condition}
                                                    />
                                                    <FormInput
                                                        autoCapitalize="none"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.price}
                                                        label="السعر"
                                                        type="text"
                                                        name="price"
                                                        error={touched.price && errors.price}
                                                    />
                                                </div>
                                            </div>
                                            <div className="new-product mt-4">
                                              <span
                                                  className="alert alert-success mt-4 sm-alert"
                                                  style={{ cursor: 'pointer' }}
                                                  onClick={() =>
                                                      this.setState({
                                                          pickedItem: null, sendSubModelRequest: true
                                                      })
                                                  }>
                                                  لم تجد منتجك؟ اطلب إضافة المنتج
                                              </span>
                                            </div>
                                            <div className="text-right mt-5 button-container">
                                                <button
                                                    type="button"
                                                    className="btn gray px-5 pt-1 pb-1"
                                                    onClick={() => this.setState({ pickedItem: null })}
                                                >
                                                    رجوع
                                                </button>
                                                <button
                                                    type="submit"
                                                    className="btn btn-info px-5 pt-1 pb-1 ml-3"
                                                    disabled={!isValid || isSubmitting}
                                                >
                                                    حفظ
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                )}
                        </Formik>
                    </FloatingCard>
                )}

                {this.state.sendSubModelRequest && (
                    <FloatingCard isAddClicked={!!this.state.sendSubModelRequest}>
                        <Formik
                          initialValues={{
                            model_name: '',
                            category: {},
                            brand: {},
                          }}
                          onSubmit={this.submitSubModelNotificationRequest}
                          validationSchema={
                            Yup.object().shape({
                              model_name: Yup.string().required('ادخل اسم المنتج'),
                              category: Yup.string().required('اختر القسم'),
                              brand: Yup.string().required('اختر العلامة التجارية'),
                            })
                          }
                        >
                            {({
                                handleSubmit,
                                handleChange,
                                handleBlur,
                                values,
                                errors,
                                touched,
                                isValid,
                                isSubmitting,
                                setFieldValue
                            }) => (
                                    <div className="auth__form mx-100 flex-form">
                                        <form onSubmit={handleSubmit} className="m-0">
                                            <div className="text-right px-1">
                                                <h2 style={{ fontSize: '18px', fontWeight: 'bold' }}>طلب إضافة منتج</h2>
                                            </div>
                                            <div className="d-flex justify-content-start text-right direction-rtl flex-column flex-lg-row">
                                                <div className="flex-1">
                                                    <React.Fragment>
                                                      <div className="single-input">
                                                        <label>
                                                          القسم
                                                          <strong className="red-text mr-3">*</strong>
                                                        </label>
                                                        <Select 
                                                          menuPortalTarget={document.querySelector('body')}
                                                          options={this.getOptions(this.props.categories && this.props.categories.data.sort((a, b) => {
                                                            return a.name['en'] > b.name['en'] ? 1 : -1;
                                                          }))}
                                                          className='react-select-container'
                                                          classNamePrefix="react-select"
                                                          value={values.category}
                                                          onChange={(option) => {
                                                            setFieldValue('category', option);
                                                            setFieldValue('brand', '');
                                                          }}
                                                          placeholder=""
                                                        />
                                                      </div>
                                                      {touched.category && errors.category && <FetchError message={errors.category} />}
                                                    </React.Fragment>
                                                    <React.Fragment>
                                                      <div className="single-input">
                                                        <label>
                                                          العلامة التجارية
                                                          <strong className="red-text mr-3">*</strong>
                                                        </label>
                                                        <Select 
                                                          menuPortalTarget={document.querySelector('body')}
                                                          options={this.getOptions(this.props.brands && this.props.brands.sort((a, b) => {
                                                            return a.name['en'] > b.name['en'] ? 1 : -1;
                                                          }))}
                                                          placeholder=""
                                                          className='react-select-container'
                                                          classNamePrefix="react-select"
                                                          isDisabled={!values.category}
                                                          value={values.brand}
                                                          onChange={(option) => {
                                                            setFieldValue('brand', option);
                                                          }}
                                                        />
                                                      </div>
                                                      {touched.brand && errors.brand && <FetchError message={errors.brand} />}
                                                    </React.Fragment>
                                                </div>
                                                <div className="flex-1 px-4">
                                                    <FormInput
                                                        autoCapitalize="none"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.model_name}
                                                        label="اسم المنتج"
                                                        placeholder="ادخل اسم المنتج"
                                                        type="text"
                                                        name="model_name"
                                                        error={touched.model_name && errors.model_name}
                                                    />
                                                </div>
                                            </div>
                                            {this.state.requestNotificationGranted && (
                                              <div className="new-product mt-4">
                                                <span className="red-text"> سيتم ارسال الطلب الى الاداره</span>
                                              </div>
                                            )}
                                            <div className="text-right mt-5 button-container">
                                                <button
                                                    type="button"
                                                    className="btn gray px-5 pt-1 pb-1"
                                                    onClick={() => this.setState({ sendSubModelRequest: false })}
                                                >
                                                    رجوع
                                                </button>
                                                <button
                                                    type="submit"
                                                    className="btn btn-info px-5 pt-1 pb-1 ml-3"
                                                    disabled={!isValid || isSubmitting}
                                                >
                                                    حفظ
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                )}
                        </Formik>
                    </FloatingCard>
                )}
            </ShadowCard>
        )
    }
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    account_type: state.auth.user.account_type,
    branch_items: state.branch_items,
    brands: state.brands,
    categories: state.categories
})

const mapDispatchToProps = (dispatch) => ({
    startGetStoreBranch: (branch_id) => dispatch(startGetStoreBranch(branch_id)),
    startUpdateStoreBranch: (branch_id, values) => dispatch(startUpdateStoreBranch(branch_id, values)),
    startFetchBranchItems: (page, branch_id, name, categoryId, brandId) => dispatch(startFetchBranchItems(page, branch_id, name, categoryId, brandId)),
    startAddBranchItem: (branch_id, values) => dispatch(startAddBranchItem(branch_id, values)),
    startUpdateBranchItem: (branch_id, item_id, updates) => dispatch(startUpdateBranchItem(branch_id, item_id, updates)),
    startDeleteBranchItem: (branch_id, item_id) => dispatch(startDeleteBranchItem(branch_id, item_id)),
    startFetchWorkDomains: () => dispatch(startFetchWorkDomains()),
    startGetCategories: () => dispatch(startGetCategories()),
    startGetBrands: () => dispatch(startGetBrands()),
    startRemoveStoreBranch: (branch_id) => dispatch(startRemoveStoreBranch(branch_id))
})

export default connect(mapStateToProps, mapDispatchToProps)(AccountStoreBranch)